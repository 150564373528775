import { APIBase, GET } from "./apibase";
import ResourceList from '../resourceList';
import { Product } from '../product';

export default class ProductAPI extends APIBase {
    /** 상품 목록을 취득합니다. */
    public getList(page: number) {
        const endpoint = '/_api/product/list';
        return this.request(GET, endpoint, {
            page
        }) as Promise<ResourceList<Product>>;
    }

    /** 상품 목록을 취득합니다. */
    public getMonthlyProductList(page: number) {
        const endpoint = '/_api/product/listMonthly';
        return this.request(GET, endpoint, {
            page
        }) as Promise<ResourceList<Product>>;
    }

    /**
     * 상품 정보를 취득합니다.
     */
    public get(id: string | number) {
        const endpoint = `/_api/product/${id}`;
        return this.request(GET, endpoint) as Promise<Product>;
    }

    public getPortfoliosByGroup(groupId: string | number) {
        const endpoint = `/_api/group/${groupId}/portfolio/list`;
        return this.request(GET, endpoint) as Promise<Product[]>;
    }
}
