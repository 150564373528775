import Vue from 'vue';
import Vuex from 'vuex';
import { Member } from './api/member';
import APIClient from './api/client';

Vue.use(Vuex);

export interface GlobalState {
    profile: Member | null;
    isDrawerVisible: boolean;
    isLoginPopupVisible: boolean;
}

export default new Vuex.Store({
    state: {
        profile: null,
        isDrawerVisible: false,
        isLoginPopupVisible: false,
    } as GlobalState,
    getters: {
        isLoggedIn(state: GlobalState) {
            return state.profile != null;
        }
    },
    mutations: {
        setProfile(state: GlobalState, profile: Member | null) {
            state.profile = profile;
        },
        setDrawerVisible(state: GlobalState, isVisible: boolean) {
            state.isDrawerVisible = isVisible;
        },
        setLoginPopupVisible(state: GlobalState, isVisible: boolean) {
            state.isLoginPopupVisible = isVisible;
        },
        commitSelf(state: GlobalState, self: Member) {
            state.profile = self;
        }
    },
    actions: {
        /**
         * 자기 자신의 profile을 취득하면서 로그인 여부도 같이 확인합니다.
         */
        async fetchProfile() {
            const profile = await APIClient.instance.mypage.getSelf();

            // 가상계좌 번호와 코드가 없으면 조용히 생성합니다.
            // if ( ! (profile.vbankWithdrawNumber && profile.vbankWithdrawCode ) ) {
            //     APIClient.instance.mypage.createVbankAccount().then(
            //         () => this.dispatch('fetchProfile')
            //     );
            //     // 로그인을 거부하지 않습니다.
            //     this.commit("setProfile", profile);
            // } else {
            this.commit("setProfile", profile);
            // }
        }
    },
});
