import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    ko: {
        boolean: {
            true: '예',
            false: '아니오',
        },
        productState: {
            'in-review': '심사 대기 중',
            'preparing': '투자 예정',
            'funding': '투자 진행 중',
            'funded': '투자 완료',
            'repaying': '상환 중',
            'completed': '상환 완료',
            'canceled': '취소됨',
            'failed': '투자 실패',
        },
        userType: {
            individual: '개인',
            company: '법인',
            overseas: '해외',
            manager: '관리자'
        },
        bankCode: {
            null: '알 수 없음',
            undefined: '알 수 없음',
            '001': '한국은행',
            '002': '산업은행',
            '003': '기업은행',
            '004': '국민은행',
            '005': '외환은행 [X]',
            '007': '수협은행',
            '008': '수출입은행',
            '011': '농협은행',
            '012': '지역농·축협',
            '020': '우리은행',
            '023': 'SC제일은행',
            '027': '한국씨티은행',
            '031': '대구은행',
            '032': '부산은행',
            '034': '광주은행',
            '035': '제주은행',
            '037': '전북은행',
            '039': '경남은행',
            '041': '우리카드',
            '044': '외환카드',
            '045': '새마을금고중앙회',
            '048': '신협',
            '050': '저축은행',
            '052': '모건스탠리은행',
            '054': 'HSBC은행',
            '055': '도이치은행',
            '056': '알비에스피엘씨은행 [X]',
            '057': '제이피모간체이스은행',
            '058': '미즈호은행',
            '059': '엠유에프지은행',
            '060': 'BOA은행',
            '061': '비엔피파리바은행',
            '062': '중국공상은행',
            '063': '중국은행',
            '064': '산림조합중앙회',
            '065': '대화은행',
            '066': '교통은행',
            '067': '중국건설은행',
            '071': '우체국',
            '076': '신용보증기금',
            '077': '기술보증기금',
            '081': '하나은행 [구 KEB하나은행]',
            '088': '신한은행',
            '089': '케이뱅크',
            '090': '카카오뱅크',
            '093': '한국주택금융공사 [X]',
            '094': '서울보증보험 [X]',
            '095': '경찰청 [X]',
            '096': '한국전자금융(주) [X]',
            '097': '퇴직연금 [X]',
            '099': '금융결제원 [X]',
            '101': '한국신용정보원',
            '102': '대신저축은행',
            '103': '에스비아이저축은행',
            '104': '에이치케이저축은행',
            '105': '웰컴저축은행',
            '106': '신한저축은행',
            '209': '유안타증권',
            '218': 'KB증권',
            '221': '상상인증권',
            '222': '한양증권',
            '223': '리딩투자증권',
            '224': 'BNK투자증권',
            '225': 'IBK투자증권',
            '226': 'KB투자증권 [X]',
            '227': 'KTB투자증권',
            '238': '미래에셋대우',
            '240': '삼성증권',
            '243': '한국투자증권',
            '247': 'NH투자증권',
            '261': '교보증권',
            '262': '하이투자증권',
            '263': '현대차증권',
            '264': '키움증권',
            '265': '이베스트투자증권',
            '266': 'SK증권',
            '267': '대신증권',
            '269': '한화투자증권',
            '270': '하나금융투자',
            '278': '신한금융투자',
            '279': 'DB금융투자',
            '280': '유진투자증권',
            '287': '메리츠종합금융증권',
            '288': '카카오페이증권 [구 바로투자증권]',
            '290': '부국증권',
            '291': '신영증권',
            '292': '케이프투자증권',
            '293': '한국증권금융',
            '294': '한국포스증권',
            '295': '우리종합금융',
            '299': '아주캐피탈',
            '361': 'BC카드',
            '364': '광주카드',
            '365': '삼성카드',
            '366': '신한카드',
            '367': '현대카드',
            '368': '롯데카드',
            '369': '수협카드',
            '370': '씨티카드',
            '371': 'NH카드',
            '372': '전북카드',
            '373': '제주카드',
            '374': '하나SK카드',
            '381': 'KB국민카드',
            '431': '미래에셋생명',
            '432': '한화생명',
            '433': '교보라이프플래닛생명',
            '434': '푸본현대생명',
            '435': '라이나생명',
            '436': '교보생명',
            '437': '에이비엘생명',
            '438': '신한생명',
            '439': 'KB생명보험',
            '440': '농협생명',
            '441': '삼성화재',
            '442': '현대해상',
            '443': 'DB손해보험',
            '444': 'KB손해보험',
            '445': '롯데손해보험',
            '446': '오렌지라이프생명보험',
            '447': '악사손해보험',
            '448': '메리츠화재',
            '449': '농협손해보험',
            '450': '푸르덴셜생명보험',
            '452': '삼성생명',
            '453': '흥국생명',
            '454': '한화손해보험',
            '455': 'AIA생명보험 ',
            '456': 'DGB생명보험',
            '457': 'DB생명보험',
            '458': 'KDB생명보험',
            '481': '행정안전부 [X]',

            'BANK_CD_001': '한국은행',
            'BANK_CD_002': '산업은행',
            'BANK_CD_003': '기업은행',
            'BANK_CD_004': '국민은행',
            'BANK_CD_005': '외환은행 [X]',
            'BANK_CD_007': '수협은행',
            'BANK_CD_008': '수출입은행',
            'BANK_CD_011': '농협은행',
            'BANK_CD_012': '지역농·축협',
            'BANK_CD_020': '우리은행',
            'BANK_CD_023': 'SC제일은행',
            'BANK_CD_027': '한국씨티은행',
            'BANK_CD_031': '대구은행',
            'BANK_CD_032': '부산은행',
            'BANK_CD_034': '광주은행',
            'BANK_CD_035': '제주은행',
            'BANK_CD_037': '전북은행',
            'BANK_CD_039': '경남은행',
            'BANK_CD_041': '우리카드',
            'BANK_CD_044': '외환카드',
            'BANK_CD_045': '새마을금고중앙회',
            'BANK_CD_048': '신협',
            'BANK_CD_050': '저축은행',
            'BANK_CD_052': '모건스탠리은행',
            'BANK_CD_054': 'HSBC은행',
            'BANK_CD_055': '도이치은행',
            'BANK_CD_056': '알비에스피엘씨은행 [X]',
            'BANK_CD_057': '제이피모간체이스은행',
            'BANK_CD_058': '미즈호은행',
            'BANK_CD_059': '엠유에프지은행',
            'BANK_CD_060': 'BOA은행',
            'BANK_CD_061': '비엔피파리바은행',
            'BANK_CD_062': '중국공상은행',
            'BANK_CD_063': '중국은행',
            'BANK_CD_064': '산림조합중앙회',
            'BANK_CD_065': '대화은행',
            'BANK_CD_066': '교통은행',
            'BANK_CD_067': '중국건설은행',
            'BANK_CD_071': '우체국',
            'BANK_CD_076': '신용보증기금',
            'BANK_CD_077': '기술보증기금',
            'BANK_CD_081': '하나은행 [구 KEB하나은행]',
            'BANK_CD_088': '신한은행',
            'BANK_CD_089': '케이뱅크',
            'BANK_CD_090': '카카오뱅크',
            'BANK_CD_093': '한국주택금융공사 [X]',
            'BANK_CD_094': '서울보증보험 [X]',
            'BANK_CD_095': '경찰청 [X]',
            'BANK_CD_096': '한국전자금융(주) [X]',
            'BANK_CD_097': '퇴직연금 [X]',
            'BANK_CD_099': '금융결제원 [X]',
            'BANK_CD_101': '한국신용정보원',
            'BANK_CD_102': '대신저축은행',
            'BANK_CD_103': '에스비아이저축은행',
            'BANK_CD_104': '에이치케이저축은행',
            'BANK_CD_105': '웰컴저축은행',
            'BANK_CD_106': '신한저축은행',
            'BANK_CD_209': '유안타증권',
            'BANK_CD_218': 'KB증권',
            'BANK_CD_221': '상상인증권',
            'BANK_CD_222': '한양증권',
            'BANK_CD_223': '리딩투자증권',
            'BANK_CD_224': 'BNK투자증권',
            'BANK_CD_225': 'IBK투자증권',
            'BANK_CD_226': 'KB투자증권 [X]',
            'BANK_CD_227': 'KTB투자증권',
            'BANK_CD_238': '미래에셋대우',
            'BANK_CD_240': '삼성증권',
            'BANK_CD_243': '한국투자증권',
            'BANK_CD_247': 'NH투자증권',
            'BANK_CD_261': '교보증권',
            'BANK_CD_262': '하이투자증권',
            'BANK_CD_263': '현대차증권',
            'BANK_CD_264': '키움증권',
            'BANK_CD_265': '이베스트투자증권',
            'BANK_CD_266': 'SK증권',
            'BANK_CD_267': '대신증권',
            'BANK_CD_269': '한화투자증권',
            'BANK_CD_270': '하나금융투자',
            'BANK_CD_278': '신한금융투자',
            'BANK_CD_279': 'DB금융투자',
            'BANK_CD_280': '유진투자증권',
            'BANK_CD_287': '메리츠종합금융증권',
            'BANK_CD_288': '카카오페이증권 [구 바로투자증권]',
            'BANK_CD_290': '부국증권',
            'BANK_CD_291': '신영증권',
            'BANK_CD_292': '케이프투자증권',
            'BANK_CD_293': '한국증권금융',
            'BANK_CD_294': '한국포스증권',
            'BANK_CD_295': '우리종합금융',
            'BANK_CD_299': '아주캐피탈',
            'BANK_CD_361': 'BC카드',
            'BANK_CD_364': '광주카드',
            'BANK_CD_365': '삼성카드',
            'BANK_CD_366': '신한카드',
            'BANK_CD_367': '현대카드',
            'BANK_CD_368': '롯데카드',
            'BANK_CD_369': '수협카드',
            'BANK_CD_370': '씨티카드',
            'BANK_CD_371': 'NH카드',
            'BANK_CD_372': '전북카드',
            'BANK_CD_373': '제주카드',
            'BANK_CD_374': '하나SK카드',
            'BANK_CD_381': 'KB국민카드',
            'BANK_CD_431': '미래에셋생명',
            'BANK_CD_432': '한화생명',
            'BANK_CD_433': '교보라이프플래닛생명',
            'BANK_CD_434': '푸본현대생명',
            'BANK_CD_435': '라이나생명',
            'BANK_CD_436': '교보생명',
            'BANK_CD_437': '에이비엘생명',
            'BANK_CD_438': '신한생명',
            'BANK_CD_439': 'KB생명보험',
            'BANK_CD_440': '농협생명',
            'BANK_CD_441': '삼성화재',
            'BANK_CD_442': '현대해상',
            'BANK_CD_443': 'DB손해보험',
            'BANK_CD_444': 'KB손해보험',
            'BANK_CD_445': '롯데손해보험',
            'BANK_CD_446': '오렌지라이프생명보험',
            'BANK_CD_447': '악사손해보험',
            'BANK_CD_448': '메리츠화재',
            'BANK_CD_449': '농협손해보험',
            'BANK_CD_450': '푸르덴셜생명보험',
            'BANK_CD_452': '삼성생명',
            'BANK_CD_453': '흥국생명',
            'BANK_CD_454': '한화손해보험',
            'BANK_CD_455': 'AIA생명보험 ',
            'BANK_CD_456': 'DGB생명보험',
            'BANK_CD_457': 'DB생명보험',
            'BANK_CD_458': 'KDB생명보험',
            'BANK_CD_481': '행정안전부 [X]',
        },
        repaymentMethodCode: {
            'REPAYMENT_METHOD_CD_001': '만기 일시 상환',
            'REPAYMENT_METHOD_CD_002': '원리금 균등 상환'
        },
        portfolioPurpose: {
            'fundconst': '건축 자금',
            'fundbridge': '브릿지 자금',
            'loanaucrem': '경락 잔금',
            'loanprpty': '부동산 담보대출'
        },
        depositDueStatus: {
            'deposit': '완료',
            'future': '-',
            'indue': '입금 대기',
            'overdue': '미납'
        },
    }
};

const i18n = new VueI18n({
    locale: 'ko',
    fallbackLocale: 'ko',
    messages,
});

Vue.mixin({
    methods: {
        /**
         * nested key를 resolve하고 번역된 메시지를 반환합니다
         */
        $tr(...target: string[]) {
            return this.$t(target.join('.'));
        },
        $tre(fallback: string, ...target: string[]) {
            let query = target.join('.');

            if (this.$te(query) ) {
                return this.$t(query);
            } else {
                return fallback;
            }
        }
    }
});

export default i18n;
