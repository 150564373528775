






















































































































































































import {Component, Vue, Prop, Watch, Mixins} from 'vue-property-decorator';
import CenterWrapper from '@/components/CenterWrapper.vue';
import DaumMapWrapper from '@/components/DaumMapWrapper.vue';
import { Product } from '../api/product';
import APIClient from '../api/client';
import {Member} from "@/api/member";
import StoreHelper from "@/mixins/StoreHelper";
import {mixins} from "vue-class-component";
import DescPreview from "@/components/DescPreview.vue";
// import Swiper JS
import Swiper from "swiper/core";
// core version + navigation, pagination modules:
import SwiperCore, { Navigation, Pagination } from 'swiper/core';


import { EventBus } from '@/lib/event-bus';

import {CustomException, FatalException, InformationNotification} from '@/api/client/notification';
import {MessageHandler} from "@/api/client/error";
import {Attachment} from "@/api/attachment";
import CommaInput from "@/components/CommaInput.vue";
import {Metadata, MetadataUtils} from "@/metadata";
import {InvestmentTransaction} from "@/api/transaction";



// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination]);



@Component({
    components: {
        CenterWrapper,
        DaumMapWrapper,
        DescPreview,
        CommaInput,
    }
})
export default class ProductDetail extends Mixins<StoreHelper>(StoreHelper) {

    /** 기본 이미지로 우회합니다. */
    public readonly URL_IMG_FALLBACK: string = "../assets/product-list/no-image.png";

    @Prop({ required: true })
    public productId!: string;
    public product: Product | null = null;

    // 최근 트랜잭션 1건
    private lastNotConfirmedTxn: InvestmentTransaction | null = null;
    private lastConfirmedTxn: InvestmentTransaction | null = null;

    private revGeocodeStatus: string = 'not yet';
    private roadAddr: string = '도로명 주소 없음';
    private landAddr: string = '지번 주소 없음';

    // Swiper에서 사용할 객체
    private swiper: Swiper | null = null; // null 허용
    private virtualData: object[] = [];

    // 투자 계산기에서 쓸 원금
    private inputInvestAmount: number = 0;

    private reverseGeocodeTest(status: string, roadAddress: string, address: string) {
        this.revGeocodeStatus = status;
        this.roadAddr = roadAddress;
        this.landAddr = address;
    }

    /** 상품 보고서 URL */
    get attachedDescURLs(): string[] {
        return this.product!.productDescs.map(a => a.url);
    }
    get attachedDescExts(): string[] {
        return this.product!.productDescs.map(a => a.ext);
    }
    get attachedDescFilenames(): string[] {
        return this.product!.productDescs.map(a => a.filename);
    }

    /** 상품 이미지 URL */
    private get attachedImageURLs(): string[] {
        if (this.product!.productImages.length > 0) {
            return this.product!.productImages.map(a => a.url);
        }
        return [this.URL_IMG_FALLBACK];
    }


    /** 투자 계산기 시작 */
    // 투자 원금
    private get calcInvestAmount(): number {
        return this.inputInvestAmount;
    }
    // 이자
    private get calcInterest(): number {
        if (this.product!.interestRate) {
            return this.inputInvestAmount * (this.product!.interestRate / 100);
        }
        return 0;
    }
    // 수익금 (연 기준)
    private get calcProfitYearly(): number {
        return this.calcInterest;
    }
    // 이자소득세
    private get calcInterestTax(): number {
        return this.calcInterest * ((this.product!.interestTaxRate) ? (this.product!.interestTaxRate * 0.01) : 0.154);
    }
    // 년간 수익금 (세후)
    private get calcProfitYearlyAfterTax(): number {
        return this.calcInterest - this.calcInterestTax;
    }
    // 월간 수익금 (세후)
    private get calcProfitMonthlyAfterTax(): number {
        return this.calcProfitYearlyAfterTax / 12;
    }

    @Watch('productId', { immediate: true })
    private async onProductIdChanged() {
        try {
            this.product = await (APIClient.instance).product.get(this.productId);

            if ( this.product.isMonthlyProduct) {
                // 월간 상품이면 월간 상품 페이지로 이동합니다.
                this.$router.replace(`/portfolios/${this.product.index}`);

                return false;
            }

            this.setMetadata();
        } catch (e) {
            // TODO: 없는 상품이면은.. 홈으로 이동시키기?
            this.$router.push('/');
            EventBus.$emit('toast-notification', new FatalException(
                MessageHandler.combineMessage("상품 정보를 가져오지 못했습니다.", e)
            ));
        }
        try {
            this.loadLastTransactionForThis();
        } catch (e) {
            // 투자내역을 가져오지 못해도 기존처럼 투자할 수 있으므로 아무것도 할 필요 없음.
        }
    }

    @Watch('product') // product 객체 변화가 감지되면 실행
    private initImageSlides() {
        // JavaScript 라이브러리 구동시 한 tick에 모두 넣으면
        // 일부는 렌더링 전이라 작동하지 않는 문제가 있는데
        // 그것을 다음 tick으로 미룸
        this.$nextTick(() => {
            this.destroySwiper();
            this.swiper = new Swiper('.prod-img-swiper-container', {
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                },
                // virtual: {
                //     slides: this.attachedImageURLs,
                //     renderExternal: (data: object[]) => {
                //         // assign virtual slides data
                //         this.virtualData = data;
                //     },
                // },
            });
        });
    }

    private mounted() {
        this.setMetadata();
    }

    private beforeDestroy() {
        // 페이지 이동 등의 이유로 Vue 컴포넌트를 벗어나는 경우 호출

        this.destroySwiper();
    }

    private destroySwiper() {
        if (this.swiper) {
            // swiper 객체 파괴
            this.swiper.destroy(true, true);
            this.swiper = null;
        }
    }


    private async loadLastTransactionForThis() {
        // 2020-01-14 투자금액 변경하기 버튼 무력화
        return ;
        //
        // if (this.product) {
        //     try {
        //         // 상품정보를 받았으니 해당 상품에 대한 transaction들이 있는지 조회.
        //         let investTransactions =
        //             await APIClient.instance.mypage.getMyTransactionsOnProduct(this.product!.index);
        //
        //         if (investTransactions) {
        //             // 그중에 맨 마지막 유효 상품을 골라냅니다.
        //             investTransactions.forEach(
        //                 (txn: InvestmentTransaction, index: number, txnList: InvestmentTransaction[]) => {
        //                     // if (!txn.confirmedAt && !txn.canceled) {
        //                     if (!txn.canceled) {
        //                         // 취소 안된거라면
        //                         // 최근 txn으로 처리합니다.
        //                         // 2019-12-12 컨펌 받은거라면 증액만 가능.
        //                         //            감액은 취소후 재투자 필요.
        //
        //                         if (!!txn.confirmedAt) {
        //                             this.lastConfirmedTxn = txn;
        //                         } else {
        //                             this.lastNotConfirmedTxn = txn;
        //                         }
        //                     }
        //                 });
        //         }
        //     } catch (error) {
        //         // 에러 메시지 처리를 할 필요가 있을까요?
        //     }
        // }
    }

    /** 투자하기 화면으로 이동 */
    private investThis() {

        if ( ! this.checkInvestible) {
            EventBus.$emit('toast-notification',
                new CustomException('이 상품은 투자할 수 없습니다.'));
            return false;
        }

        if ( ! this.profile ) {
            // 로그인 되어있지 않습니다.
            this.showLoginPopup();

            return false;
        }

        if ( this.product ) {

            if (this.lastNotConfirmedTxn) {
                this.$router.push(`/products/${this.product.index}/invest/change/${this.lastNotConfirmedTxn.index}`);
                return false;
            }

            // if (this.lastConfirmedTxn && ! this.lastNotConfirmedTxn) {
            //    // 컨펌되지 않은 투자내역이 없을 때만 추가 투자 활성화
            //    // 2019-12-12 컨펌된 투자 내역은 증액만 가능.
            //    // 감액은 취소 후 재투자가 필요합니다.
            // }

            this.$router.push(`/products/${this.product.index}/invest`);
            return false;
        }
    }

    get profile(): Member | null {
        return this.$state.profile;
    }


    // 현재 투자 가능한 상품인지 보여줍니다.
    private get checkInvestible(): boolean {
        if ( this.product ) {
            return this.product.state === "funding";
        }
        return false;
    }

    // 현재 투자가 가능하다면 투자하기를 보여줍니다.
    private get investBtnTxt(): string {
        if (this.checkInvestible) {
            let text = '투자하기';

            if (this.lastNotConfirmedTxn) {
                text = '투자금액 변경하기';
            }
            if (this.lastConfirmedTxn && ! this.lastNotConfirmedTxn) {
                // 컨펌되지 않은 투자내역이 없을 때만 추가 투자 활성화
                text = '추가 투자하기';
            }

            return text;
        }

        if (this.product) {
            let root: any = this.$root;
            return root.$tre('알 수 없음', 'productState', this.product.state).toString();
        }

        return '투자할 수 없음';
    }

    /**
     * 남은 금액을 0 이상으로만 보이게 조치된 "목표금액 - 투자금액"
     */
    private get remainingInvestAmount(): number {
        if (this.product) {
            let target: number = 0;

            if (this.product.totalInvestment) {
                // 목표 투자액
                target = this.product.totalInvestment;
            }

            // 현재 투자액
            let current: number = this.product.currentInvestmentAmount;

            // 남은 금액
            let remaining = target - current;

            if (remaining > 0) {
                // 남은 금액이 0보다 클 때만 남은 금액 반환
                return remaining;
            }
        }
        return 0;
    }

    private stepWarned() {
        EventBus.$emit('toast-notification', new InformationNotification(
            `입력란에 ${Number(1000000).toLocaleString('ko-kr')} 단위로만 값을 넣을 수 있습니다.`));
    }

    private setMetadata() {

        let meta: Metadata;

        let pageTitle: string = '상품보기';
        // let desc: string = '빌드온파트너스대부 투자상품보기 페이지입니다.';

        if (this.product) {
            // 상품이 로드되었을 때는 상품 정보를 보이게 합니다.
            pageTitle = `상품보기: ${this.product.title}`;
            // desc = `빌드온파트너스대부 투자상품 ${this.product.title} 입니다.`;
            //
            // if (this.product.intro) {
            //     desc = MetadataUtils.processContentBelow(this.product.intro);
            // }
        }
        meta = {
            title: pageTitle,
            // description: desc
        };
        EventBus.$emit("meta-title", meta);
    }

}
