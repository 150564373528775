




import {Component, Prop, Watch} from 'vue-property-decorator';
import DigitsOnly from "../mixins/DigitsOnly";
import {mixins} from "vue-class-component";

@Component
export default class CommaInput extends mixins (DigitsOnly) {

    @Prop({default: 0})
    public initVal!: number;

    @Prop({ default: Number.MIN_SAFE_INTEGER })
    public minVal!: number;

    @Prop({ default: Number.MAX_SAFE_INTEGER })
    public maxVal!: number;

    @Prop({ default: 1 })
    public stepNum!: number;

    @Prop({default: ''})
    public placeholder!: string;

    @Prop({default: false})
    public showZero!: boolean;


    private numInput: number = 0;

    private valueString: string = '';

    private inputToNumber!: HTMLInputElement;

    private isInitial: boolean = true;

    @Watch('valueString', { immediate: true })
    private onInputNumberChanged() {
        // 최저/최대 값 단속을 수행합니다.
        if (this.inputToNumber) {
            // 2019-12-12 : 입력중일 때도 콤마가 보이게 하고 대신 step 단위로 핸들을 눌러 조정하는 부분을 삭제합니다.

            // 현재 수 모드일 때만 입력값을 바꿀 수 있도록 합니다.
            // if (this.inputToNumber.type === 'number') {
                let isMinus = false;
                if (this.valueString.startsWith('-')) {
                    // 음수값입니다.
                    isMinus = true;

                    if (this.valueString.length === 1) {
                        // - 기호가 입력되지 않는걸 막고자 일단 보류.
                        return false;
                    }
                }

                if (this.valueString === '0-') {
                    // 음수를 입력하려합니다.
                    this.valueString = '-';

                    // - 기호가 입력되지 않는걸 막고자 일단 보류.
                    return false;
                }

                // 수 모드 = 입력 도중에는 값이 이중으로 바뀌려다 충돌납니다.
                // v-model로 연결해둔 valueString이 string이므로 parseInt 해야합니다.

                let filteredOutString = this.valueString.replace(/[^\d]/g, '');
                if (isMinus) {
                    filteredOutString = '-' + filteredOutString;
                }
                this.numInput = parseInt(filteredOutString, 10);

                if ( ! isNaN(this.numInput) ) {
                    // 수라면 아래 비교를 수행

                    if (this.numInput < this.minVal) {
                        // 최소값보다 적을 때 최소값으로 규제
                        this.numInput = this.minVal;

                    } else if (this.numInput > this.maxVal) {
                        // 최대값 넘었을 때 최대값으로 규제
                        this.numInput = this.maxVal;
                    }
                } else {
                    // 수가 아니면 0을 표출합니다.
                    this.numInput = 0;
                }

                // 10진수 문자열로 변환 한 수
                this.valueString = this.numInput.toLocaleString('ko-kr');
                // 그냥 .value = 새로운 값으로는 안되는 것 같다. v-model에서 가공해야할 듯
                this.inputToNumber.setAttribute('value', this.valueString);

                // v-model 로 연결된 변수에 값을 전달합니다.
                this.$emit('input', this.numInput);
            // }
        }
    }

    @Watch('initVal', { immediate: true })
    private onInitValChanged() {
        if (this.inputToNumber) {
            // 2019-12-12 : 입력중일 때도 콤마가 보이게 하고 대신 step 단위로 핸들을 눌러 조정하는 부분을 삭제합니다.

            // if ( this.inputToNumber.type !== 'number' || this.isInitial) {
                // 수 모드 = 입력 도중에는 값이 이중으로 바뀌려다 충돌납니다.
                // 또는 초기값 설정 모드일 경우에만

                // 이제 초기값 설정모드는 아닙니다.
                this.isInitial = false;

                this.numInput = this.initVal;

                this.valueString = this.numInput.toLocaleString('ko-kr');

                // 강제 변경은 수가 아닌 상태로 합니다.
                // this.inputToNumber.type = 'number';

                // 강제 값 변경 직후엔 포커스 없는 상태로 시작
                this.onLostFocus();
            // }
        }
    }

    private mounted() {
        // 해당 입력란의 참조를 기억
        this.inputToNumber = this.$refs.inputToNumber as HTMLInputElement;

        this.onInitValChanged();
    }

    /**
     * 해당 입력창에서 입력할 수 있게 됐을 때
     */
    private onFocus() {
        if (this.inputToNumber) {
            this.valueString = this.numInput.toLocaleString('ko-kr');
            this.inputToNumber.setAttribute('value', this.valueString);

            // number 만 허락하는 input으로 전환을 맨 나중에합니다.
            // 크롬에서 띄우는 형식 불일치 경고를 해소코자 함.
            // this.inputToNumber.type = 'number';
        }
    }

    /**
     * 해당 입력창에서 다른 곳을 눌렀을 때
     */
    private onLostFocus() {
        let self = this;
        setTimeout( () => {
            // inputToNumber가 현재 활성 요소가 아니면 그제서야 onBlur 를 실행합니다.
            if (document.activeElement !== self.inputToNumber) {
                self.handleLostFocus();
            }
        }, 0);
    }

    /**
     * 해당 입력창이 현재 활성 요소가 아닐 때 시도해야 파이어폭스에서 누르자마자 다시 풀리는 현상을 예방합니다.
     */
    private handleLostFocus() {
        if (this.inputToNumber) {

            // step 단위로 숫자가 잘 되고 있는지 판단
            this.followSteps();

            // 다시 숫자위주 그러나 콤마정도는 들어가는 형식으로 전환
            this.inputToNumber.type = 'tel';

            let zeroFilter = false;
            if (this.showZero) {
                // 0도 보이게 합니다 무시

            } else {
                // 0은 없는 것으로 간주하고 값을 날립니다.
                if (this.numInput === 0) {
                    zeroFilter = true;
                }
            }

            // 값을 날리는 부분
            if (zeroFilter) {
                this.valueString = '';
            } else {
                this.valueString = this.numInput.toLocaleString('ko-kr');
            }

            this.inputToNumber.setAttribute('value', this.valueString);
        }
    }

    private followSteps() {
        if (this.stepNum) {
            if (this.stepNum > 1) {
                // step이 1 초과일 때만 작동

                // 입력 수를 단계 수로 나눈 나머지를 구해 step에서 벗어나있는지 확인
                let residue = this.numInput % this.stepNum;
                if (residue === 0) {
                    // step 단계에 맞아떨어짐. 할 필요 없음

                } else {
                    // step단계에 맞아 떨어지지 않음.
                    // 입력값에서 나머지 값을 빼서 step에 맞춥니다.
                    this.numInput = this.numInput - residue;

                    // v-model 로 연결된 변수에 값을 전달합니다.
                    this.valueString = this.numInput.toLocaleString('ko-kr');
                    this.onInputNumberChanged();

                    // v-model 로 연결된 변수에 값을 전달합니다.
                    this.$emit('stepwarn', '');
                }
            }
        }
    }
}
