










import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';

import iziToast, {IziToastSettings} from 'izitoast';

import { EventBus } from '@/lib/event-bus';

import CenterWrapper from '@/components/CenterWrapper.vue';
import Navbar from '@/components/Navbar.vue';
import MainFooter from '@/components/MainFooter.vue';

import LoginPopup from '@/components/LoginPopup.vue';
import Drawer from '@/components/Drawer.vue';

import { BaseError } from './api/client/error';
import { Notification, FatalException, JustNotification} from '@/api/client/notification';
import {Metadata} from "@/metadata";
import IeWarn from "@/components/IeWarn.vue";

@Component({
    components: {
        Navbar,
        CenterWrapper,
        LoginPopup,
        Drawer,
        MainFooter,
        IeWarn,
    },
    computed: {
        // @note https://github.com/vuejs/vue-class-component/issues/109
        ...mapState([
            'isLoginPopupVisible',
            'isDrawerVisible'
        ])
    }
})
export default class App extends Vue {
    public readonly isLoginPopupVisible!: boolean;
    public readonly isDrawerVisible!: boolean;
    public isApplicationReady: boolean = false;

　  private toastSingletonArr: IziToastSettings[] = [];
    private toastMaximumQty: number = 1;
    private toastOnceChecked: boolean = false;
    private toastIsBlocked: boolean = false;
    private toastWrapper: HTMLDivElement | null = null;

    private notifyFunc(notification: Notification) {
        this.notify(notification);
    }

    private setLimitFunc(maxNumOfNotifs: number) {
        this.setNotifyLimit(maxNumOfNotifs);
    }

    private flushToastFunc() {
        this.flushToasts();
    }

    private metaModifyFunc(meta: Metadata) {
        this.setMetadata(meta);
    }

    // route 변경 시 로그인 팝업 해제
    @Watch('$route')
    private onRouteChanged() {
        this.$store.commit('setLoginPopupVisible', false);
    }

    private async mounted() {
        try {
            await this.$store.dispatch("fetchProfile");
        } catch (e) {
            console.log(e);
        } finally {
            this.isApplicationReady = true;
        }
    }

    private created() {
        this.setupIzitoastSettings();

        EventBus.$on("toast-notification", this.notifyFunc);
        EventBus.$on("toast-set-limit", this.setLimitFunc);
        EventBus.$on("toast-flush", this.flushToastFunc);

        EventBus.$on("meta-title", this.metaModifyFunc);
    }

    private setupIzitoastSettings() {
        const defaultSettings: any = {
            position: 'topCenter',
            timeout: 5000
        };

        iziToast.settings(defaultSettings);

        // 차단 여부를 확인합니다.
        this.testToastNow();
    }


    private testToastNow() {
        if ( ! this.toastOnceChecked ) {
            this.notify(new JustNotification('사이트를 로드하고 있습니다. 잠시 기다려주십시오.'));
        }
    }

    /**
     * 토스트 차단 여부를 최소 한 번 확인하는 메소드
     * 한 번 결과를 보고 나면 여러번 실행되지 않습니다.
     *
     * onOpened 에서 사용해 차단 여부를 첫 방문에도 조회할 수 있습니다.
     */
    private checkToastIsBlocked() {
        if ( ! this.toastOnceChecked ) {
            // 토스트가 차단되어있는지 확인하기 위해 ublock origin에서 차단하는 css 선택자를 가져옵니다.
            let capsules = document.querySelectorAll('.iziToast-capsule');

            if (capsules.length >= 1) {
                for (let capsule of capsules) {
                    let capElm = capsule as HTMLElement;
                    // 그냥 HTMLElement.style은 인라인 스타일만 볼 수 있습니다. getComputedStyle를 활용해주세요.
                    let display = getComputedStyle(capElm).getPropertyValue("display");

                    if (display && display.includes("none")) {
                        // style의 display에 none이 박혀있으면 차단된 것
                        // 토스트가 차단되어 있습니다.
                        this.toastIsBlocked = true;

                        // 나갑니다.
                        break;
                    }
                }

                // 토스트 차단여부는 확인되었습니다. 페이지 새로고침 전까지 이대로 사용합니다.
                this.toastOnceChecked = true;
            } else {
                // element 가 나오기를 기다렸는데 없다고 나옵니다 1 프레임 안에 다시 시도 합니다.
                setTimeout(this.checkToastIsBlocked, 16);
            }

            this.flushToasts();

            // toastWrapper 는 차후 써야하므로 다시 보이게 원복시켜줍니다.
            // fade 애니메이션이 뜰 정도만 기다렸다가 말이죠.
            if (this.toastWrapper) {
                setTimeout(() => {
                    this.toastWrapper!.style.opacity = "1.0";
                    this.toastWrapper = null;
                }, 500);
            }
        }
    }

    private onToastWrapperAppears() {
        // toastWrapper를 투명하게 해서 차단감지 시간동안 보이지 않게 합니다.
        this.toastWrapper = document.querySelector('.iziToast-wrapper');
        if (this.toastWrapper) {
            this.toastWrapper.style.opacity = "0.0";
        }
    }

    private notify(notification: Notification) {

        if (this.toastSingletonArr.length >= this.toastMaximumQty ) {
            this.flushToasts();
        }

        if ( this.toastIsBlocked ) {
            // 토스트가 차단되어있습니다. window.alert로 우회합니다.
            window.alert(notification.message);
        } else {
            let synthesizedId: string =
                `toast-${new Date().getTime()}-${Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)}`;

            const toastOption: any = {
                id: synthesizedId,
                message: notification.message,
                onClosed: () => this.removeToast(synthesizedId)
            };

            if ( ! this.toastOnceChecked ) {
                // 투명으로 하지 않으면 uBlock origin이 빨리 잡아내지 못해
                // 토스트 차단여부를 검사하지 못할 수 있습니다.
                toastOption.backgroundColor = "rgba(255, 255, 255, 0.0)";
                toastOption.messageColor = "rgba(255, 255, 255, 0.0)";
                toastOption.progressBarColor = "rgba(255, 255, 255, 0.0)";

                // 바로 시험해서는 불확실한 결과가 나옵니다.
                // onOpened 에서 체크하도록 해 열리고 나서 실제로 차단됐는지를 확인하게 합니다.
                toastOption.onOpened = () => this.checkToastIsBlocked();

                toastOption.onOpening = () => this.onToastWrapperAppears();
            }

            if (notification.type === 'warning') {
                iziToast.warning(toastOption);
            } else if (notification.type === 'success') {
                iziToast.success(toastOption);
            } else if (notification.type === 'show') {
                iziToast.show(toastOption);
            } else if (notification.type === 'info') {
                iziToast.info(toastOption);
            } else if (notification.type === 'error') {
                iziToast.error(toastOption);
            } else {
                iziToast.show(toastOption);
            }

            // 잠금을 걸고 토스트 객체를 목록에 추가합니다.
            this.toastSingletonArr.push(toastOption);
        }

    }


    /**
     * 토출가능한 토스트 갯수를 정합니다.
     * @param maxNumOfNotifs
     */
    private setNotifyLimit(maxNumOfNotifs: number) {
        this.toastMaximumQty = maxNumOfNotifs;
    }

    /**
     * 토스트 전체를 비워버립니다.
     */
    private flushToasts() {
        for (let toastConf of this.toastSingletonArr) {
            iziToast.hide(toastConf, `#${toastConf.id!}`, 'overrided');
        }
        this.toastSingletonArr = [];
    }

    /**
     * 토스트 목록을 순회해 닫힌 토스트는 목록에서 빼도록 합니다. onClosed에 매핑됨
     * @param id synthesizedId
     */
    private removeToast(id: string) {
        for (let cur = 0; cur < this.toastSingletonArr.length; cur++) {
            if (this.toastSingletonArr[cur].id === id) {
                this.toastSingletonArr.splice(cur, 1);

                // 찾아서 삭제했습니다. 같은 id로 던 나올 리가 없으니 반복을 종료합니다.
                break;
            }
        }
    }

    private errorCaptured(error: BaseError, vm: any, info: string): boolean {
        EventBus.$emit("toast-notification", new FatalException(error.message));
        return false;
    }

    /**
     * 기존 메타 태그(우리 식별자 한)를 삭제한 뒤 새 메타태그를 삽입합니다.
     * @param meta Metadata 객체
     */
    private setMetadata(meta: Metadata) {

        // 타이틀을 변경합니다.
        if (meta.title) {
            meta.title = meta.title += ' - 빌드온파트너스대부';
            let titleEl = document.querySelector('head title') as HTMLTitleElement;
            if (titleEl) {
                titleEl.innerText = meta.title;
            }
        }

        // 더 이상 유효하지 않은 메타태그를 우리 식별자를 통해 골라내 삭제합니다.
        Array.from(document.querySelectorAll('[data-vue-router-controlled]'))
            .map((el: Element) => el.parentNode!.removeChild(el));

        // 소셜 미디어에서 쓰이는 open graph 대응
        const prefixKeys: string[] = ['', 'og:'];

        // 이제 새 메타 태그를 문서에 추가합니다.
        Object.keys(meta).forEach(key => {
            // 식별자별로 여러 메타 태그를 적용합니다.
            for (let prefixKey of prefixKeys ) {
                // 문서의 head 부분에 메타태그 정의를 삽입하고자 합니다.
                const tag: HTMLMetaElement = document.createElement('meta');

                tag.setAttribute(prefixKey + key, (meta as any)[key]);

                // 아래의 식별자를 속성에 담음으로써, 다른 태그를 건드리지 않도록 합니다.
                tag.setAttribute('data-vue-router-controlled', '');

                // 태그를 head 부분에 추가.
                document.head.appendChild(tag);
            }
        });
    }

    private destroyed() {
        EventBus.$off("meta-title", this.metaModifyFunc);

        EventBus.$off("toast-flush", this.flushToastFunc);
        EventBus.$off("toast-set-limit", this.setLimitFunc);
        EventBus.$off("toast-notification", this.notifyFunc);
    }
}
