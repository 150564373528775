




















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CenterWrapper from '@/components/CenterWrapper.vue';

import { Member } from '@/api/member';
import { APIClient } from "@/api/client/client";
import { DashboardValues } from '@/api/mypage';

import { EventBus } from '@/lib/event-bus';

import {
    InformationNotification,
    FatalException, SuccessNotification
} from '@/api/client/notification';
import {MessageHandler} from "@/api/client/error";
import ScheduleBox from "@/views/mypage/ScheduleBox.vue";
import GraphBox from "@/views/mypage/GraphBox.vue";
import InvestBox from "@/views/mypage/InvestBox.vue";

@Component({
    components: {
        CenterWrapper,
        InvestBox,
        ScheduleBox,
        GraphBox,
    },
})
export default class DashboardBox extends Vue {

    /** 객체 fetch가 끝나 값을 렌더링 할 수 있을지 정하는 플래그 */
    private resourceReady: boolean = false;

    @Prop({ required: true })
    private member!: Member;

    private readonly dateNow: Date =  new Date();
    private datePayment: Date =  new Date();

    private dashboardValues: DashboardValues | null = null;

    private get accumulateInterestAfterTaxPaid() { /** 세후이자 */
        return (this.dashboardValues!.interestSum - this.dashboardValues!.interestTaxSum)
            - (this.dashboardValues!.remainingInterestToRepay - this.dashboardValues!.remainingInterestToTax);
    }

    private get totalAmount() {
        if (this.member) {
            return (this.member.totalVbankAmount)
                + (this.dashboardValues!.amountSum - this.dashboardValues!.paidAmountSum)
                + (this.dashboardValues!.remainingInterestToRepay - this.dashboardValues!.remainingInterestToTax);
        } else {
            return 0;
        }
    }

    private get getUserTypeStr() {
        if (this.member) {
            let root: any = this.$root;
            return root.$tre('알 수 없음', 'userType.' + this.member.userType) + " 투자자";
        }
        return '알 수 없음';
    }

    get currentProfitRate(): string {
        let interestSum: number = 0;
        let interestTaxSum: number = 0;
        let amountSum: number = 0;

        if (this.dashboardValues!.interestSum) {
            interestSum = this.dashboardValues!.interestSum;
        }

        if (this.dashboardValues!.interestTaxSum) {
            interestTaxSum = this.dashboardValues!.interestSum;
        }

        if (this.dashboardValues!.amountSum) {
            amountSum = this.dashboardValues!.amountSum;
        }

        let profit = ( ( interestSum - interestTaxSum ) / amountSum);

        if ( isNaN(profit) ) {
            return '0.00';
        }


        return profit.toFixed(2);
    }

    mounted() {
        this.fetchThings();
    }

    /** 초기에 값을 불러오는 메소드로, 모든 fetch가 성공해야 렌더링을 허용합니다 */
    async fetchThings() {
        try {

            await this.fetchDashboard();

            this.datePayment = new Date();

            // 이번 지급일보다 오늘이 더 크면 지난겁니다.
            if (this.datePayment.getDate() > this.dashboardValues!.payDayOfThisMonth) {
                // 지급일 계산에 추가
                this.datePayment.setMonth(this.datePayment.getMonth() + 1);
            }

            this.resourceReady = true;
        } catch (error) {
            EventBus.$emit('toast-notification', new FatalException(
                MessageHandler.combineMessage('대시보드 정보를 불러오지 못했습니다.', error)
            ));
        }

    }

    /** 대시보드 값을 가져옵니다 */
    async fetchDashboard() {
        try {
            this.dashboardValues = await APIClient.instance.mypage.getDashboard();
        } catch (error) {
            EventBus.$emit('toast-notification', new FatalException(
                MessageHandler.combineMessage('대시보드 정보를 불러오지 못했습니다.', error)
            ));
            throw error;
        }
    }


    /**
     * 출금계좌 은행명과 띄어쓰기 가상계좌 번호를 받아옵니다.
     */
    private get copyRbankNum() {
        if (this.isRbankNumExists) {
            return this.member.depositAccountBankName + " " + this.member.depositAccountNumber;
        } else {
            return '출금계좌 인증을 실행해주세요.';
        }
    }

    private get isRbankNumExists() {
        if (this.member) {
            return (this.member.depositAccountBankName) && (this.member.depositAccountNumber);
        }
        return false;
    }

    /**
     * 가상계좌 계좌번호를 클립보드로 복사합니다.
     */
    private copyRbankNumToClipboard() {
        let vankNumber = this.$refs['copy-clipboard-vbank-number'] as HTMLInputElement;
        vankNumber.setAttribute('type', 'text');    // hidden은 선택 불가?
        vankNumber.select();

        try {
            let successful = document.execCommand('copy');
            if (successful) {
                EventBus.$emit('toast-notification', new SuccessNotification('계좌번호가 클립번호에 복사되었습니다.'));
            } else {
                EventBus.$emit('toast-notification', new FatalException('계좌번호를 복사하지 못했습니다.'));
            }
        } catch (err) {
            EventBus.$emit('toast-notification', new FatalException(
                MessageHandler.combineMessage('계좌번호를 복사하지 못했습니다.', err)
            ));
        }

        /* 선택 취소 */
        vankNumber.setAttribute('type', 'hidden');

        let selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
    }
}
