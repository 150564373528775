




















import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import { VueDaumPostcode } from "vue-daum-postcode";

@Component({
    components: {
        VueDaumPostcode,
    }
})
export default class DaumPostcodeWrapper extends Vue {

    /** ************************** Props ************************** */

    @Prop({ default: "" })
    private q!: string;

    @Prop({ default: false })
    private animation!: boolean;

    @Prop({ default: false })
    private noAutoMapping!: boolean;

    @Prop({ default: false })
    private noShorthand!: boolean;

    @Prop({ default: 0 })
    private pleaseReadGuide!: number;

    @Prop({ default: 1.5 })
    private pleaseReadGuideTimer!: number;

    @Prop({ default: 10 })
    private maxSuggestItems!: number;

    @Prop({ default: false })
    private showMoreHName!: boolean;

    @Prop({ default: false })
    private hideMapBtn!: boolean;

    @Prop({ default: false })
    private hideEngBtn!: boolean;

    @Prop({ default: false })
    private alwaysShowEngAddr!: boolean;

    @Prop({ default: false })
    private zonecodeOnly!: boolean;

    @Prop({ required: false })
    private theme!: object;

    private onComplete(data: any) {
        this.$emit('complete', data);
    }

}
