















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CenterWrapper from '@/components/CenterWrapper.vue';
import {APIClient} from "@/api/client/client";

import { EventBus } from "@/lib/event-bus";

import { CustomException } from "@/api/client/notification";
import {MessageHandler} from "@/api/client/error";
import {InvestmentTransaction} from "@/api/transaction";
import {MonthlyReserveTxnCalc} from "@/api/mypage";

@Component({
    components: {
        CenterWrapper,
    },
})
export default class MonthlyReserveInvestTable extends Vue {

    private isResourceReady: boolean = false;
    private investAmount: number = 12000000 / 12;
    private interestRate: number = 5 / 100;
    private interestTaxRate: number = 15.4 / 100;
    private fullRepaymentMonth: number = 12;
    private currentMonthStr: string = '0000-00';

    @Prop({ default: [] })
    private txnListPerParentTxn!: InvestmentTransaction[];

    @Watch('txnListPerParentTxn', { immediate: true })
    handleChangeTxnListPerParentTxn() {
        this.isResourceReady = false;

        this.investAmount = 0;
        this.interestRate = 0;
        this.interestTaxRate = 0;
        this.fullRepaymentMonth = 0;

        if (this.txnListPerParentTxn && this.txnListPerParentTxn.length > 0) {
            this.interestRate = this.txnListPerParentTxn[0].product!.interestRate / 100;
            this.interestTaxRate = this.txnListPerParentTxn[0].product!.interestTaxRate / 100;
            this.fullRepaymentMonth = this.txnListPerParentTxn.length;


            let currentDate = new Date();
            this.currentMonthStr = this.getMonthStrOfDate(currentDate);

            ////////////////////////////////////////////////////////////////////////////////
            // 이번달 통계를 제출하기 위한 로직
            let dueAmountThisMonth = 0;
            let depositAmountThisMonth = 0;
            for (const txn of this.txnListPerParentTxn) {
                // checkTxnStatus(txn) 에서 일부 발췌
                if (txn.countStartedAt) {
                    let countStartDate = new Date(txn.countStartedAt);

                    let countStartMonthStr = this.getMonthStrOfDate(countStartDate);
                    if (this.currentMonthStr === countStartMonthStr) {
                        // 현재 월과 기산월이 같음 = 이번달에 입금 해야함!
                        if (txn.monthlyReservePaymentStatus === 'deposit') {
                            depositAmountThisMonth = txn.amount!;
                        } else {
                            dueAmountThisMonth = txn.amount!;
                        }

                        // 반복문을 나갑니다.
                        break;
                    }
                }
            }

            let calcResult: MonthlyReserveTxnCalc = {
                depositAmount: this.estimateAmountDepositAll(this.txnListPerParentTxn),
                afterTaxInterest: this.estimateAfterTaxInterestAll(this.txnListPerParentTxn),
                taxForInterest: this.estimateTaxForInterestAll(this.txnListPerParentTxn),
                dueAmountThisMonth,
                depositAmountThisMonth,
            };
            this.$emit('txn-calculate-finished', calcResult);
        }

        this.isResourceReady = true;
    }

    getMonthStrOfDate(dateObj: Date) {
        return `${(dateObj.getFullYear() + 1900)}-${String(dateObj.getMonth()).padStart(2, '0')}`;
    }

    matchTxnConfirmedMonthStrWithCountStart(txn: InvestmentTransaction): boolean {
        const countStartDateOfTxn = new Date(txn.countStartedAt!);
        const confirmedDateOfTxn = new Date(txn.confirmedAt!);

        if (Number.isNaN(countStartDateOfTxn.getTime()) || Number.isNaN(confirmedDateOfTxn.getTime())) {
            // 올바르지 않은 date입니다.
            // false 반환
            return false;
        }
        return this.getMonthStrOfDate(countStartDateOfTxn) === this.getMonthStrOfDate(confirmedDateOfTxn);
    }

    estimateInterest(investAmount: number, currentMonth: number) {
        return investAmount * this.interestRate
            * ((this.fullRepaymentMonth - (currentMonth - 1)) / this.fullRepaymentMonth);
    }

    estimateAfterTaxInterest(investAmount: number, currentMonth: number) {
        return this.estimateInterest(investAmount, currentMonth) * (1.00 - this.interestTaxRate);
    }

    estimateTaxForInterest(investAmount: number, currentMonth: number) {
        return this.estimateInterest(investAmount, currentMonth) * (this.interestTaxRate);
    }

    estimateDepositCountAll(txnListPerParentTxn: InvestmentTransaction[]) {
        let count = 0;
        if (txnListPerParentTxn) {
            for (const txn of txnListPerParentTxn) {
                if (this.checkDepositStatus(txn) === 'deposit' ) {
                    // 완납분만 추가
                    count++;
                }
            }
        }
        return count;
    }

    estimateAmountDepositAll(txnListPerParentTxn: InvestmentTransaction[]) {
        let amountAll = 0;
        if (txnListPerParentTxn) {
            for (const txn of txnListPerParentTxn) {
                if (this.checkDepositStatus(txn) === 'deposit' ) {
                    // 완납분만 추가
                    amountAll = amountAll + txn.amount!;
                }
            }
        }
        return amountAll;
    }

    estimateInterestAll(txnListPerParentTxn: InvestmentTransaction[]) {
        let interestAll = 0;
        let count = 0;
        if (txnListPerParentTxn) {
            for (const txn of txnListPerParentTxn) {
                count++;
                if (this.checkDepositStatus(txn) === 'overdue' ) {
                    // 미납 분은 건너뜀
                    continue;
                }
                // 나머지는 추가
                interestAll = interestAll
                    + this.estimateInterest(txn.amount!, count);
            }
        }
        return interestAll;
    }

    estimateAfterTaxInterestAll(txnListPerParentTxn: InvestmentTransaction[]) {
        return this.estimateInterestAll(txnListPerParentTxn) * (1.00 - this.interestTaxRate);
    }

    estimateTaxForInterestAll(txnListPerParentTxn: InvestmentTransaction[]) {
        return this.estimateInterestAll(txnListPerParentTxn) * (this.interestTaxRate);
    }

    checkDepositStatus(txn: InvestmentTransaction) {
        return txn.monthlyReservePaymentStatus;
        // if (txn.isConfirmed) {
        //     // 컨펌됐다면 납입된거임
        //     return "deposit";
        // } else if (txn.countStartedAt) {
        //     let countStartDate = new Date(txn.countStartedAt);
        //
        //     let countStartMonthStr = this.getMonthStrOfDate(countStartDate);
        //     if (this.currentMonthStr < countStartMonthStr) {
        //         // 현재 월이 기산월보다 작음 = 미래
        //         return "future";
        //     } else if (this.currentMonthStr === countStartMonthStr) {
        //         // 현재 월과 기산월이 같음 = 이번달에 입금 해야함!
        //         return "indue";
        //     } else {
        //         // 현재 월이 기산일보다 큼 = 미납
        //         return "overdue";
        //     }
        // } else {
        //     return null;
        // }
    }
}
