export abstract class Notification {
    abstract get message(): string;
    abstract get type(): string;

    toString(): string {
        return `${this.message}`;
    }
}

export class SuccessNotification extends Notification {
    constructor(public customMessage: string) {
        super();
        Object.setPrototypeOf(this, SuccessNotification.prototype);
    }

    get type(): string {
        return 'success';
    }

    get message(): string {
        return this.customMessage;
    }
}

export class InformationNotification extends Notification {
    constructor(public customMessage: string) {
        super();
        Object.setPrototypeOf(this, InformationNotification.prototype);
    }

    get type(): string {
        return 'info';
    }

    get message(): string {
        return this.customMessage;
    }
}

export class JustNotification extends Notification {
    constructor(public customMessage: string) {
        super();
        Object.setPrototypeOf(this, JustNotification.prototype);
    }

    get type(): string {
        return 'show';
    }

    get message(): string {
        return this.customMessage;
    }
}

export abstract class Exception extends Notification {
    constructor() {
        super();
        Object.setPrototypeOf(this, Exception.prototype);
    }

    abstract get message(): string;

    get type(): string {
        return 'warning';
    }
}

export class AuthException extends Exception {
    constructor() {
        super();
        Object.setPrototypeOf(this, AuthException.prototype);
    }

    get message(): string {
        return '로그인이 되어 있어야 합니다.';
    }
}

export class FatalException extends Exception {
    constructor(public customMessage: string) {
        super();
        Object.setPrototypeOf(this, FatalException.prototype);
    }

    get type(): string {
        return 'error';
    }

    get message(): string {
        return this.customMessage;
    }
}

export class CustomException extends Exception {
    constructor(public customMessage: string) {
        super();
        Object.setPrototypeOf(this, CustomException.prototype);
    }

    get type(): string {
        return 'warning';
    }

    get message(): string {
        return this.customMessage;
    }
}
