



























import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';

import { EventBus } from '@/lib/event-bus';

import { Faq } from '@/api/faq';
import APIClient from '@/api/client';
import { CustomException } from '@/api/client/notification';

import CenterWrapper from '@/components/CenterWrapper.vue';
import FaqTile from '@/components/FaqTile.vue';
import {MessageHandler} from "@/api/client/error";
import {Metadata} from "@/metadata";

interface Category {
    index: number;
    name: string;
    label: string;
}

@Component({
    components: {
        CenterWrapper,
        FaqTile
    }
})
export default class InvestmentFAQ extends Vue {

    public faqList: Faq[] = [];
    public originalFaqList: Faq[] = [];

    public readonly categories: Category[] = [
        { index: 0, name: '투자관련', label: '투자' },
        { index: 1, name: '대출관련', label: '대출' },
        { index: 2, name: '기타사항', label: '일반' },
    ];

    public isActive: boolean[] = [true, false, false];

    public switchFaqCategory(index: number) {
        for (let currentIdx = 0; currentIdx < this.categories.length; currentIdx++) {
            this.isActive[currentIdx] = false;
        }

        this.isActive[index] = true;

        this.faqList = this.originalFaqList.filter( faq => faq.category === this.categories[index].label );
    }

    /**
     * 마운트 되었을 때 호출됩니다.
     */
    private mounted() {
        this.setMetadata();

        this.loadFaqs();
    }

    private async loadFaqs() {
        try {
            let fetchResult = await APIClient.instance.faq.faqList();
            this.originalFaqList = fetchResult.content;
            this.switchFaqCategory(0);
        } catch (e) {
            EventBus.$emit('toast-notification',
                MessageHandler.combineMessage("리소스를 가져올 수 없습니다", e)
            );
        }
    }

    private setMetadata() {
        let meta: Metadata = {
            title: '투자 FAQ',
            // description: '빌드온파트너스대부 투자에 관해 궁금하신 것들을 모았습니다.'
        };
        EventBus.$emit("meta-title", meta);
    }
}

