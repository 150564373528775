









































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import VueDaumMap from '@/lib/VueDaumMaps.vue';

@Component({
    components: {
        VueDaumMap
    }
})
export default class DaumMapWrapper extends Vue {
    private readonly daumMapKey: string = process.env.VUE_APP_DAUMMAP_KEY!;

    /** ************************** Props ************************** */

    /** 지도를 강제로 표시합니다 */
    @Prop({ default: false })
    private mapForcedToShow!: boolean;

    /** 지도에 클릭 입력을 받아 좌표에 표시시킬지 여부 */
    @Prop({ default: false })
    private mapIsInteractive!: boolean;

    /** 지오코딩 탐색용 주소 */
    @Prop({ required: false })
    private geocodeAddressComputed!: string;

    /** 추가로 불러올 라이브러리 */
    @Prop({ required: false })
    private libraries!: string[];
    private librariesComputed: string[] = [];

    /** 위경도 중 위도 */
    @Prop({ required: false })
    private latY!: string;

    /** 위경도 중 경도 */
    @Prop({ required: false })
    private lngX!: string;

    /** 중심 좌표 (없으면 우회합니다) */
    @Prop({ required: false })
    private center!: Coordinate;
    private daumMapsCenterComputed: Coordinate = this.center;

    /** 지도의 레벨(확대, 축소 정도) */
    @Prop({ required: false })
    private level!: number;
    private levelComputed: number = 3;

    /** 지도 형식 */
    @Prop({ default: 1 }) // VueDaumMap.MapTypeId.NORMAL
    private mapTypeId!: boolean;

    /**  마우스 드래그, 휠, 모바일 터치를 이용한 시점 변경(이동, 확대, 축소) 가능 여부 */
    @Prop({ required: false })
    private draggable!: boolean;

    /** 마우스 휠, 모바일 터치를 이용한 확대 및 축소 가능 여부 */
    @Prop({ required: false })
    private scrollwheel!: boolean;

    /** 더블클릭 이벤트 및 더블클릭 확대 가능 여부 */
    @Prop({ required: false })
    private disableDoubleClick!: boolean;

    /** 더블클릭 확대 가능 여부 */
    @Prop({ required: false })
    private disableDoubleClickZoom!: boolean;

    /** 투영법 지정 (기본값: daum.maps.ProjectionId.WCONG) */
    @Prop({ required: false })
    private projectionId!: string;

    /** 지도 타일 애니메이션 설정 여부 (기본값: true) */
    @Prop({ default: true })
    private tileAnimation!: boolean;

    /** 키보드의 방향키와 +, – 키로 지도 이동,확대,축소 가능 여부 */
    @Prop({ required: false })
    private keyboardShortcuts!: [boolean, object];

    /** ************************ END of props *********************** */

        // JavaScript 라이브러리 그대로 호출하므로 any를 썼습니다.
    private vueDaumMap: any;
    private daumMapTimer: any;

    /** 지도에 좌표가 있는지를 검수하는 변수 */
    private mapHasCoordinate: boolean = true;

    /** 생성자 일부 멤버의 초기화를 담당 */
    constructor() {
        super();

        if (this.libraries) {
            this.librariesComputed = this.libraries;
        }

        if (this.level) {
            this.levelComputed = this.level;
        }

    }


    /** 지도가 표시되어야만 하는 경우 */
    private get mapHasToBeShown(): boolean {
        return this.mapHasCoordinate || this.mapForcedToShow || this.mapIsInteractive;
    }

    /** 지오코딩 주소 */
    public get geocodeAddress(): string {
        return this.geocodeAddressComputed;
    }

    public set geocodeAddress(geocodeAddress: string) {
        this.geocodeAddressComputed = geocodeAddress;
    }

    /** 지도의 현재 위경도 */
    public get daumMapCenter(): Coordinate {
        return this.daumMapsCenterComputed;
    }

    public set daumMapCenter(coordinate: Coordinate) {
        this.daumMapsCenterComputed = coordinate;
    }

    private onDaumMapLoad(map: object) {
        // 다음 지도 초기화
        this.vueDaumMap = map;
        this.vueDaumMap.initMarker(0, 0, "/img/map/ico_marker.png", 52, 77, 26, 71);

        this.waitForDaumMapsThenSetGeocode();
    }


    private waitForDaumMapsThenSetGeocode() {
        if (this.daumMapTimer) {
            // 타이머 취소
            clearInterval(this.daumMapTimer);
        }

        if (!this.vueDaumMap) {
            // 타이머 재 예약
            this.daumMapTimer = setInterval(this.waitForDaumMapsThenSetGeocode, 1000);
        } else {
            if (this.geocodeAddressComputed && this.libraries.includes('services')) {
                // 지오코딩을 통해 위경도 값을 추출합니다.
                this.vueDaumMap.geocodeAddrToCoord(this.geocodeAddressComputed, (params: string[]) => {
                    let status: string = params[0];
                    let latY: string = params[1];
                    let lngX: string = params[2];

                    if (status === "success" && latY && lngX) {
                        // 위경도값 추출에 성공
                        this.goAndSetTo(new Coordinate(latY, lngX), true);
                    } else {
                        this.fallbackToCoords();
                    }
                });
            } else {
                this.fallbackToCoords();
            }
        }
    }

    private fallbackToCoords() {
        let foundCoords = false;

        if (this.center) {
            // 제공받은 중앙 좌표가 있다면 이걸 이용하도록 합니다.
            if (this.center.lat && this.center.lng) {
                this.goAndSetTo(new Coordinate(this.center.lat, this.center.lng), true);
                foundCoords = true;
            }
        }

        if (this.latY && this.lngX) {
            // 제공받은 위경도 값이 있다면 이걸 이용하도록 합니다.
            this.goAndSetTo(new Coordinate(this.latY, this.lngX), true);
            foundCoords = true;
        }

        if (!foundCoords) {
            // 좌표를 결국 설정하지 못했습니다 못 보는 거네요.
            this.mapHasCoordinate = false;
        }
    }

    /**
     *
     * @param goAndSetTo 좌표
     * @param moveCenter 뷰포트 중심 이동 여부
     */
    private goAndSetTo(goAndSetTo: Coordinate, moveCenter: boolean) {
        // 좌표로 이동하고, 마커를 박습니다. .sync 때문에 moveCenter와 무관하게 뷰포트가 이동되므로 해제
        // this.daumMapsCenterComputed = goAndSetTo;
        this.mapHasCoordinate = true;
        this.vueDaumMap.moveMarker(goAndSetTo.lat, goAndSetTo.lng);
        if (moveCenter) {
            this.vueDaumMap.moveCenter(goAndSetTo.lat, goAndSetTo.lng);
        }
    }


    private handleOnClickDaumMaps(eventObj: any) {
        // 다음 지도 스크립트가 minified 되어있으므로 obj.lat obj.lng 로 참조할 수 없다.
        let firstObject: any = eventObj[0];
        let firstObjectLatLngKeys: string[] = Object.keys(firstObject.latLng);
        let lngX: string = firstObject.latLng[firstObjectLatLngKeys[0]];
        let latY: string = firstObject.latLng[firstObjectLatLngKeys[1]];

        // 지도에 클릭 입력을 받아 좌표에 표시시킬지 여부가 활성이면 전달.
        if (this.mapIsInteractive) {
            // 마커및 중심좌표 설정
            this.goAndSetTo(new Coordinate(latY, lngX), false);

            // 역지오코딩으로 주소 가져옴
            this.vueDaumMap.geocodeCoordToAddr(latY, lngX, (params: string[]) => {
                let status: string = params[0];
                let roadAddress: string = params[1];
                let address: string = params[2];

                this.$emit('reverse_geocode_result', status, roadAddress, address);
            });
        }

        // 이벤트를 상위로 전달
        this.$emit('click', eventObj, latY, lngX);
    }
}

class Coordinate {
    private latY: string; // 위도
    private lngX: string; // 경도

    constructor(latY: string, lngX: string) {
        this.latY = latY;
        this.lngX = lngX;
    }

    public get lat(): string {
        return this.latY;
    }
    public set lat(latY: string) {
        this.latY = latY;
    }
    public get lng(): string {
        return this.lngX;
    }
    public set lng(lngX: string) {
        this.lngX = lngX;
    }
}

