


























































import {Component, Watch, Mixins} from 'vue-property-decorator';
import CenterWrapper from '@/components/CenterWrapper.vue';

import {APIClient} from "@/api/client/client";
import StoreHelper from "@/mixins/StoreHelper";
import {MessageHandler} from "@/api/client/error";
import {DeactivationForm} from "@/api/in/login";
import {EventBus} from "@/lib/event-bus";

import {
    CustomException,
    FatalException,
} from "@/api/client/notification";
import DeactivatePopup from "@/components/mypage/DeactivatePopup.vue";
import {Metadata} from "@/metadata";

@Component({
    components: {
        CenterWrapper,
        DeactivatePopup,
    },
})
export default class DeactivateAccount extends Mixins<StoreHelper>(StoreHelper) {
    // public readonly recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_KEY!;


    // 회원탈퇴 동의 여부
    private deactForm: DeactivationForm = {
        responseToken: null,
        hasConsent: false,
    };

    private deactivateCodeSent: boolean = false;

    // 회원 고유번호
    private memberIdx: number = -1;

    private get memberSelf() {
        return this.$state.profile;
    }

    private get allCheckPassed(): boolean {
        return this.deactForm.hasConsent;
    }

    private mounted() {
        this.setMetadata();
        this.loadMemberSelf();
    }

    //
    // private onRecaptchaVerified(response: string) {
    //     this.deactForm.responseToken = response;
    // }
    //
    // private onRecaptchaExpired() {
    //     this.deactForm.responseToken = null;
    // }


    private loadMemberSelf() {
        this.$store.dispatch("fetchProfile");
    }

    @Watch('isLoggedIn', { immediate: true })
    private async checkLoggedIn() {
        if ( ! this.isLoggedIn ) {
            EventBus.$emit('toast-notification',
                new FatalException("로그인 되지 않은 상태에서 회원 탈퇴를 시도하셨습니다."));

            // 메인페이지로 이동
            this.$router.push("/");
        }
    }

    private async requestDeactivation() {
        if ( ! this.allCheckPassed) {
            EventBus.$emit('toast-notification',
                new CustomException("회원 탈퇴에 필요한 절차를 완료하지 않으셨습니다."));

            return false;
        }

        // if ( ! this.deactForm.responseToken) {
        //     EventBus.$emit('toast-notification',
        //         new CustomException("보안을 위해 로봇 방지 체크를 진행해 주십시오."));
        //
        //     return false;
        // }

        try {
            let response = await APIClient.instance.mypage.deleteSelf(this.deactForm);

            if (response.success != null) {
                if (response.success) {
                    // 탈퇴 신청 성공

                    // 회원 고유번호도 잊지말고
                    this.memberIdx = parseInt(response.message, 10);

                    // 인증코드를 입력할 차례입니다.
                    this.deactivateCodeSent = true;
                } else {
                    // 탈퇴 신청 실패
                    EventBus.$emit('toast-notification', new CustomException(
                        MessageHandler.combineMessage("회원 탈퇴를 신청하지 못했습니다.", response)
                    ));
                    // (this.$refs.recaptcha! as VueRecaptcha).reset();
                }
            } else {
                EventBus.$emit('toast-notification', new CustomException(
                    MessageHandler.combineMessage("회원 탈퇴를 신청하지 못했습니다.", response)
                ));
                // (this.$refs.recaptcha! as VueRecaptcha).reset();
            }
        } catch (error) {
            // 회원탈퇴 신청도중 오류 발생
            EventBus.$emit('toast-notification', new FatalException(
                MessageHandler.combineMessage("회원탈퇴 신청중 오류가 발생했습니다.", error)
            ));
            // (this.$refs.recaptcha! as VueRecaptcha).reset();
        }
    }

    private setMetadata() {
        let meta: Metadata = {
            title: '회원 탈퇴',
            // description: '회원 탈퇴를 할 수 있는 페이지 입니다.'
        };
        EventBus.$emit("meta-title", meta);
    }
}
