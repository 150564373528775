import { APIBase, GET } from "./apibase";
import ResourceList from '../resourceList';
import Study from "@/api/study";


export default class StudyAPI extends APIBase {
    /** 스터디 목록을 취득합니다 */
    public studyList(page: number): Promise<ResourceList<Study>> {
        const endpoint = '/_api/study/list';
        return this.request(GET, endpoint, {
            page
        }) as Promise<ResourceList<Study>>;
    }

    /** 스터디 목록을 취득합니다 */
    public studyPages() {
        const endpoint = '/_api/study/list/pages';
        return this.request(GET, endpoint) as unknown as Promise<number>;
    }

    /** 스터디 본문을 취득합니다 */
    public get(id: string | number): Promise<Study> {
        const endpoint = `/_api/study/${id}`;
        return this.request(GET, endpoint) as Promise<Study>;
    }

    /** 스터디 다음글을 취득합니다 */
    public next(id: string | number): Promise<Study> {
        const endpoint = `/_api/study/${id}/next`;
        return this.request(GET, endpoint) as Promise<Study>;
    }

    /** 스터디 이전글을 취득합니다 */
    public prev(id: string | number): Promise<Study> {
        const endpoint = `/_api/study/${id}/prev`;
        return this.request(GET, endpoint) as Promise<Study>;
    }
}
