
































































































































































































































































































































































import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
import moment from 'moment';
import { mixins } from "vue-class-component";

import CenterWrapper from '@/components/CenterWrapper.vue';
import DaumPostcodeWrapper from '@/components/DaumPostcodeWrapper.vue';

import StoreHelper from "@/mixins/StoreHelper";
import DigitsOnly from '@/mixins/DigitsOnly';

import { APIClient } from "@/api/client/client";
import { Member } from "@/api/member";
import { LoginForm, NiceDiPrepareVal, RegisterForm } from "@/api/in/login";
import {BankEntry, DepositAvailability, DepositBankAccountInfo} from '@/api/mypage';

import { EventBus } from '@/lib/event-bus';

import {
    CustomException,
    SuccessNotification,
    InformationNotification,
    FatalException
} from '@/api/client/notification';
import {MessageHandler} from "@/api/client/error";
import {isCRNlegit, isSSNlegit, isUserSSNlegit} from "@/lib/juminNoUtils";

@Component({
    components: {
        CenterWrapper,
        DaumPostcodeWrapper,
    },
})
export default class PersonalBox extends Mixins<StoreHelper, DigitsOnly>(StoreHelper, DigitsOnly) {

    // public readonly recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_KEY!;

    // 인증 통과 여부 여기서 true가 되어야 정보 수정 창이 열림
    private authenticated: boolean = false;

    private isSavingChanges: boolean = false;

    private loginForm: LoginForm = {
        username: '',
        password: '',
        responseToken: null,
    };
    private memberModifiable: Member | null = null;

    /** 정보수정용 객체 */
    private mypageForm: RegisterForm | null = null;

    private bankCodes: BankEntry[] = [];

    // 주소 찾기
    private mypageAddressFinder: boolean = false;  // 주소찾기 창 표시 여부


    // 비밀번호
    // private currentPwd: string = '';
    private newPwd: string = '';
    private newPwdChk: string = '';

    // 본인인증 재인증 관련
    private personalIdCheckNiceEncData: string = '';
    private niceEncData: string | null = null;


    // 마운트 되었을 때 호출
    mounted() {
        // this.authenticated = true;
        // this.afterAuthenticated();
    }

    get isOverseas(): boolean {
        if ( this.memberModifiable ) {
            return this.memberModifiable.userType === 'overseas';
        }

        return false;
    }

    get isCompany(): boolean {
        if ( this.memberModifiable ) {
            return this.memberModifiable.userType === 'company';
        }

        return false;
    }

    get juminName(): string {
        if (this.memberModifiable) {
            if (this.isCompany) {
                return "법인등록번호";
            } else {
                return "주민등록번호";
            }
        }
        return "";
    }

    get isUserSSNlegit() {
        if (this.memberModifiable) {
            return isUserSSNlegit(this.memberModifiable);
        }
        return false;
    }

    get isInputSSNlegit() {
        if (this.mypageForm && this.memberModifiable) {
            if (this.memberModifiable.userType === 'company') {
                return isCRNlegit(`${this.mypageForm.jumin1}${this.mypageForm.jumin2}`);
            } else {
                return isSSNlegit(`${this.mypageForm.jumin1}${this.mypageForm.jumin2}`);
            }
        }
        return false;
    }

    /** NICE 인증 결과를 새 창으로부터 수신받는 이벤트 핸들러 */
    onWindowMessage(event: MessageEvent) {
        this.onWindowMessageIE(event.data.toString());
    }

    onWindowMessageIE(message: string) {
        const [type, data] = message.split(":");
        if (type === "NICE") {
            this.niceEncData = data;
        }
    }


    @Watch('authenticated', { immediate: true })
    private afterAuthenticated() {
        if (this.authenticated) {
            this.loadBankCodes();
            this.loadMySelf();

            this.prepareNiceEncData();
            window.addEventListener('message', this.onWindowMessage);
            (window as any).__onWindowMessageIE = this.onWindowMessageIE; // IE 대응
        } else {
            this.removeNiceEventListener();
        }
    }

    private async loadBankCodes() {
        try {
            this.bankCodes = await APIClient.instance.loan.getBankCodeList();
        } catch (error) {
            EventBus.$emit('toast-notification', new FatalException(
                MessageHandler.combineMessage('은행 목록 불러오기에 실패하였습니다. 다시 시도해주세요.', error)
            ));
        }
    }

    private async loadMySelf() {
        try {
            this.initFields();
        } catch (error) {
            EventBus.$emit('toast-notification', new FatalException(
                MessageHandler.combineMessage('회원 정보 불러오기에 실패하였습니다. 다시 시도해주세요.', error)
            ));
        }
    }

    private getInitializedForm(): RegisterForm | null {
        // 마지막으로 로드한 회원정보에 기반해 폼 작성
        if (this.memberModifiable) {
            let self = this.memberModifiable;
            return {
                responseToken: null,
                type: self.userType ? self.userType : '',
                email: self.email,
                password: '',
                name: self.name ? self.name : '',
                jumin1: self.jumin1 ? self.jumin1 : '',
                jumin2: self.jumin2 ? self.jumin2 : '',
                birthday: self.birthday ? self.birthday : null,
                gender: self.gender,
                telephone: self.telephone ? self.telephone : '',
                allowMarketing: self.allowMarketing,
                niceDI: '',

                companyRegId: self.companyRegId ? self.companyRegId : null,
                companyRepres: self.companyRepres ? self.companyRepres : null,
                companyType: self.companyType ? self.companyType : null,

                zipCode: self.zipCode ? self.zipCode : null,
                address1: self.address1 ? self.address1 : null,
                address2: self.address2 ? self.address2 : null,

                depositAccountBankCode: self.depositAccountBankCode ? self.depositAccountBankCode : null,
                depositAccountNumber: self.depositAccountNumber ? self.depositAccountNumber : null,
            };
        } else {
            return null;
        }
    }


    private initFields() {
        // 초기화된 값을 form에 대입합니다.
        this.mypageForm = this.getInitializedForm();

        // 주소찾기 창 표시 여부
        this.mypageAddressFinder = false;
        // 새 비밀번호
        this.newPwd = '';
        // 새 비밀번호 확인
        this.newPwdChk = '';

    }

    /**
     * 변경사항 저장하기 요청
     */
    private async saveChanges() {
        if (this.mypageForm) {
            // 마이페이지 폼에 있는 비밀번호 초기화
            this.mypageForm.password = '';

            if (this.newPwd) {
                if (this.newPwdChk) {
                    if (this.newPwd === this.newPwdChk) {
                        // 비밀번호가 일치하는 경우 제출하도록 합니다.
                        this.mypageForm.password = this.newPwd;
                    } else {
                        EventBus.$emit('toast-notification', new CustomException(
                            "비밀번호를 변경하려면 입력란과 확인란에 같은 비밀번호를 입력해주세요."));
                        return false;
                    }
                } else {
                    EventBus.$emit('toast-notification', new CustomException(
                        "비밀번호를 변경하려면 새 비밀번호 확인란에 한 번 더 새로운 비밀번호를 입력해주세요."));
                    return false;
                }
            }

            await this.saveChangesOnForm(this.mypageForm, true, true);
        }
    }

    private async saveChangesOnForm(form: RegisterForm, reInitFields: boolean, prepareNiceEncData: boolean) {
        if (this.isSavingChanges) {
            return false;
        }
        this.isSavingChanges = true;

        try {
            this.memberModifiable = await APIClient.instance.mypage.modifySelf(form);

            // Store에 반영
            this.commitSelf(this.memberModifiable);

            // 변경사항 반영 이후 새로고침
            if (reInitFields) {
                this.loadMySelf();
            }

            if (prepareNiceEncData) {
                this.prepareNiceEncData();
            }

            EventBus.$emit('toast-notification', new SuccessNotification(
                "회원정보 변경사항이 잘 저장되었습니다.")
            );

        } catch (error) {
            EventBus.$emit('toast-notification', new FatalException(
                MessageHandler.combineMessage("회원정보 변경사항을 저장하지 못했습니다.", error)
            ));
        } finally {
            this.isSavingChanges = false;
        }
    }

    private requestAuthentication() {
        if (this.loginForm.password) {
            this.handleLogin();
        } else {
            EventBus.$emit('toast-notification', new CustomException("현재 비밀번호를 입력해주세요."));
        }

    }

    /**
     * 로그인 처리합니다
     */
    private async handleLogin() {
        // if ( ! this.loginForm.responseToken ) {
        //     EventBus.$emit('toast-notification', new CustomException("보안을 위해 로봇 방지 체크를 진행해 주십시오."));
        //     return;
        // }

        try {
            this.loginForm.username = (this.$state.profile as Member).email;
            // 비밀번호 일치시 POLICY_MYPAGE 로 member 가져오기
            this.memberModifiable = await APIClient.instance.login.loginAndGetModifiable(this.loginForm);

            this.authenticated = true;
        } catch (e) {
            EventBus.$emit('toast-notification', new CustomException(
                MessageHandler.combineMessage("로그인 유무 혹은 비밀번호를 확인 후 다시 시도해주세요.", e)
            ));
            // (this.$refs.recaptcha! as VueRecaptcha).reset();
            this.authenticated = false;
            return;
        }
    }

    /**
     * 회원 탈퇴 요청
     */
    private async requestDeactivation() {
        this.$router.push("/deactivate");
    }

    private performIdCheck() {
        if (this.personalIdCheckNiceEncData !== '') {
            this.openNicePopup();
        }
    }

    /** 주소찾기 시작 버튼 (토글) */
    private showMypagePostcode() {
        this.mypageAddressFinder = !this.mypageAddressFinder;
    }

    /** 주소 찾기 */
    private handleAddress(data: any) {
        let fullAddress = data.jibunAddress;
        let extraAddress = '';
        if (data.addressType === 'R') {
            // 도로명 주소인경우
            fullAddress = data.roadAddress;

            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }

        if (this.mypageForm) {
            this.mypageForm.zipCode = data.zonecode;
            this.mypageForm.address1 = fullAddress;
            this.mypageForm.address2 = '';
        }

        this.mypageAddressFinder = false;

        (this.$refs.mypageAddressExtra as HTMLInputElement).focus();
    }


    // private onRecaptchaVerified(response: string) {
    //     this.loginForm.responseToken = response;
    // }
    //
    // private onRecaptchaExpired() {
    //     this.loginForm.responseToken = null;
    // }


    private destroyed() {
        this.removeNiceEventListener();
    }

    private removeNiceEventListener() {
        window.removeEventListener('message', this.onWindowMessage);
        delete (window as any).__onWindowMessageIE; // 객체 삭제는 delete 로
    }


    /**
     * 서버로부터 NiceEncData 가져오기
     */
    private async prepareNiceEncData() {
        this.personalIdCheckNiceEncData = '';
        let niceDiPrepareVal: NiceDiPrepareVal = await APIClient.instance.signup.prepareNiceDi();

        this.personalIdCheckNiceEncData = niceDiPrepareVal.sencData;
    }

    private openNicePopup() {
        window.open(
            '',
            'popupChk',
            ('width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, ' +
                'status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no')
        );
        // HTMLFormElement로 nicePopupPersonalForm을 불러들여 popupChk 창으로 제출, 본인인증창을 띄웁니다.
        let nicePopup: HTMLFormElement = this.$refs.nicePopupPersonalForm as HTMLFormElement;
        nicePopup.setAttribute("target", "popupChk");
        nicePopup.setAttribute("action", "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb");
        nicePopup.submit();
    }

    @Watch('niceEncData')
    private async onNiceEncDataChanged() {
        const response = await APIClient.instance.signup.decodeNiceEncodedData(this.niceEncData!);

        if (this.mypageForm) {
            this.mypageForm.name = response.sname;
            this.mypageForm.birthday = response.sbirthDate;
            this.mypageForm.gender = (parseInt(response.sgender, 10) % 2 === 0) ? "F" : "M"; // 0 여 1 남;
            this.mypageForm.birthday = response.sbirthDate;
            this.mypageForm.telephone = response.smobileNo;
            this.mypageForm.niceDI = response.sdupInfo;
        }
    }


    private resetIdCheck() {
        this.prepareNiceEncData();
        this.resetChangedColumns(['name',　'telephone',　'gender',　'birthday',　'telephone',　'niceDI']);
    }

    private get hasChangedIdCheck(): boolean {
        return this.checkColumnChanges(['name',　'telephone',　'gender',　'birthday',　'telephone',　'niceDI']);
    }

    private async saveIdCheck() {
        await this.saveChangedColumnsWithParams(
            ['name',　'telephone',　'gender',　'birthday',　'telephone',　'niceDI'],
            false, true);
    }

    private resetOverseasBasic() {
        this.resetChangedColumns(['name', 'birthday', 'gender', 'telephone']);
    }

    private get hasChangedOverseasBasic(): boolean {
        return this.checkColumnChanges(['name', 'birthday', 'gender', 'telephone']);
    }

    private async saveOverseasBasic() {
        await this.saveChangedColumns(['name', 'birthday', 'gender', 'telephone']);
    }

    private resetCompanyBasic() {
        this.resetChangedColumns(['name', 'telephone']);
    }

    private get hasChangedCompanyBasic(): boolean {
        return this.checkColumnChanges(['name', 'telephone']);
    }

    private async saveCompanyBasic() {
        await this.saveChangedColumns(['name', 'telephone']);
    }

    private resetCompanyAdvanced() {
        this.resetChangedColumns(['companyRegId', 'companyRepres', 'companyType']);
    }

    private get hasChangedCompanyAdvanced(): boolean {
        return this.checkColumnChanges(['companyRegId', 'companyRepres', 'companyType']);
    }

    private async saveCompanyAdvanced() {
        await this.saveChangedColumns(['companyRegId', 'companyRepres', 'companyType']);
    }

    private resetAddressInfo() {
        this.resetChangedColumns(['zipCode', 'address1', 'address2']);
    }

    private get hasChangedAddressInfo(): boolean {
        return this.checkColumnChanges(['zipCode', 'address1', 'address2']);
    }

    private async saveAddressInfo() {
        await this.saveChangedColumns(['zipCode', 'address1', 'address2']);
    }


    private resetBankAccountInfo() {
        this.resetChangedColumns(['depositAccountBankCode', 'depositAccountNumber']);
    }

    private get hasChangedBankAccountInfo(): boolean {
        return this.checkColumnChanges(['depositAccountBankCode', 'depositAccountNumber']);
    }

    private async saveBankAccountInfo() {
        await this.saveChangedColumns(['depositAccountBankCode', 'depositAccountNumber']);
    }


    private async saveJumin() {
        if (this.isInputSSNlegit) {
            await this.saveChangedColumns(['jumin1', 'jumin2']);
        } else {
            EventBus.$emit('toast-notification', new CustomException(`${this.juminName}을 올바르게 입력해주세요.`));
        }
    }

    private get hasChangedJumin(): boolean {
        return this.checkColumnChanges(['jumin1',　'jumin2']);
    }

    private resetPasswordInput() {
        this.newPwd = '';
        this.newPwdChk = '';
        this.resetChangedColumns(['password']);
    }

    private get hasPasswordInput(): boolean {
        return this.newPwd.length >= 1 && this.newPwdChk.length >= 1 && this.newPwd === this.newPwdChk;
    }

    private async savePassword() {
        if (this.mypageForm) {
            // 마이페이지 폼에 있는 비밀번호 초기화
            this.mypageForm.password = '';

            if (this.newPwd) {
                if (this.newPwdChk) {
                    if (this.newPwd === this.newPwdChk) {
                        // 비밀번호가 일치하는 경우 제출하도록 합니다.
                        this.mypageForm.password = this.newPwd;
                    } else {
                        EventBus.$emit('toast-notification', new CustomException(
                            "비밀번호를 변경하려면 입력란과 확인란에 같은 비밀번호를 입력해주세요."));
                        return false;
                    }
                } else {
                    EventBus.$emit('toast-notification', new CustomException(
                        "비밀번호를 변경하려면 새 비밀번호 확인란에 한 번 더 새로운 비밀번호를 입력해주세요."));
                    return false;
                }
            }

            await this.saveChangedColumns(['password']);
        }
    }

    private async resetChangedColumns(columns: string[]) {
        const form = this.getInitializedForm() as any;

        if (form) {
            const modifiedForm = this.mypageForm as any;

            if (modifiedForm) {
                // 폼 변경사항 초기화
                for (const column of columns) {
                    modifiedForm[`${column}`] = form[`${column}`];
                }
            }
        }
    }

    private checkColumnChanges(columns: string[]): boolean {
        const form = this.getInitializedForm() as any;

        if (form) {
            const modifiedForm = this.mypageForm as any;

            if (modifiedForm) {
                // 폼 변경사항 확인
                for (const column of columns) {
                    if (form[`${column}`] !== modifiedForm[`${column}`]) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    private async saveChangedColumns(columns: string[]) {
        await this.saveChangedColumnsWithParams(columns, false, false);
    }

    private async saveChangedColumnsWithParams(columns: string[], reinitFields: boolean, prepareNiceEncData: boolean) {
        const form = this.getInitializedForm() as any;

        if (form) {
            const modifiedForm = this.mypageForm as any;

            if (modifiedForm) {
                // 폼 변경사항 제출하게 반영
                for (const column of columns) {
                    form[`${column}`] = modifiedForm[`${column}`];
                }

                // 변경사항 반영
                await this.saveChangesOnForm(form as RegisterForm, reinitFields, prepareNiceEncData);
            }
        }
    }
}
