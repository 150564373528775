


















































































































































































































































import {Component, Mixins, Vue, Watch} from 'vue-property-decorator';

import CenterWrapper from '@/components/CenterWrapper.vue';

import { EventBus } from '@/lib/event-bus';

import {APIClient} from "@/api/client/client";
import {AuthException, CustomException} from "@/api/client/notification";
import Notice from "@/api/notice";
import NoticeTile from "@/components/NoticeTile.vue";
import {Metadata} from "@/metadata";

import {mixins} from "vue-class-component";
import StoreHelper from "@/mixins/StoreHelper";
import {MessageHandler} from "@/api/client/error";
import {Product} from "@/api/product";
import ProductTile from "@/components/ProductTile.vue";
import PortfolioTile from "@/components/PortfolioTile.vue";

@Component({
    components: {
        NoticeTile,
        CenterWrapper,
        ProductTile,
        PortfolioTile,
    },
})
export default class Main extends Mixins<StoreHelper>(StoreHelper) {

    /** 공지사항 배열 */
    noticeListArray: Notice[] = [];
    readonly SHOW_NOTICES_PER_PANE: number = 3;
    currentNoticeOrder: number = 0; // 공지사항 현재 몇 번째가 보이고 있는가
    noticeOrderTimer: number | null = null; // 공지사항 전환 타이머


    /** 상품 목록 */
    listLoading: boolean = false;
    listCurrentPage = 0;
    listFininalized: boolean = false;
    listMonthlyCurrentPage = 0;
    listMonthlyFininalized: boolean = false;

    listMonthlyForPreviousProducts = false; // 이전 분산투자true/일반false 상품 보기 전환 플래그

    products: Product[] = []; // 일반 상품
    monthlyProduct: Product[] = []; // 월간 분산투자 상품


    mounted() {
        this.setMetadata();
    }

    destroyed() {
        this.expireNoticeTimer();
        window.removeEventListener('scroll', this.onWindowScroll);
    }

    @Watch('isLoggedIn', {immediate: true})
    checkIsLoggedIn() {
        if ( ! this.isLoggedIn) {
            this.showLoginPopup();
            return;
        } else {
            this.loadNoticesViaApi();
            this.loadProducts();

            window.addEventListener('scroll', this.onWindowScroll);
        }
    }

    goBack() {
        this.$router.back();
    }


    @Watch('$route.query', { immediate: true })
    checkIfRedirected(query: {login_required?: string}) {
        if (query.login_required === "true") {
            EventBus.$emit('toast-notification', new AuthException());
            this.$router.replace('/');
        }
    }


    /** 사용가능한 공지사항 배열 */
    get availableNoticeList(): Notice[] {
        return this.noticeListArray.filter((notice: Notice) => !notice.deleted);
    }


    // 다음 공지사항으로 전환
    switchToNextNotice() {
        if (this.availableNoticeList.length > 0) {
            this.currentNoticeOrder = this.currentNoticeOrder + this.SHOW_NOTICES_PER_PANE;

            // if (this.currentNoticeOrder >= this.availableNoticeList.length - (this.availableNoticeList.length % this.SHOW_NOTICES_PER_PANE)) { // 순환 기능이 필요하다면
            if (this.currentNoticeOrder >= this.SHOW_NOTICES_PER_PANE) { // 최신 3개만
                this.currentNoticeOrder = 0;
            }
        }
    }

    // 공지사항 전환 타이머를 끕니다.
    expireNoticeTimer() {
        if (this.noticeOrderTimer) {
            window.clearInterval(this.noticeOrderTimer);
            this.noticeOrderTimer = null;
        }
    }


    // NoticeList 에서 가져옴
    async loadNoticesViaApi() {
        this.expireNoticeTimer();

        try {
            let response = await APIClient.instance.notice.noticeList(0);
            this.noticeListArray = response.content;

            // 시간주기를 갖고 다음 공지사항으로 전환시키게 타이머를 설정합니다.
            this.currentNoticeOrder = 0;
            this.noticeOrderTimer = window.setInterval(this.switchToNextNotice, 3000);
        } catch (error) {
            EventBus.$emit('toast-notification', new CustomException(
                MessageHandler.combineMessage("공지사항 목록을 가져올 수 없습니다.", error)
            ));
        }
    }

    // ProductList 에서 가져옴

    get productsInFunding(): Product[] {
        return this.products.filter( p => {
            return p.state === 'funding' || p.state === 'preparing';
        } );
    }

    get productsIsNotFunding(): Product[] {
        return this.products.filter( p => {
            return ! (p.state === 'funding' || p.state === 'preparing');
        } );
    }

    get monthlyProductIsFunding(): Product[] {
        return this.monthlyProduct.filter( p => {
            return p.state === 'funding'
                && !p.isMonthlyReserve // 적립식 상품 제외
            ;
        } ).reverse(); // 1년차 2년차 생성 순서가 있으므로 역방향 대신 정방향으로 보이게 함.
    }

    get monthlyReserveIsFunding(): Product[] {
        return this.monthlyProduct.filter( p => {
            return p.state === 'funding'
                && !!p.isMonthlyReserve // 적립식 상품만 표시
            ;
        } ).reverse(); // 1년차 2년차 생성 순서가 있으므로 역방향 대신 정방향으로 보이게 함.
    }

    get monthlyProductsIsNotFunding(): Product[] {
        return this.monthlyProduct.filter( p => {
            return p.state === 'funded' || p.state === 'repaying' || p.state === 'completed';
        } );
    }

    onWindowScroll(event: Event) {
        const element = this.$el as HTMLElement;
        const scrollY = window.scrollY + screen.height;
        const offsetY = element.offsetTop + element.clientHeight;

        const scrollPercentage = scrollY / offsetY;

        if (scrollPercentage > 0.75) {
            this.loadProducts();
        }
    }

    /**
     * 상품 목록을 불러옵니다.
     */
    async loadProducts() {
        // 상품 목록 로딩중일 때 아무것도 하지 않음.
        if (this.listLoading) {
            return false;
        }

        this.listLoading = true;
        try {
            if ( ! this.listFininalized) {
                // 일반투자 상품만 가져옴

                let response = await APIClient.instance.product.getList(this.listCurrentPage);

                // 어차피 역순 정렬이라서 이렇게 넣더라도 괜찮을 듯.
                if (response.content) {
                    let context = this;
                    response.content.map(product => {
                        context.products.push(product);
                    });
                }

                if (response.numberOfElements === 0) {
                    this.listFininalized = true;
                }
                this.listCurrentPage = this.listCurrentPage + 1;
            }

            if ( ! this.listMonthlyFininalized) {
                // 월간 분산투자 상품 목록을 가져옴

                let response = await APIClient.instance.product.getMonthlyProductList(this.listMonthlyCurrentPage);

                // 어차피 역순 정렬이라서 이렇게 넣더라도 괜찮을 듯.
                if (response.content) {
                    let context = this;
                    response.content.map(product => {
                        this.monthlyProduct.push(product);
                    });
                }

                if (response.numberOfElements === 0) {
                    this.listMonthlyFininalized = true;
                }
                this.listMonthlyCurrentPage = this.listMonthlyCurrentPage + 1;
            }

        } catch (e) {
            EventBus.$emit('toast-notification', new CustomException(
                MessageHandler.combineMessage("리소스를 가져올 수 없습니다.", e)
            ));
        }

        this.listLoading = false;
    }

    private setMetadata() {
        let meta: Metadata = {
            title: '메인',
            // description: '빌드온파트너스대부에 오신 것을 환영합니다.',
        };
        EventBus.$emit("meta-title", meta);
    }
}
