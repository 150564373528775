import { APIBase, GET } from "./apibase";
import ResourceList from '../resourceList';
import Notice from "@/api/notice";


export default class StaticFilesAPI extends APIBase {

    /** PDF Viewer Html을 취득합니다 */
    public pdfViewer(): Promise<string> {
        const endpoint = `/pdfvw/pdfviewer.html`;
        return this.request(GET, endpoint) as unknown as Promise<string>;
    }
}
