import { APIBase, POST, GET } from "./apibase";
import { LoanForm } from '../loan';
import {BankEntry} from "@/api/mypage";
import {SimpleResponse} from "@/api/resourceList";

export default class LoanAPI extends APIBase {

    public requestLoan(data: LoanForm) {
        const endpoint = '/_api/loan/request';
        return this.request(POST, endpoint, data) as Promise<SimpleResponse>;
    }

    public investToProduct(productId: number, amount: number, depositDateOfMonth: number) {
        const endpoint = '/_api/loan/invest';

        const data = {productId, amount, depositDateOfMonth};

        return this.request(POST, endpoint, data) as Promise<InvestResult>;
    }

    public changeInvest(transactionId: number, amount: number, depositDateOfMonth: number) {
        const endpoint = '/_api/loan/modify_invest';

        const data = {transactionId, amount, depositDateOfMonth};

        return this.request(POST, endpoint, data) as Promise<InvestResult>;
    }

    public cancelInvest(transactionId: number) {
        const endpoint = `/_api/loan/invest/cancel/${transactionId}`;

        return this.request(GET, endpoint) as Promise<SimpleResponse>;
    }

    public getBankCodeList() {
        const endpoint = '/_api/loan/bank/list';

        return this.request(GET, endpoint) as Promise<BankEntry[]>;
    }
}

class InvestResult {
    public success?: boolean;
    public message?: string;
}
