



























import { Component, Vue } from 'vue-property-decorator';

import CenterWrapper from '@/components/CenterWrapper.vue';
import Spacer from '@/components/Spacer.vue';
import {APIClient} from "@/api/client/client";
import Notice from "@/api/notice";
import Study from "@/api/study";
import StudyTile from "@/components/StudyTile.vue";
import NoticeTile from "@/components/NoticeTile.vue";
import {Metadata} from "@/metadata";
import {EventBus} from "@/lib/event-bus";

@Component({
    components: {
        CenterWrapper,
    },
})
export default class NotFound extends Vue {

    mounted() {
        this.setMetadata();
    }

    goBack() {
        this.$router.back();
    }

    private setMetadata() {
        let meta: Metadata = {
            title: '페이지 찾을 수 없음',
            // description: '요청하신 페이지는 찾을 수 없습니다.'
        };
        EventBus.$emit("meta-title", meta);
    }
}
