







































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {APIClient} from "@/api/client/client";
import {InvestmentTransaction, LoanRecord} from "@/api/transaction";
import PdfViewer from "@/components/PdfViewer.vue";
import {EventBus} from "@/lib/event-bus";
import {CustomException, SuccessNotification} from "@/api/client/notification";
import {MessageHandler} from "@/api/client/error";
import {SimpleResponse} from "@/api/resourceList";
import {Product} from "@/api/product";
import moment from "moment";
import {getPaymentStatusForSpecifiedTransaction} from "@/lib/common-mypage-methods";

@Component({
    components: {
        PdfViewer
    },
})
export default class InvestmentList extends Vue {

    @Prop( {default: true} )
    private isResourceReady!: boolean;
    @Prop( { required: true } )
    private investTransactions!: InvestmentTransaction[];
    @Prop( { required: false } )
    private loanRecords?: LoanRecord[];

    private currentContractTxn: number = -1;
    private contractPdfSrc: string = '';

    /**
     * 날인 처리된 계약서를 표시합니다.
     * @param txnIndex 투자 트랜잭션의 번호
     */
    private showContractSigned(txnIndex: number) {
        this.currentContractTxn = txnIndex;
        this.contractPdfSrc = `/_api/self/contract/${txnIndex}`;

    }

    /**
     * 날인 처리된 계약서를 다운로드합니다.
     * @param txnIndex 투자 트랜잭션의 번호
     */
    private downloadContractSigned(txnIndex: number) {
        APIClient.instance.mypage.downloadContract(txnIndex);
    }

    private doNothing() {
        /// really do nothing;
    }

    private async cancelInvest(txn: InvestmentTransaction) {
        let productTitle: string = '알 수 없음';
        let investAmount: number = -1;

        if (txn.product && txn.product.title) {
            productTitle = txn.product.title;
        }
        if (txn.amount) {
            investAmount = txn.amount;
        }

        if ( confirm('아래 상품에 대한 투자를 취소하려면 확인을 누르고,\n'
            + '돌아가려면 취소를 눌러주세요.\n'
            + '\n'
            + `투자상품: ${productTitle}\n`
            + `투자금액: ${investAmount.toLocaleString('ko-kr')}원`
        ) ) {
            let result: SimpleResponse | null = null;
            let error: any = null;
            try {
                result = await APIClient.instance.loan.cancelInvest(txn.index);
            } catch (exception) {
                error = exception;
            }

            if (result && result.success) {
                EventBus.$emit('toast-notification',
                    new SuccessNotification('투자 취소에 성공했습니다.'));
                this.$emit('cancel-txn');
            } else {
                EventBus.$emit('toast-notification', new CustomException(
                    MessageHandler.combineMessage('투자를 취소하지 못했습니다.', error)
                ));
            }
        }
    }

    private getPaymentStatusForCurrentTransaction(txn: InvestmentTransaction): string {
        if (!txn.isConfirmed) {
            return "미입금";
        } else if (!!txn.product && (txn.isExecutedSeparately || txn.product.executed)) {
            return getPaymentStatusForSpecifiedTransaction(txn, this.loanRecords);
        } else {
            return "입금완료";
        }
    }
}
