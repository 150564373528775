import { Component, Vue } from 'vue-property-decorator';
import { GlobalState } from '@/store';
import { mapState } from 'vuex';
import { Member } from '@/api/member';

@Component
export default class StoreHelper extends Vue {

    get $state(): GlobalState {
        return this.$store.state as GlobalState;
    }

    get isLoggedIn(): boolean {
        return this.$state.profile != null;
    }

    showDrawer() {
        this.$store.commit("setDrawerVisible", true);
    }

    showLoginPopup() {
        this.$store.commit("setLoginPopupVisible", true);
    }

    closeDrawer() {
        this.$store.commit("setDrawerVisible", false);
    }

    closeLoginPopup() {
        this.$store.commit("setLoginPopupVisible", false);
    }

    commitSelf(self: Member) {
        this.$store.commit("commitSelf", self);
    }
}
