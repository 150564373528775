




























import { Component, Vue, Prop } from 'vue-property-decorator';
import CenterWrapper from '@/components/CenterWrapper.vue';
import Notice from '@/api/notice';
@Component
export default class NoticeTile extends Vue {
    @Prop({ required: true})
    private entry!: Notice;

    @Prop({default: false})
    private isForMain!: boolean;

    @Prop({default: false})
    private isImportant!: boolean;
}
