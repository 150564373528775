







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Fullscreen extends Vue {
    @Prop({default: false})
    private loginWindow!: boolean;

    /**
     * 바깥을 클릭했을 때 닫기 메소드를 실행합니다.
     */
    private closeWindowFromOutside() {
        if ( this.loginWindow ) {
            // 로그인 창에서는 작동하지 않게 함
            return false;
        }

        this.$emit('close-request');
    }
}
