import { APIBase, POST, GET } from "./apibase";
import { Attachment } from '../attachment';

export default class StatsAPI extends APIBase {

    public fetchMainPageStat() {
        const endpoint = `/_api/stats/mainPageStat`;
        return this.request(GET, endpoint) as unknown as Promise<MainPageStat>;
    }
}


export interface MainPageStat {

    /* 누적 투자액 */
    accumulateInvestmentAmount: number;
    /* 누적 상환액 */
    accumulateRepaymentAmount: number;
    /* 누적 지급 이자 */
    accumulatePaidInterestAmount: number;
    /* 누적 지급 원금 */
    accumulatePaidLoanAmount: number;

    /* 투자 건수 */
    numberOfInvestTransactions: number;
    /* 상환 건수 */
    numberOfRepayments: number;
    /* 이자 지급 건수 */
    numberOfPaidInterests: number;
    /* 원금 지급 건수 */
    numberOfPaidLoans: number;
}
