<template>
    <div></div>
</template>

<script>
    //https://github.com/okchangwon/vue-daum-map/blob/master/src/VueDaumMap.vue
    import loadScriptOnce from 'load-script-once';
    const MapTypeId = {
        "ROADMAP": 1,
        "NORMAL": 1,
        "SKYVIEW": 2,
        "HYBRID": 3,
        "OVERLAY": 4,
        "ROADVIEW": 5,
        "TRAFFIC": 6,
        "TERRAIN": 7,
        "BICYCLE": 8,
        "BICYCLE_HYBRID": 9,
        "USE_DISTRICT": 10
    };
    const EVENTS = [
        'center_changed',
        'zoom_start',
        'zoom_changed',
        'bounds_changed',
        'click',
        'dblclick',
        'rightclick',
        'mousemove',
        'dragstart',
        'drag',
        'dragend',
        'idle',
        'tilesloaded',
        'maptypeid_changed'
    ];
    export default {
        name: "VueDaumMap",
        props: {
            appKey: {
                type: String,
                required: true
            },
            libraries: {
                type: Array,
                default: () => []
            },
            center: {
                type: Object,
                required: true
            },
            level: {
                type: Number,
                default: undefined
            },
            mapTypeId: {
                type: Number,
                default: undefined
            },
            draggable: {
                type: Boolean,
                default: undefined
            },
            scrollwheel: {
                type: Boolean,
                default: undefined
            },
            disableDoubleClick: {
                type: Boolean,
                default: undefined
            },
            disableDoubleClickZoom: {
                type: Boolean,
                default: undefined
            },
            projectionId: {
                type: String,
                default: undefined
            },
            tileAnimation: {
                type: Boolean,
                default: undefined
            },
            keyboardShortcuts: {
                type: [Boolean, Object],
                default: undefined
            }
        },
        data: () => ({
            map: null,
            mapMarker: null,
            geocoder: null
        }),
        mounted () {
            loadScriptOnce(`//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=${this.appKey}&libraries=${this.libraries.join(',')}`, (err) => {
                if (err) {
                    console.error(err);
                    return;
                }
                daum.maps.load(() => {
                    this.render();
                    this.bindEvents();
                    this.initGeocoder();
                    this.$emit('load', this);
                });
            });
        },
        watch: {
            level () {
                if (!this.map) {
                    return;
                }
                this.map.setLevel(this.level);
            },
            center : {
                handler () {
                    if (!this.map) {
                        return;
                    }
                    this.map.setCenter(new daum.maps.LatLng(this.center.lat, this.center.lng));
                },
                deep: true
            }
        },
        methods: {
            render () {
                const options = { //지도를 생성할 때 필요한 기본 옵션
                    center: new daum.maps.LatLng(this.center.lat, this.center.lng), //지도의 중심좌표.
                    level: this.level, //지도의 레벨(확대, 축소 정도)
                    mapTypeId: this.mapTypeId, //지도 타입
                    draggable: this.draggable,
                    scrollwheel: this.scrollwheel,
                    disableDoubleClick: this.disableDoubleClick,
                    disableDoubleClickZoom: this.disableDoubleClickZoom,
                    projectionId: this.projectionId,
                    tileAnimation: this.tileAnimation,
                    keyboardShortcuts: this.keyboardShortcuts
                };
                this.map = new daum.maps.Map(this.$el, options); //지도 생성 및 객체 리턴
            },
            bindEvents () {
                const handlers = {
                    bounds_changed: this.onChange,
                    idle: this.onChange
                };
                for (let event of EVENTS) {
                    this.bindEvent(event, handlers[event]);
                }
            },
            bindEvent (event, handler) {
                daum.maps.event.addListener(this.map, event, (...args) => {
                    this.$emit(event, args);
                    if (typeof handler === 'function') {
                        handler();
                    }
                });
            },
            onChange () {
                const level = this.map.getLevel();
                const latlng = this.map.getCenter();
                this.$emit('update:level', level);
                this.$emit('update:center', {
                    lat: latlng.getLat(),
                    lng: latlng.getLng()
                });
            },
            moveCenter(latY, lngX){
                this.map.setCenter(new daum.maps.LatLng(latY, lngX));
            },
            initMarker(latY, lngX, markerUrl, sizeX, sizeY, offsetX, offsetY) {
                if(this.mapMarker){
                    // 이미 있는 경우 지도에서 제거합니다.
                    this.mapMarker.setMap(null);
                    this.mapMarker = null;
                }

                // 마커 시작 /////////////////////////////////////////////////////////////////////////////
                const imageSrc = markerUrl; // 마커이미지의 주소입니다

                const imageSize = new daum.maps.Size(sizeX, sizeY); // 마커이미지의 크기입니다, 가로x세로
                const imageOption = {offset: new daum.maps.Point(offsetX, offsetY)}; // 마커이미지의 옵션입니다. offset은 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

                const markerCenter = new daum.maps.LatLng(latY, lngX); //지도의 중심좌표.

                // 지정된 위치에 표출할 마커입니다
                this.mapMarker = new daum.maps.Marker({
                    // 마커 이미지
                    image: new daum.maps.MarkerImage(imageSrc, imageSize, imageOption),
                    // 지도 중심좌표에 마커를 생성합니다
                    position: markerCenter
                });
                // 지도에 마커를 표시합니다
                this.mapMarker.setMap(this.map);
                // 마커 끝 ///////////////////////////////////////////////////////////////////////////////
            },
            moveMarker (latY, lngX) {
                if(this.mapMarker) {
                    //마커가 있는 경우에만 작동합니다.
                    this.mapMarker.setPosition(new daum.maps.LatLng(latY, lngX));
                }
            },
            initGeocoder(){
                let canContinue = true;
                if( ! daum.maps.services || ! daum.maps.services.Geocoder){
                    // services를 불러오지 못한 경우입니다. 진행불가
                    canContinue = false;
                }
                if(this.geocoder){
                    //이미 있으면 할 필요가 없습니다. 진행불가
                    canContinue = false;
                }

                if(canContinue){
                    this.geocoder = new daum.maps.services.Geocoder();
                }
            },
            geocodeAddrToCoord(address, callback){
                if(this.geocoder){
                    // geocoder가 있는 경우에만 작동합니다.
                    this.geocoder.addressSearch(address, function(result, status){
                        // 정상적으로 검색이 완료됐으면
                        if (status === daum.maps.services.Status.OK) {
                            // OK면 콜백을 불러옵니다.
                            //callback.call(latY, lngX);
                            callback.call(null, ["success", result[0].y, result[0].x]);
                        }else{
                            callback.call(null, ["fail", -1, -1]);
                        }
                    });
                }
            },
            geocodeCoordToAddr(latY, lngX, callback){
                if(this.geocoder) {
                    //geocoder가 있는 경우에만 작동합니다.
                    this.geocoder.coord2Address(lngX, latY, function(result, status){
                        if (status === daum.maps.services.Status.OK) {
                            callback.call(null, ["success", (result[0].road_address ? result[0].road_address.address_name : ''), (result[0].address ? result[0].address.address_name : '')]);
                        }else{
                            callback.call(null, ["fail", '', '']);
                        }
                    });
                }else{
                    console.log("geocoder가 없습니다.");
                }
            }
        },
        MapTypeId: MapTypeId
    }
</script>

<style scoped>
</style>
