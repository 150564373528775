import { APIBase, POST, GET } from "./apibase";
import { Attachment } from '../attachment';

export default class AttachmentAPI extends APIBase {
    public uploadAttachment(attachment: File, type: string) {
        const endpoint = '/_api/attachment/upload';

        const data = new FormData();

        data.append("body", JSON.stringify({
            "type": "loan_attachment",
            "fileName": attachment.name
        }));
        data.append("file", attachment);

        const options = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        return this.request(POST, endpoint, data, options) as Promise<Attachment>;
    }

    /** 파일의 접근가능 여부를 체크합니다 */
    public check(attRawFilename: string) {
        const endpoint = `/_api/attachment/check/${attRawFilename}`;
        return this.request(GET, endpoint) as unknown as Promise<string>;
    }

    /**
     * 로그인된 회원의 권한으로 {rawFilename} 파일을 삭제 처리 합니다.
     * 매개변수 ::
     *     rawFilename 파일 uuid
     *
     * 반환값 :: AttachmentDeletionReport
     */
    public deleteOne(attRawFilename: string): Promise<AttachmentDeletionReport> {
        const endpoint = `/_api/attachment/deleteOne/${attRawFilename}`;
        return this.request(GET, endpoint) as Promise<AttachmentDeletionReport>;
    }
}

export interface AttachmentDeletionReport {
    /**
     *  status: success-전체삭제, notAllDel-일부삭제, noneDel-삭제된거없음
     *  found: 찾은 첨부파일 수
     *  deleted: 삭제처리한 첨부파일 수
     *  nullAuthor: 업로더 정보가 없는 orphaned 첨부 파일 수
     */
    status: string;
    found: number;
    deleted: number;
    nullAuthor: number;
}
