import { APIBase, GET, POST } from "./apibase";
import {NiceDiPrepareVal, RegisterForm} from "@/api/in/login";
import { NiceVerificationResponse } from '../register';
import {SimpleResponse} from "@/api/resourceList";

export default class RegisterAPI extends APIBase {

    public signup(signupForm: RegisterForm) {
        const endpoint = "/_api/signup";
        return this.request(POST, endpoint, signupForm) as Promise<SimpleResponse>;
    }

    public prepareNiceDi() {
        const endpoint = "/_api/signup/nice/prepare";
        return this.request(POST, endpoint) as Promise<NiceDiPrepareVal>;
    }

    public decodeNiceEncodedData(encodedData: string) {
        const endpoint = "/_api/signup/nice/decode";
        return this.request(POST, endpoint, {encodedData}) as Promise<NiceVerificationResponse>;
    }

}


