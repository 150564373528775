










































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {APIClient} from "@/api/client/client";
import {InvestmentTransaction, LoanRecord} from "@/api/transaction";
import PdfViewer from "@/components/PdfViewer.vue";
import {EventBus} from "@/lib/event-bus";
import {CustomException, SuccessNotification} from "@/api/client/notification";
import {MessageHandler} from "@/api/client/error";
import {SimpleResponse} from "@/api/resourceList";
import {MonthlyReserveTxnCalc} from "@/api/mypage";
import {Product} from "@/api/product";
import moment from "moment";
import {
    getPaymentStatusForSpecifiedTransaction
} from "@/lib/common-mypage-methods";


interface CombinedTxnAndStatByProducts {
    product: Product;
    paymentStatus: string;
    currentlyDepositAmount: number;
    contractedAmount: number;
    firstInvestedAt: number;
    expiresAt: number;
    productStatus: string;
}

@Component({
    components: {
        PdfViewer
    },
})
export default class InvestmentListForReserve extends Vue {

    @Prop( {default: true} )
    private isResourceReady!: boolean;
    @Prop( { required: true } )
    private investTransactions!: InvestmentTransaction[];
    @Prop( { required: false } )
    private loanRecords?: LoanRecord[];

    @Prop( { required: true } )
    private allTxnResultsRaw!: Record<number, MonthlyReserveTxnCalc> | null;

    get allTxnResults(): MonthlyReserveTxnCalc | null {
        return this.getTxnResultsByProductIdx(-1);
    }

    get investList(): CombinedTxnAndStatByProducts[] {
        const investList: CombinedTxnAndStatByProducts[] = [];
        const productIdxList: number[] = [];

        const root = (this.$root as any);

        for (const txn of this.investTransactions) {
            if (!!txn.product) {
                let needsPush: boolean = false;
                let combined: CombinedTxnAndStatByProducts | null;
                let calcPerProduct: MonthlyReserveTxnCalc | null = this.getTxnResultsByProductIdx(txn.product.index);
                const elementIdxForProductIdx: number = productIdxList.indexOf(txn.product.index);

                if (elementIdxForProductIdx >= 0) {
                    combined = investList[elementIdxForProductIdx];
                } else {
                    combined = {
                        product: txn.product,
                        paymentStatus: root.$tre('알 수 없음', 'productState', txn.product.state).toString(),
                        currentlyDepositAmount: 0,
                        contractedAmount: 0,
                        firstInvestedAt: txn.executionDate || 0,
                        expiresAt: txn.product.expiresAt || 0,
                        productStatus: getPaymentStatusForSpecifiedTransaction(txn, this.loanRecords),
                    };
                    needsPush = true;
                }

                if (!!calcPerProduct) {
                    // 서버에서 내려준 통계 그대로 넣기
                    combined.currentlyDepositAmount = calcPerProduct.depositAmount;
                }

                if (!!txn.parentAmount && txn.parentAmount !== 0) {
                    // 계약 투자금
                    // 어차피 건별 하나씩만 오므로 괜찮음.
                    combined.contractedAmount = combined.contractedAmount + txn.parentAmount;
                }

                if (!!txn.executionDate && txn.executionDate !== 0) {
                    // 최초 대출실행일
                    let firstInvestAtReplaceable = false;
                    if (combined.firstInvestedAt === 0) {
                        firstInvestAtReplaceable = true;
                    } else if (combined.firstInvestedAt > txn.executionDate) {
                        firstInvestAtReplaceable = true;
                    }

                    if (firstInvestAtReplaceable) {
                        combined.firstInvestedAt = txn.executionDate;
                    }
                }

                if (!!txn.product.expiresAt && txn.product.expiresAt !== 0) {
                    // 만기 예정일
                    let expiresAtReplaceable = false;
                    if (combined.expiresAt === 0) {
                        expiresAtReplaceable = true;
                    }

                    if (expiresAtReplaceable) {
                        combined.expiresAt = txn.product.expiresAt;
                    }
                }

                if (needsPush) {
                    investList.push(combined);
                    productIdxList.push(txn.product.index);
                }
            }
        }

        return investList;
    }

    getTxnResultsByProductIdx(productIdx: number): MonthlyReserveTxnCalc | null {
        if (!!this.allTxnResultsRaw) {
            return this.allTxnResultsRaw[productIdx] || null;
        }
        return null;
    }

}
