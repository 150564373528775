import axios, {AxiosRequestConfig, AxiosError, AxiosStatic} from 'axios';

import { APIError } from './error';

export const POST = 'post';
export const GET = 'get';
export const PATCH = 'patch';
export const DELETE = 'delete';
export const PUT = 'put';

export class APIBase {
    /**
     * HTTP Request를 행합니다
     * @param method  HTTP 메소드
     * @param endpoint API 엔드포인트 주소
     * @param data 데이터 (optional)
     * @param options  axios 옵션 (optional)
     */
    protected async request(
        method: "post" | "get" | "patch" | "delete" | "put" | "GET" | "DELETE" | "head" | "HEAD" | "options"
            | "OPTIONS" | "POST" | "PUT" | "PATCH" | "purge" | "PURGE" | "link" | "LINK" | "unlink" | "UNLINK"
            | undefined,
        endpoint: string,
        data?: object,
        options?: Partial<AxiosRequestConfig>
    ): Promise<object> {
        let args: AxiosRequestConfig = {
            method,
            url: endpoint,
            withCredentials: true, // enable cookie in xhr request,
            headers: {},
            ...options,
        };

        if (method === GET || method === DELETE) {
            // HTTP GET / DELETE에는 body가 들어갈 수 없게 되어있으므로 query parameter로 보냄
            args.params = data;
        } else {
            args.data = data;
        }


        try {
            const response = await axios(args);
            return response.data;
        } catch (error) {
            throw APIError.buildFromAxiosError(error as AxiosError);
        }
    }
}
