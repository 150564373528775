
























































import {Component, Mixins, Prop} from 'vue-property-decorator';

import { Product } from '@/api/product';
import {EventBus} from "@/lib/event-bus";
import {CustomException} from "@/api/client/notification";
import {mixins} from "vue-class-component";
import StoreHelper from "@/mixins/StoreHelper";
import {Member} from "@/api/member";

@Component
export default class PortfolioTile extends Mixins<StoreHelper>(StoreHelper) {
    @Prop({ required: true })
    private product!: Product;

    @Prop({ default: false })
    private clickable!: boolean;

    private get nenchaNum(): string {
        return Number(Number.parseInt(this.product.repaymentMethodMonth!, 10) / 12).toFixed(0);
    }

    private get isOrange() {
        return !this.isBluePrimary && this.nenchaNum === '1';
    }

    private get isRed() {
        return !this.isBluePrimary && this.nenchaNum === '2';
    }

    private get isBluePrimary() {
        return this.isMonthlyReserve;
    }

    private get isMonthlyReserve(): boolean {
        if (this.product) {
            return !!this.product.isMonthlyReserve;
        }
        return false;
    }

    // 현재 투자 가능한 상품인지 보여줍니다.
    private get checkInvestible(): boolean {
        if ( this.product ) {
            return this.product.state === "funding";
        }
        return false;
    }

    // 현재 투자가 가능하다면 투자하기를 보여줍니다.
    private get investBtnTxt(): string {
        if (this.checkInvestible) {
            return '투자하기';
        }

        if (this.product) {
            let root: any = this.$root;
            return root.$tre('알 수 없음', 'productState', this.product.state).toString();
        }

        return '투자할 수 없음';
    }


}
