














































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import CenterWrapper from '@/components/CenterWrapper.vue';
import NoticeTile from '@/components/NoticeTile.vue';

import { EventBus } from '@/lib/event-bus';

import Notice from '@/api/notice';
import {APIClient} from "@/api/client/client";
import { CustomException } from '../api/client/notification';
import {MessageHandler} from "@/api/client/error";
import {Metadata} from "@/metadata";

@Component({
    components: {
        CenterWrapper,
        NoticeTile,
    }
})
export default class NoticeList extends Vue {

    /** 페이지 번호 */
    private pageNum: number = 0;

    /** 최대 페이지 번호 */
    private maxPageNum: number = 0;

    /** 목록에 표시될 페이지 수 */
    private readonly PAGES_PER_LIST = 10;

    /** 공지사항 배열 */
    private noticeListArray: Notice[] = [];
    private noticeListImportantArray: Notice[] = [];

    private isResourceReady: boolean = false;

    /** 사용가능한 공지사항 배열 */
    public get availableNoticeList(): Notice[] {
        return this.noticeListArray.filter((notice: Notice) => !notice.deleted);
    }

    /** 사용가능한 주요 공지사항 배열 */
    public get importantNoticeList(): Notice[] {
        return this.noticeListImportantArray.filter((notice: Notice) => !notice.deleted);
    }

    /**
     * 마운트 되었을 때 호출됩니다.
     */
    private mounted() {
        this.setMetadata();

        this.loadNotices();
        this.loadImportantNotices();
    }

    /** 이전 페이지 */
    private turnPrevPage() {
        // 최소 페이지 직전 페이지까지만 이전 페이지 호출 가능
        if (this.pageNum > 0) {
            this.turnPage(this.pageNum - 1);
        } else {
            EventBus.$emit('toast-notification', new CustomException("이전 페이지가 없습니다."));
        }
    }

    /** 특정 번호의 페이지 */
    private turnPage(pageNum: number) {
        this.pageNum = pageNum;
    }

    /** 다음 페이지 */
    private turnNextPage() {
        // 최대 페이지 직전 페이지까지만 다음 페이지 호출 가능
        if (this.pageNum < (this.maxPageNum - 1) ) {
            this.turnPage(this.pageNum + 1);
        } else {
            EventBus.$emit('toast-notification', new CustomException("다음 페이지가 없습니다."));
        }
    }

    private get listPagesDesc(): number[] {
        let listPagesDesc: number[] = [];

        let currentPageOnList: number = Math.floor(this.pageNum / this.PAGES_PER_LIST);
        let nextPageOnList: number = (currentPageOnList + 1) * this.PAGES_PER_LIST;
        for (let page = currentPageOnList * this.PAGES_PER_LIST; page < nextPageOnList; page ++) {
            // 현재 페이지 번호가 속한 0 1 2 3 4 5 6 7 8 9 를 구한 다음 최대 페이지 까지만 페이지 번호를 추가
            if (page < this.maxPageNum) {
                // 0부터 시작하니까 <= 대신 < 로
                listPagesDesc.push(page);
            } else {
                // 빠져나갑니다.
                break;
            }
        }

        return listPagesDesc;
    }

    private async loadImportantNotices() {
        try {
            let response = await APIClient.instance.notice.noticeListImportant();
            this.noticeListImportantArray = response.content;
        } catch (error) {
            EventBus.$emit('toast-notification', new CustomException(
                MessageHandler.combineMessage("주요 공지사항 목록을 가져올 수 없습니다.", error)
            ));
        }
    }

    // pageNum 변경시 호출
    @Watch('pageNum')
    private onPageNumChanged() {
        this.loadNotices();
    }

    private async loadNotices() {
        await this.loadNoticesViaApi();
        await this.loadNoticePages();

        // 첫 로딩에 아무것도 없을 때에 대비하여 플래그를 준비함
        if ( ! this.isResourceReady) {
            // 이미 로딩이 됐었던 적이 있다면 플래그가 다시 세워지지 않습니다.
            this.isResourceReady = true;
        }
    }

    private async loadNoticesViaApi() {
        try {
            let response = await APIClient.instance.notice.noticeList(this.pageNum);
            this.noticeListArray = response.content;
        } catch (error) {
            EventBus.$emit('toast-notification', new CustomException(
                MessageHandler.combineMessage("공지사항 목록을 가져올 수 없습니다.", error)
            ));
        }
    }

    private async loadNoticePages() {
        try {
            let response = await APIClient.instance.notice.noticePages();
            this.maxPageNum = response.valueOf() as number;
        } catch (error) {
            EventBus.$emit('toast-notification', new CustomException(
                MessageHandler.combineMessage("공지사항 갯수를 가져올 수 없습니다.", error)
            ));
        }
    }

    private setMetadata() {
        let meta: Metadata = {
            title: '공지사항',
            // description: '빌드온파트너스대부의 공지사항을 둘러보는 페이지입니다.'
        };
        EventBus.$emit("meta-title", meta);
    }
}
