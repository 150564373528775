

















































import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';

import StoreHelper from '@/mixins/StoreHelper';

import CenterWrapper from './CenterWrapper.vue';


@Component({
    components: {
        CenterWrapper,
    },
})
export default class Navbar extends Mixins<StoreHelper>(StoreHelper) {
    public readonly MENU_ENTRIES: MenuEntry[] = [
        // { label: '메인', link: '/' },
        { label: '투자하기', link: '/' },
        // { label: '대출 신청하기', link: '/loan/application' },
        { label: '공지사항', link: '/board/notice' },
        // { label: '투자 FAQ', link: '/board/faq' }
    ];

    isSubmenuShown: boolean = false;
}
