





























































































































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CenterWrapper from '@/components/CenterWrapper.vue';
import moment from 'moment';
import {APIClient} from "@/api/client/client";
import {Product} from "@/api/product";
import {Member} from "@/api/member";

import { EventBus } from "@/lib/event-bus";

import { CustomException } from "@/api/client/notification";
import {MessageHandler} from "@/api/client/error";
import {Transaction} from "@sentry/integrations";
import {InvestmentTransaction, LoanInterestRecord, LoanRecord} from "@/api/transaction";
import PdfViewer from "@/components/PdfViewer.vue";
import InvestmentList from "@/components/mypage/InvestmentList.vue";
import {getPaymentStatusForSpecifiedProduct} from "@/lib/common-mypage-methods";

@Component({
    components: {
        InvestmentList,
        CenterWrapper,
        PdfViewer,
    },
})
export default class InterestsByProductBox extends Vue {


    private isResourceReady: boolean = false;
    private investedProducts: Product[] = [];
    private currentProduct: Product | null = null;
    private recordList: Array<(LoanRecord | LoanInterestRecord)> = [];
    private loanRecordsPerProduct: LoanRecord[] = [];
    private investTransactions: InvestmentTransaction[] = [];
    private currentContractTxn: number = -1;
    private contractPdfSrc: string = '';

    private currentLoanRecordIndex: number = 0;

    private totalInterestRangeMonth: Date = new Date();
    private readonly MILLISECONDS_ONE_DAY = 24 * 60 * 60 * 1000;


    // private totalInterestRangeMonth: Date = new Date();
    // private totalInterestTxnList: any = [];

    mounted() {
        // let today = moment(new Date());
        // let expiryDate = moment('2019-10-22 12:01:59', 'YYYY-MM-DD HH:mm:ss').endOf('day'); // 만기 당일을 연체라고 보지 않습니다.
        // let diffs = today.diff(expiryDate, 'seconds') / 86400; // days 지정시 소숫점이 나오지 않아 직접 계산하는 방법을 택했습니다.
        //
        // console.log('DayDifference: ' + diffs);

        this.__initialize();
    }

    async fetchInvestedProducts() {
        this.investedProducts = await APIClient.instance.mypage.getInvestedProducts();
    }
    async __initialize() {
        this.isResourceReady = false;
        await Promise.all([
            this.fetchInvestedProducts(),
        ]);
        this.selectRecent();
        this.isResourceReady = true;
    }

    @Watch('currentProduct', { immediate: true })
    async fetchInterestRecordList() {
        if ( this.currentProduct ) {
            this.investTransactions = [];
            try {
                this.investTransactions =
                    await APIClient.instance.mypage.getTransactionsOnProductConsolidate(this.currentProduct.index);
            } catch (error) {
                EventBus.$emit('toast-notification', new CustomException(
                    MessageHandler.combineMessage(this.currentProduct.index
                        + '번 상품의 투자내역을 불러오지 못했습니다.', error)
                ));
            }

            this.recordList = [];
            try {
                this.recordList =
                    await APIClient.instance.mypage.getRecordsOnProduct(this.currentProduct.index, -1, -1, false);
            } catch (error) {
                EventBus.$emit('toast-notification', new CustomException(
                    MessageHandler.combineMessage(this.currentProduct.index
                        + '번 상품의 이자 지급내역을 불러오지 못했습니다.', error)
                ));
            }

            this.loanRecordsPerProduct = [];
            this.currentLoanRecordIndex = 0;
            try {
                this.loanRecordsPerProduct =
                    await APIClient.instance.mypage.getAllLoanRecordsOnProduct(this.currentProduct.index);
            } catch (error) {
                EventBus.$emit('toast-notification', new CustomException(
                    MessageHandler.combineMessage(this.currentProduct.index
                        + '번 상품의 원금 내역을 불러오지 못했습니다.', error)
                ));
            }
        }
    }

    getProof() {
        APIClient.instance.mypage.downloadCertificate(this.loanRecordsPerProduct[this.currentLoanRecordIndex].index);
    }

    get isProofDownloadable(): boolean {
        if (this.loanRecordsPerProduct.length > 0) {
            return true;
        }
        return false;
    }

    // LoanRecord - 미지급 포함을 가져오는 endpoint를 사용하므로 추출의 필요성이 없음.
    // get loanRecordsPerProduct(): any[] {
    //     let loanRecordsIndices: number[] = [];
    //     let loanRecords: any[] = [];
    //     if (this.recordList && this.recordList.length > 0) {
    //         // loanInterestRecord가 있으면 반복합니다.
    //         for (let loanInterestRecord of this.recordList) {
    //             let loanRecord = loanInterestRecord.loanRecord;
    //             // loanInterestRecord 의 loanRecord 를 확인 후
    //             if (loanRecord) {
    //                 // 있다면 그 index를 중복이 되지 않게 추가해둡니다.
    //                 if ( ! loanRecordsIndices.includes(loanRecord.index)) {
    //                     // laonRecords에 없다면 추가
    //                     loanRecordsIndices.push(loanRecord.index);
    //                     loanRecords.push(loanRecord);
    //                 }
    //             }
    //         }
    //     }
    //     return loanRecords;
    // }

    calcDayDiff(end: number, start: number): number {
        return Math.floor((end - start) / 1000 / 86400);
    }

    // https://stackoverflow.com/a/43367234
    // Vue의 모델과 연결된 값을 선택해주면 됩니다.
    selectRecent() {
        let currentProduct: Product | null | undefined = this.investedProducts.find(
            (product: Product) =>
                product.index === this.investedProducts[this.investedProducts.length - 1].index );

        if (currentProduct) {
            this.currentProduct = currentProduct;
        }
    }

    //
    // async fetchTotalInterestTxnList() {
    //     const rangeMonth = this.totalInterestRangeMonth;
    //     this.isResourceReady = false;
    //
    //     try {
    //         this.totalInterestTxnList
    //             = await APIClient.instance.mypage.getTotalTableData(
    //                 rangeMonth.getFullYear(), rangeMonth.getMonth() + 1);
    //
    //         this.isResourceReady = true;
    //     } catch (e) {
    //         console.error(e);
    //         this.isResourceReady = true;
    //         throw e;
    //     }
    // }

    // prevMonth() {
    //     this.totalInterestRangeMonth = new Date(this.totalInterestRangeMonth.setMonth(
    //         this.totalInterestRangeMonth.getMonth() - 1
    //     ));
    // }
    // nextMonth() {
    //     let now = new Date();
    //     let thisMonth: number = now.getFullYear() * 100 + now.getMonth();
    //     if (thisMonth <=
    //         (this.totalInterestRangeMonth.getFullYear() * 100 + this.totalInterestRangeMonth.getMonth()) ) {
    //         // 오늘의 년월 보다 현재 선택된 년월이 같거나 크면 다음 달로 넘어갈 수 없습니다.
    //         return false;
    //     }
    //
    //     this.totalInterestRangeMonth = new Date(this.totalInterestRangeMonth.setMonth(
    //         this.totalInterestRangeMonth.getMonth() + 1
    //     ));
    // }

    /** getTotalTableData 기반. 사용되지 않습니다. */
    // @Watch('totalInterestRangeMonth', { immediate: true })
    // onTotalInterestRangeMonthChanged() {
    //     this.fetchTotalInterestTxnList();
    // }
    //
    // get loanRecordsThisMonth() {
    //     return this.totalInterestTxnList.filter( (txn: any) => txn.type === 'loan_record' );
    // }
    //
    // get interestRecordsThisMonth() {
    //     return this.totalInterestTxnList.filter( (txn: any) => txn.type === 'interest_record' );
    // }

    /**
     * 이번달의 현재 상품에 대한 이자 기록을 가져옵니다.
     */
    /** getTotalTableData 기반. 사용되지 않습니다. */
    // get interestRecordsThisMonthForCurrentProduct() {
    //     let self = this;
    //     return this.interestRecordsThisMonth.filter( (record: any) => {
    //         let txnProduct: Product = (record.loanRecord.product as Product);
    //         if (txnProduct) {
    //             return txnProduct.index === (self.currentProduct as Product).index;
    //         }
    //         return false;
    //     } );
    // }

    get totalRecordsOnCurrentProduct() {
        return this.recordList;
    }

    get totalInterestRecordsOnCurrentProduct(): LoanInterestRecord[] {
        return this.totalRecordsOnCurrentProduct.filter( (record) => {
            return record.type === "interest_record";
        }).map(record => record as LoanInterestRecord);
    }

    get interestRecordsPaidOnCurrentProduct() {
        return this.totalInterestRecordsOnCurrentProduct.filter( (record) => {
            return record.isPaid;
        } );
    }

    get payoutInterestForCurrentMonthAndProduct() {
        return this.interestRecordsPaidOnCurrentProduct.reduce(
                (sum: number, r: any) => sum + ( r.amount - r.taxAmount ),
                0
            );
    }

    calcInvestedDays(product: Product): number {
        if (!!product && product.executionDate) {
            // 현재 선택된 월을 추출
            let dateThatDay = new Date(this.totalInterestRangeMonth);

            let currentDate = new Date();
            if (dateThatDay < currentDate) {
                // TODO: need to something but don't remember ;;
            }

            if (!!product.expiresAt) {

                if (product.state === "completed") {
                    // 상환 완료
                    // 만기일이 되어있다면 대출 실행일과의 차이를 구함
                    return product.expiresAt - product.executionDate;
                }

                if (product.state === "repaying") {
                    // 상환중
                    let currentDateMillis = currentDate.getTime();
                    // 오늘 날짜로부터 대출 실행일과의 차이를 구함
                    return currentDateMillis - product.executionDate;
                }

            }
        }

        return -1;
    }

    calcInvestedDaysForTxnExecSeparately(txnExecSeparately: InvestmentTransaction): number {
        if (!!txnExecSeparately && txnExecSeparately.executionDate) {
            // 현재 선택된 월을 추출
            let dateThatDay = new Date(this.totalInterestRangeMonth);

            let currentDate = new Date();
            if (dateThatDay < currentDate) {
                // TODO: need to something but don't remember ;;
            }

            if (!!txnExecSeparately.product && txnExecSeparately.product.expiresAt) {

                if (txnExecSeparately.product.state === "completed") {
                    // 상환 완료
                    // 만기일이 되어있다면 대출 실행일과의 차이를 구함
                    return txnExecSeparately.product.expiresAt - txnExecSeparately.executionDate;
                }

                if (
                    txnExecSeparately.product.state === "funding"
                    || txnExecSeparately.product.state === "funded"
                    || txnExecSeparately.product.state === "repaying"
                ) {
                    // 모집중/모집완료/상환중 모두 검사해야함 왜냐면 이 때도 단건대출은 가능하니까.
                    let currentDateMillis = currentDate.getTime();
                    // 오늘 날짜로부터 대출 실행일과의 차이를 구함
                    return currentDateMillis - txnExecSeparately.executionDate;
                }
            }
        }

        return -1;
    }

    // 총 투자일수를 계산해 가져오는 메소드. 단 '일' 단위를 붙임
    getInvestedDaysFromLoanRecord(loanRecord: LoanRecord): string {
        let result = -1;
        if (loanRecord.transactionExecSeparately) {
            result = this.calcInvestedDaysForTxnExecSeparately(loanRecord.transactionExecSeparately);
        } else {
            result = this.calcInvestedDays(loanRecord.product);
        }

        // 계산불가
        if (result < 0) {
            return '-';
        } else {
            return `${(result / this.MILLISECONDS_ONE_DAY).toFixed(0)}일`;
        }
    }

    accumulatePayoutInterestforDisplay(maxIndex: number): number {
        let sum: number = 0;
        for (let cur: number = 0; cur <= maxIndex; cur++) {
            if (
                this.totalRecordsOnCurrentProduct[cur].type === "interest_record"
                && this.totalRecordsOnCurrentProduct[cur].isPaid
            ) {
                sum = sum + (this.totalRecordsOnCurrentProduct[cur].amount
                    - (this.totalRecordsOnCurrentProduct[cur] as LoanInterestRecord).taxAmount);
            }
        }
        return sum;
    }

    // get txnListForCurrentProduct() {
    //     let self = this;
    //     return this.totalInterestTxnList.filter( (txn: any) => {
    //         if (txn.type === 'loan_record') {
    //             // LoanRecord는 InvestmentProduct를 함유합니다.
    //             let txnProduct: Product = (txn.product as Product);
    //             if (txnProduct) {
    //                 return txnProduct.index === (self.currentProduct as Product).index;
    //             }
    //         } else if (txn.type === 'interest_record') {
    //             // LoanInterestRecord 에는 LoanRecord를 함유합니다.
    //             if (txn.loanRecord) {
    //                 let txnProduct: Product = (txn.loanRecord.product as Product);
    //                 if (txnProduct) {
    //                     return txnProduct.index === (self.currentProduct as Product).index;
    //                 }
    //             }
    //         }
    //
    //         //해당하지 않는 경우
    //         return false;
    //     });
    // }

    private getPaymentStatusForCurrentProduct(product: Product) {
        getPaymentStatusForSpecifiedProduct(product);
    }


    /**
     * 날인 처리된 계약서를 표시합니다.
     * @param txnIndex 투자 트랜잭션의 번호
     */
    private showContractSigned(txnIndex: number) {
        this.currentContractTxn = txnIndex;
        this.contractPdfSrc = `/_api/self/contract/${txnIndex}`;

    }

    /**
     * 날인 처리된 계약서를 다운로드합니다.
     * @param txnIndex 투자 트랜잭션의 번호
     */
    private downloadContractSigned(txnIndex: number) {
        APIClient.instance.mypage.downloadContract(txnIndex);
    }
}
