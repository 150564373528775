





























































































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
// import Swiper JS
import Swiper from "swiper/core";
// core version + navigation, pagination modules:
import SwiperCore, { Navigation, Pagination } from 'swiper/core';

import {mixins} from "vue-class-component";
import StoreHelper from "@/mixins/StoreHelper";
import {PortfolioMerchandise} from "@/api/portfolioMerchandise";


// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination]);


@Component
export default class PortfolioTile extends Mixins<StoreHelper>(StoreHelper) {

    /** 기본 이미지로 우회합니다. */
    public readonly URL_IMG_FALLBACK: string = "../assets/product-list/no-image.png";




    @Prop({ required: false })
    private portfolio!: PortfolioMerchandise;

    @Prop({ default: false })
    private clickable!: boolean;

    @Prop({default: 0})
    private tileIndex!: string | number;

    // Swiper에서 사용할 객체
    private swiper: Swiper | null = null; // null 허용

    private get computedTileIndex(): string {

        return `merchandise-tile-${this.tileIndex}`;
    }

    @Watch('portfolio') // product 객체 변화가 감지되면 실행
    private initImageSlides() {
        // JavaScript 라이브러리 구동시 한 tick에 모두 넣으면
        // 일부는 렌더링 전이라 작동하지 않는 문제가 있는데
        // 그것을 다음 tick으로 미룸
        this.$nextTick(() => {
            this.destroySwiper();
            this.swiper = new Swiper(`.merchandise-img-swiper-container.${this.computedTileIndex}`, {
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                },
            });
        });
    }

    /** 상품 이미지 URL */
    private get attachedImageURLs(): string[] {
        if (this.portfolio && this.portfolio.portfolioImages.length > 0) {
            return this.portfolio.portfolioImages.map(a => a.url);
        }
        return [this.URL_IMG_FALLBACK];
    }


    private mounted() {
        this.initImageSlides();
    }

    private beforeDestroy() {
        // 페이지 이동 등의 이유로 Vue 컴포넌트를 벗어나는 경우 호출

       this.destroySwiper();
    }

    private destroySwiper() {
        if (this.swiper) {
            // swiper 객체 파괴
            this.swiper.destroy(true, true);
            this.swiper = null;
        }
    }
}
