import { render, staticRenderFns } from "./InvestmentListForReserve.vue?vue&type=template&id=4a5ce607&scoped=true&"
import script from "./InvestmentListForReserve.vue?vue&type=script&lang=ts&"
export * from "./InvestmentListForReserve.vue?vue&type=script&lang=ts&"
import style0 from "./InvestmentList.scss?vue&type=style&index=0&id=4a5ce607&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a5ce607",
  null
  
)

export default component.exports