
























import { Component, Vue, Prop } from 'vue-property-decorator';
import CenterWrapper from '@/components/CenterWrapper.vue';

import { Faq } from '@/api/faq';

@Component
export default class FaqTile extends Vue {

    @Prop({ required: true})
    private faqEntry!: Faq;

    private isActive: boolean = false;

    private toggleActive() {
        this.isActive = !this.isActive;
    }
}
