import Vue from 'vue';
import Router, {RouteConfig} from 'vue-router';
import Home from './views/Home.vue';
import Main from "@/views/Main.vue";
import store from '@/store';

Vue.use(Router);

function withPrefix(prefix: string, routes: RouteConfig[]) {
    return routes.map(route => {
        route.path = prefix + route.path;
        return route;
    });
}

const requireAuth = () => (from: any, to: any, next: any) => {
    if (store.state.profile) {
        next();
    } else {
        next('/?login_required=true');
    }
};

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'main',
            component: () => import(/* webpackChunkName: "main" */ './views/Main.vue')
        },
        ...withPrefix('/find-password', [
            {
                path: '/',
                name: 'find-password',
                component: () => import(/* webpackChunkName: "find-pw" */ './views/FindPasswordByToken.vue'),
            }
        ]),
        ...withPrefix('/register', [
            {
                path: '/',
                name: 'register',
                component: () => import(/* webpackChunkName: "user" */ './views/Register.vue'),
            }
        ]),
        {
            path: '/mypage',
            redirect: '/mypage/default'
        },
        {
            path: '/mypage/:directedView',
            name: 'mypage',
            component: () => import(/* webpackChunkName: "mypage" */ './views/MyPage.vue'),
            props: true,
            meta: { requiresLoggedIn: true },
        },
        {
            path: '/deactivate',
            name: 'deactivate-account',
            component: () => import(/* webpackChunkName: "mypage-deactiv" */ './views/DeactivateAccount.vue'),
            meta: { requiresLoggedIn: true },
        },
        ...withPrefix('/products', [
            {
                path: '/:productId',
                name: 'product-detail',
                component: () => import(/* webpackChunkName: "product-detail" */ './views/ProductDetail.vue'),
                props: true,
                meta: { requiresLoggedIn: true },
            },
            {
                path: '/:productId/invest',
                name: 'product-invest',
                component: () => import(/* webpackChunkName: "product-invest" */ './views/ProductInvest.vue'),
                props: true,
                meta: { requiresLoggedIn: true },
            },
            // 2020-01-14 투자금액 변경하기 버튼 무력화
            // {
            //     path: '/:productId/invest/change/:transactionId',
            //     name: 'product-invest-change',
            //     component: () => import(/* webpackChunkName: "product-invest-modify" */ './views/ProductInvest.vue'),
            //     props: true,
            //     meta: { requiresLoggedIn: true },
            // }
        ]),
        ...withPrefix('/portfolios', [
            {
                path: '/:productId',
                name: 'portfolio-detail',
                component: () => import(/* webpackChunkName: "product-detail" */ './views/PortfolioDetail.vue'),
                props: true,
                meta: { requiresLoggedIn: true },
            },
            {
                path: '/:productId/invest',
                name: 'product-invest',
                component: () => import(/* webpackChunkName: "product-invest" */ './views/ProductInvest.vue'),
                props: true,
                meta: { requiresLoggedIn: true },
            },
            // 2020-01-14 투자금액 변경하기 버튼 무력화
            // {
            //     path: '/:productId/invest/change/:transactionId',
            //     name: 'product-invest-change',
            //     component: () => import(/* webpackChunkName: "product-invest-modify" */ './views/ProductInvest.vue'),
            //     props: true,
            //     meta: { requiresLoggedIn: true },
            // }
        ]),
        ...withPrefix('/board/notice', [
            {
                path: '/',
                name: 'notice-list',
                component: () => import(/* webpackChunkName: "notice" */ './views/NoticeList.vue'),
                meta: { requiresLoggedIn: true },
            },
            {
                path: '/:noticeId',
                name: 'notice-detail',
                component: () => import(/* webpackChunkName: "notice" */ './views/NoticeDetail.vue'),
                props: true,
                meta: { requiresLoggedIn: true },
            }
        ]),
        {
            path: '/board/faq',
            name: 'faq',
            component: () => import(/* webpackChunkName: "faq" */ './views/InvestmentFAQ.vue'),
            meta: { requiresLoggedIn: true },
        },
        {
            path: '/__showcase',
            name: 'ui-showcase',
            component: () => import(/* webpackChunkName: "showcase" */ './views/UIShowcase.vue'),
        },
        {
            path: '/terms',
            redirect: '/terms/default'
        },
        {
            path: '/terms/:policy',
            name: 'terms',
            component: () => import(/* webpackChunkName: "terms" */ './views/Terms.vue'),
            props: true
        },
        {
            // 나머지 주소에 대한 fallback입니다. 이 항목이 맨 마지막에 위치할 수 있도록 배려해주세요.
            path: '*',
            name: 'not-found',
            component: () => import(/* webpackChunkName: "not-found" */'./views/NotFound.vue'),
        }
    ],
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0};
        }
    }
});

export default router;
