import { APIBase, GET } from "./apibase";
import ResourceList from '../resourceList';
import { Faq } from '../faq';

export default class FaqAPI extends APIBase {
    /** FAQ 목록을 취득합니다 */
    public faqList() {
        const endpoint = '/_api/faq/list';
        return this.request(GET, endpoint) as Promise<ResourceList<Faq>>;
    }

    /** FAQ 본문을 취득합니다 */
    public get(id: string | number) {
        const endpoint = `/_api/faq/${id}`;
        return this.request(GET, endpoint) as Promise<Faq>;
    }
}
