



























































import { Component, Vue } from 'vue-property-decorator';
import CenterWrapper from '@/components/CenterWrapper.vue';

@Component({
    components: {
        CenterWrapper
    }
})
export default class MainFooter extends Vue {



    private termsWindow: Window | null = null;

    /**
     * 약관 창을 엽니다.
     * @param termsName 약관 파일 이름
     */
    openTermsWindow(termsName: string) {
        this.closeTermsWindow();
        this.termsWindow = window.open(
            `/terms/${termsName}`,
            '_blank');
    }

    /**
     * 창을 닫아달라고 요청할 때 이 창을 연 창(window.opener) 에서만
     * 가능하며 리스너로 postMessage()를 써야하는 크롬과
     * 따로 함수 선언이 필요한 IE를 대응합니다.
     * @param event
     */
    openOrCloseWindow(event: MessageEvent) {
        const [type, data] = event.data.toString().split(":");

        switch (type) {
            case 'termsWinClose':
                this.closeTermsWindow();
                break;
            case 'termsWinOpen':
                this.openTermsWindow(data);
                break;
        }
    }

    closeTermsWindow() {
        if (this.termsWindow) {
            this.termsWindow.close();
        }
    }

    // 마운트 되었을 때 호출
    mounted() {
        window.addEventListener('message', this.openOrCloseWindow);
        (window as any).__closeTermsWindow = this.closeTermsWindow; // IE 대응
        (window as any).__openTermsWindow = this.openTermsWindow; // IE 대응
    }

    destroyed() {
        window.removeEventListener('message', this.openOrCloseWindow);
        delete (window as any).__closeTermsWindow; // 객체 삭제는 delete 로
        delete (window as any).__openTermsWindow; // 객체 삭제는 delete 로
    }
}
