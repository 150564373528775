import {InvestmentTransaction, LoanRecord} from "@/api/transaction";
import {Product} from "@/api/product";
import moment from "moment";

export const getPaymentStatusForCurrentTransaction = (txn: InvestmentTransaction, loanRecords?: LoanRecord[]): string => {
    if (!txn.isConfirmed) {
        return "미입금";
    } else if (!!txn.product && (txn.isExecutedSeparately || txn.product.executed)) {
        return getPaymentStatusForSpecifiedTransaction(txn, loanRecords);
    } else {
        return "입금완료";
    }
}

// from ScheduleBox.vue
export const getPaymentStatusForSpecifiedProduct = (product?: Product): string => {
    if (!!product) {
        if (product.state === 'completed') {
            // 상환 완료.
            return '상환 완료';
        } else if (product.expiresAt) {
            // 상환 완료 아닌 상품이라면 만기일이 지났는지 확인합니다.

            let today = moment(new Date());
            let expiryDate = moment(product.expiresAt).endOf('day'); // 만기 당일을 연체라고 보지 않습니다.
            let diffs = today.diff(expiryDate, 'seconds') / 86400; // days 지정시 소숫점이 나오지 않아 직접 계산하는 방법을 택했습니다.

            if (diffs > 0.0 ) {
                // 0.x 되겠죠?

                // 현재 시점은 만기일이 지났습니다.
                if (diffs >= 28) {
                    // NO!!! 4주가 지나면 부실로 처리
                    // 부실 표현 사용 금지
                    return `연체 (+${diffs.toFixed(0)}일)`;
                }

                return `연체 (+${diffs.toFixed(0)}일)`;
            }
            return '정상 진행중';
        }
    }

    return '-';
}

export const getPaymentStatusForSpecifiedTransaction = (transaction?: InvestmentTransaction, loanRecords?: LoanRecord[]): string => {
    if (!!transaction) {
        if (getTransactionIsPaid(transaction, loanRecords)) {
            // 투자 내역이 상환되었는지 확인했고 true면 상환 완료로 표기
            return '상환 완료';
        }
        return getPaymentStatusForSpecifiedProduct(transaction.product);
    }

    return '-';
}

export const getTransactionIsPaid = (transaction?: InvestmentTransaction, loanRecords?: LoanRecord[]): boolean => {
    if (!!transaction) {
        if (transaction.isExecutedSeparately) {
            const matchedLoanRecords = getComputedLoanRecords(loanRecords).filter((record) => {
                // InvestmentTransaction이 단건 대출시 생성된 LoanRecord와 연결되어있는지 확인합니다.
                const txnFromLoanRecord = record.transactionExecSeparately;
                if (!!txnFromLoanRecord) {
                    if (txnFromLoanRecord.index === transaction.index) {
                        return true;
                    }
                    const parentTxnIdx = txnFromLoanRecord.parentTxnIdx;
                    if (parentTxnIdx === transaction.index) {
                        return true;
                    }
                }
                return false;
            });

            if (matchedLoanRecords.length > 0) {
                // 0번 원소를 꺼내서 계산에 활용합니다.
                const matchedLoanRecord = matchedLoanRecords[0];
                if (matchedLoanRecord.isPaid) {
                    return true;
                }
            }
        } else if (!!transaction.product && !transaction.product.isMonthlyProduct) {
            // 월간 상품이 아닌 경우에만 사용.
            const matchedLoanRecords = getComputedLoanRecords(loanRecords).filter((record) => {
                // InvestmentTransaction이 단건 대출시 생성된 LoanRecord와 연결되어있는지 확인합니다.
                if (!record.product || !transaction.product) {
                    // 상품 정보가 없습니다. 비교할 수 없음.
                    return false;
                }
                // 같은 상품에 연결됐는지 확인.
                // API에서 본인 투자내역만 내주니까 그건 비교 안 해도 상관 없음.
                return record.product.index === transaction.product.index;
            });

            if (matchedLoanRecords.length > 0) {
                // 0번 원소를 꺼내서 계산에 활용합니다.
                // 일반/BP 상품은 대출 실행이 한 번만 이루어지고 생성되는 원금 내역도 하나 뿐인데다
                // 부분 상환도 없으므로 괜찮다.
                const matchedLoanRecord = matchedLoanRecords[0];
                if (matchedLoanRecord.isPaid) {
                    return true;
                }
            }
        }
    }
    return false;
}

export const getComputedLoanRecords = (loanRecords?: LoanRecord[]): LoanRecord[] => {
    if (!!loanRecords) {
        return loanRecords;
    } else {
        return [];
    }
}
