import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from '@/i18n';

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

import 'izitoast/dist/css/iziToast.css';

Sentry.init({
    dsn: 'https://a2201e8c08524098a015366340d9ff5a@sentry.io/1780345',
    integrations: [new Integrations.Vue({Vue, attachProps: true})],
});

// import './registerServiceWorker';

import './global.scss';

// import Swiper styles
import 'swiper/swiper-bundle.css';

// Vue.config.productionTip = false;

/* tslint:disable-next-line:no-var-requires */
Vue.use(require('vue-moment'));

router.beforeEach((to, from, next) => {
    // 해당 라우트가 중급 관리자 이상을 요구한다면
    if (to.meta.requiresLoggedIn) {

        // 로그인이 되어있는 경우
        if (store.state.profile) {
            // 넘겨주거나
            next();
        } else {
            // 아니면 돌려보냅니다.
            next('/');
        }
    } else {
        // 로그인을 요구하지 않는다면 그대로 넘어감.
        next();
    }
});

new Vue({
    router,
    store,
    render: (h) => h(App),
    i18n,
}).$mount('#app');
