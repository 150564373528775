

































































import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
import CenterWrapper from '@/components/CenterWrapper.vue';

import DashboardBox from '@/views/mypage/DashboardBox.vue';
import PersonalBox from "@/views/mypage/PersonalBox.vue";
import StoreHelper from "@/mixins/StoreHelper";
import DigitsOnly from "@/mixins/DigitsOnly";
import {mixins} from "vue-class-component";

import { EventBus } from '@/lib/event-bus';

import {Metadata} from "@/metadata";
import InterestsByMonthBox from "@/views/mypage/InterestsByMonthBox.vue";
import InterestsByProductBox from "@/views/mypage/InterestsByProductBox.vue";
import InvestMonthlyReserveBox from "@/views/mypage/InvestMonthlyReserveBox.vue";

@Component({
    components: {
        InvestMonthlyReserveBox,
        CenterWrapper,
        DashboardBox,
        PersonalBox,
        InterestsByMonthBox,
        InterestsByProductBox,
    },
})
export default class MyPage extends Mixins<StoreHelper, DigitsOnly>(StoreHelper, DigitsOnly) {

    @Prop({ required: false })
    private directedView!: string;


    private readonly AVAILABLE_VIEWS: string[] = [
        "dashboard",
        "personal",
        "interestsByMonth",
        "interestsByProduct",
        "investsByMonthlyReserveProduct",
    ];

    private readonly VIEW_DASHBOARD = this.AVAILABLE_VIEWS[0];
    private readonly VIEW_PERSONAL = this.AVAILABLE_VIEWS[1];
    private readonly VIEW_INTERESTS_BY_MONTH = this.AVAILABLE_VIEWS[2];
    private readonly VIEW_INTERESTS_BY_PRODUCT = this.AVAILABLE_VIEWS[3];
    private readonly VIEW_INVESTS_BY_MONTHLY_RESERVE_PRODUCT = this.AVAILABLE_VIEWS[4];

    /** 기본으로 표시될 뷰 */
    private readonly DEFAULT_VIEW: string = this.VIEW_DASHBOARD;


    // 현재 뷰
    private currentView: string = this.DEFAULT_VIEW;


    private get mypageProfileShared() {
        return this.$state.profile;
    }

    private mounted() {
        this.loadMemberSelf();
    }


    /** directedView 프롭 변경시 호출 */
    @Watch('directedView')
    private directView() {
        this.currentView = this.directedView;

        this.followCurrentView();
    }

    /** currentView 멤버변수 변경시 호출 */
    @Watch('currentView')
    private followCurrentView() {
        if ( ! this.AVAILABLE_VIEWS.includes(this.currentView) ) {
            this.currentView = this.DEFAULT_VIEW;
        }

        // 주소창에 해당 view가 보이도록 조정
        this.$router.replace({ path: '/mypage/' + this.currentView, query: { '': null } });

        let metaEachPage: Metadata | null = null;
        switch (this.currentView) {
            case this.VIEW_DASHBOARD:
                metaEachPage = {
                    title: '마이페이지: 투자확인',
                    // description: '대시보드에서 회원님의 자산 현황을 볼 수 있습니다.'
                };
                break;
            case this.VIEW_PERSONAL:
                metaEachPage = {
                    title: '마이페이지: 정보수정',
                    // description: '회원님의 정보를 확인하고 수정할 수 있습니다.'
                };
                break;
            case this.VIEW_INTERESTS_BY_MONTH:
                metaEachPage = {
                    title: '마이페이지: 월별 이자지급 통계',
                    // description: '회원님이 투자하신 상품에 대한 이자 지급 내역을 월별로 표시합니다.'
                };
                break;
            case this.VIEW_INTERESTS_BY_PRODUCT:
                metaEachPage = {
                    title: '마이페이지: 상품별 이자지급 통계',
                    // description: '회원님이 투자하신 상품에 대한 이자 지급 내역을 상품별로 표시합니다.'
                };
                break;
            case this.VIEW_INVESTS_BY_MONTHLY_RESERVE_PRODUCT:
                metaEachPage = {
                    title: '마이페이지: 월간 적립식 상품 납입 확인',
                    // description: '회원님이 투자하신 월간 적립식 상품에 대한 납입 여부를 상품별로 표시합니다.'
                };
                break;
        }

        if (metaEachPage) {
            EventBus.$emit("meta-title", metaEachPage);
        }
    }

    private loadMemberSelf() {
        this.$store.dispatch("fetchProfile");
    }

    @Watch('isLoggedIn', {immediate: true})
    private async checkLoggedIn() {
        if ( ! this.isLoggedIn ) {
            // 로그인 창을 띄우도록 합니다.
            this.showLoginPopup();
        } else {
            this.directView();
        }
    }
}
