import { APIBase, GET, POST } from "./apibase";
import { Product } from '../product';
import { Member, ResolveResult } from '../member';
import {
    FindPasswordConfirmForm,
    FindPasswordForm,
    FindUsernameCodeForm,
    FindUsernameForm,
    LoginForm
} from '../in/login';
import {SimpleResponse} from "@/api/resourceList";

export default class LoginAPI extends APIBase {

    public login(loginForm: LoginForm) {
        const endpoint = "/_api/login";
        // new Object() 가 json으로 반환되므로 object를 써도 됩니다.
        return this.request(POST, endpoint, loginForm) as Promise<object>;
    }

    public loginAndGetModifiable(loginForm: LoginForm) {
        const endpoint = "/_api/login/modifiable";
        return this.request(POST, endpoint, loginForm) as Promise<Member>;
    }

    /** 로그아웃 처리를 행합니다 */
    public logout() {
        location.href = "/_api/logout";
    }

    public has(emailId: string) {
        const endpoint = "/_api/has";
        let loginForm: LoginForm = {
            username: emailId,
            password: '__duplicateCheck',
            responseToken: null,
        };
        return this.request(POST, endpoint, loginForm) as unknown as Promise<boolean>;
    }

    /**
     * NICE 본인인증 DI 값을 제출해 해당 사용자를 찾습니다.
     * @param form
     */
    public findUsername(form: FindUsernameForm) {
        const endpoint = "/_api/find-username";

        return this.request(POST, endpoint, form) as Promise<SimpleResponse>;
    }



    /**
     * 입력한 전화번호를 제출해 해당 사용자를 찾습니다.
     * @param form
     */
    public findUsernameViaPhone(form: FindUsernameForm) {
        const endpoint = "/_api/find-username/via-phone/step01";

        return this.request(POST, endpoint, form) as Promise<SimpleResponse>;
    }

    /**
     * 입력한 전화번호를 제출해 해당 사용자를 찾습니다.
     * @param form
     */
    public findUsernameViaPhoneCode(form: FindUsernameCodeForm) {
        const endpoint = "/_api/find-username/via-phone/step02";

        return this.request(POST, endpoint, form) as Promise<SimpleResponse>;
    }


    /**
     * 비밀번호 재설정 메일을 보내달라고 합니다.
     * @param form
     */
    public requestPasswordReset(form: FindPasswordForm) {
        const endpoint = "/_api/find-password";

        return this.request(POST, endpoint, form) as Promise<SimpleResponse>;
    }


    /**
     * 비밀번호 재설정 메일을 보내달라고 합니다.
     * @param form
     */
    public checkResetTokenValid(token: string) {
        const endpoint = "/_api/find-password/checkToken/" + token;

        return this.request(GET, endpoint) as Promise<SimpleResponse>;
    }

    /**
     * 비밀번호 재설정을 수행합니다.
     * 비밀번호 재설정 메일의 링크의 페이지를 통해 폼을 제출.
     * @param form
     */
    public confirmPasswordReset(form: FindPasswordConfirmForm) {
        const endpoint = "/_api/find-password/confirm";

        return this.request(POST, endpoint, form) as Promise<SimpleResponse>;
    }
}


