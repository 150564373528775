import { APIBase, GET } from "./apibase";
import ResourceList from '../resourceList';
import Notice from "@/api/notice";


export default class NoticeAPI extends APIBase {
    /** 공지사항 목록을 취득합니다 */
    public noticeList(page: number): Promise<ResourceList<Notice>> {
        const endpoint = '/_api/notice/list';
        return this.request(GET, endpoint, {
            page
        }) as Promise<ResourceList<Notice>>;
    }

    /** 주요 공지사항 목록을 취득합니다 */
    public noticeListImportant(): Promise<ResourceList<Notice>> {
        const endpoint = '/_api/notice/list/important';
        return this.request(GET, endpoint) as Promise<ResourceList<Notice>>;
    }

    /** 공지사항 목록을 취득합니다 */
    public noticePages() {
        const endpoint = '/_api/notice/list/pages';
        return this.request(GET, endpoint) as unknown as Promise<number>;
    }

    /** 공지사항 본문을 취득합니다 */
    public get(id: string | number): Promise<Notice> {
        const endpoint = `/_api/notice/${id}`;
        return this.request(GET, endpoint) as Promise<Notice>;
    }

    /** 공지사항 다음글을 취득합니다 */
    public next(id: string | number): Promise<Notice> {
        const endpoint = `/_api/notice/${id}/next`;
        return this.request(GET, endpoint) as Promise<Notice>;
    }

    /** 공지사항 이전글을 취득합니다 */
    public prev(id: string | number): Promise<Notice> {
        const endpoint = `/_api/notice/${id}/prev`;
        return this.request(GET, endpoint) as Promise<Notice>;
    }
}
