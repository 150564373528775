

















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class IeWarn extends Vue {

    private disAppear: boolean = true;

    private get userAgent(): string {
        if ( !window || !window.navigator || !window.navigator.userAgent) {
            return 'cannot_found';
        }
        return window.navigator.userAgent;
    }

    private get isIeDeprecated(): boolean {
        return 0 <= this.getVersionIE && this.getVersionIE < 12;
    }

    private get getVersionIE(): number {
        // https://www.w3docs.com/snippets/javascript/how-to-detect-internet-explorer-in-javascript.html
        // userAgent in IE7 WinXP returns: Mozilla/4.0 (compatible; MSIE 7.0; Windows NT 5.1; .NET CLR 2.0.50727)
        // userAgent in IE11 Win7 returns: Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko

        // test for rv:x.x or rv x.x where Trident string exists
        let detectIEregexp: RegExp = /Trident.*rv[ :]*(\d+\.\d+)/;

        if (this.userAgent.indexOf('MSIE') > -1) {
            detectIEregexp = /MSIE (\d+\.\d+);/; // test for MSIE x.x
        }

        let matchResult = this.userAgent.match(detectIEregexp);

        if (matchResult && matchResult.length > 0) { // if some form of IE
            return Number(matchResult[1]); // capture x.x portion and store as a number
        } else {
            return -1;
        }
    }

    private mounted() {
        this.makeAppear();
    }

    @Watch('isIeDeprecated')
    private async makeAppear() {
        await this.sleep(500);

        this.disAppear = !this.isIeDeprecated;
    }

    private async sleep(milliseconds: number) {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
}
