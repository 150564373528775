






import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({
    components: {
    }
})
export default class PdfViewer extends Vue {

    @Prop({required: true})
    private pdfUrl!: string;

    private pdfViewerBody: HTMLIFrameElement | null = null;

    mounted() {
        this.pdfViewerBody = this.$refs.pdfViewerBody as HTMLIFrameElement;
        this.pdfViewerBody.addEventListener('load', this.loadPdfViewer);
    }

    beforeDestroy() {
        this.pdfViewerBody!.removeEventListener('load', this.loadPdfViewer);
    }

    @Watch('pdfUrl')
    async loadPdfViewer() {
        (this.pdfViewerBody!.contentWindow as any).initializePdfViewer(
            window.location.protocol + '//' + window.location.hostname + '/' + this.pdfUrl
        );
    }
}
