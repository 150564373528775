




















































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CenterWrapper from '@/components/CenterWrapper.vue';
import moment from 'moment';
import {APIClient} from "@/api/client/client";
import MonthlyReserveInvestTable from "@/components/mypage/MonthlyReserveInvestTable.vue";
import { Product } from '@/api/product';
import {EventBus} from "@/lib/event-bus";
import {CustomException} from "@/api/client/notification";
import {MessageHandler} from "@/api/client/error";
import {InvestmentTransaction, LoanRecord} from "@/api/transaction";
import { MonthlyReserveTxnCalc } from '@/api/mypage';
import InvestmentListForReserve from "@/components/mypage/InvestmentListForReserve.vue";

@Component({
    components: {
        InvestmentListForReserve,
        CenterWrapper,
        MonthlyReserveInvestTable,
    },
})
export default class InvestMonthlyReserveBox extends Vue {

    private isResourceReady: boolean = false;

    private currentProduct: Product | null = null;
    private investedProducts: Product[] = [];
    private transactionHistory: InvestmentTransaction[] = [];
    private loanRecordsForThisProduct: LoanRecord[] = [];
    private calcResults: MonthlyReserveTxnCalc[] = [];
    private allTxnResultsRaw: Record<number, MonthlyReserveTxnCalc> | null = null;

    // MonthlyReserveTxnCalc 배열에서 reduce 나 반복문을 쓰는 computed로 하려 했으나
    // 실행 타이밍의 문제인지 계산이 이루어지지 않고 0으로 출력돼서 변수에다 넣는 방법을 사용.
    private depositAmountSumUntilNow: number = 0;
    private afterTaxInterestSum: number = 0;
    private taxForInterestSum: number = 0;
    private dueAmountThisMonthSum: number = 0;
    private depositAmountThisMonthSum: number = 0;

    get transactionHistorySortedByParentIdx(): InvestmentTransaction[][] {
        let sortedByParentIdx: InvestmentTransaction[][] = [];

        let parentIdxList: number[] = [];

        this.transactionHistory.forEach((txn, index, array) => {
            let idxOfList = -1;
            if (txn.parentTxnIdx) {
                // 월간적립식 등 연관된 투자내역이 있는 경우
                idxOfList = parentIdxList.indexOf(txn.parentTxnIdx);
                if (idxOfList < 0) {
                    // 신규 항목입니다.
                    sortedByParentIdx.push([]);
                    parentIdxList.push(txn.parentTxnIdx);
                    idxOfList = parentIdxList.length - 1;
                }

                sortedByParentIdx[idxOfList].push(txn);
            }
        });

        // 정렬
        sortedByParentIdx = sortedByParentIdx.sort();
        for (let cur = 0; cur < sortedByParentIdx.length; cur++ ) {
            sortedByParentIdx[cur] = sortedByParentIdx[cur].sort((a, b) => {
                // index 기준으로 정렬
                return a.index - b.index;
            });
        }

        return sortedByParentIdx;
    }

    get allTxnResults(): MonthlyReserveTxnCalc | null {
        if (!!this.allTxnResultsRaw) {
            return this.allTxnResultsRaw[-1] || null;
        }
        return null;
    }

    get pickedFirstTxnOfTransactionHistorySortedByParentIdx(): InvestmentTransaction[] {
        return this.transactionHistorySortedByParentIdx.map((txnEntry, index) => {
            return txnEntry[0];
        }) as InvestmentTransaction[];
    }

    mounted() {
        this.__initialize();
    }

    async __initialize() {
        this.isResourceReady = false;
        await this.fetchMonthlyReserveList();
        this.fetchMonthlyReserveStats(); // 필요한 값은 다 로딩이 되었으므로 일부러 await 넣지 않음.
        this.isResourceReady = true;
    }

    async fetchMonthlyReserveStats() {
        try {
            this.allTxnResultsRaw = await APIClient.instance.mypage.getMyStatsOnMonthlyReserveProduct();
        } catch (error) {
            EventBus.$emit('toast-notification', new CustomException(
                MessageHandler.combineMessage('월간 적립식 투자내역 통계을 불러오지 못했습니다.', error)
            ));
        }
    }

    async fetchMonthlyReserveList() {
        try {
            this.investedProducts = await APIClient.instance.mypage.getInvestedMonthlyReserveProducts();
        } catch (error) {
            EventBus.$emit('toast-notification', new CustomException(
                MessageHandler.combineMessage('월간 적립식 상품의 목록을 불러오지 못했습니다.', error)
            ));
        }

        // 2020-09-02 디자인 변경으로 인해 자동 선택을 하지 않음
        // this.selectRecent();
    }

    @Watch('currentProduct', { immediate: true })
    async fetchInvestHistory() {
        // 미리 요청시의 productIndex를 기억
        const currentSelectProductIndex: number = !!this.currentProduct ? this.currentProduct.index : -1;
        try {
            this.transactionHistory = [];
            let transactionListAccumulated: InvestmentTransaction[] = [];
            // 한 투자 상품에 여러번 투자했을 수 있기 때문에 가져온 뒤 분류해야 함.
            transactionListAccumulated.push(
                ...await APIClient.instance.mypage.getMyTransactionsOnMonthlyReserveProduct(currentSelectProductIndex));
            let gotLoanRecords = await APIClient.instance.mypage.getAllLoanRecordsOnProduct(currentSelectProductIndex);

            if (currentSelectProductIndex === (!!this.currentProduct ? this.currentProduct.index : -1)) {
                // 요청시 productIndex와 현재 productIndex가 같을 때만 반영합니다.
                // 전체보기 같이 건 많은거 로딩시 오래걸릴 수 있을거 같아서
                // 사용자가 상품을 바꾼 경우 수신한 값을 버리는 로직을 추가했습니다.
                this.transactionHistory = transactionListAccumulated;
                this.loanRecordsForThisProduct = gotLoanRecords;
                this.calcResults.length = 0;
            }
        } catch (error) {
            EventBus.$emit('toast-notification', new CustomException(
                MessageHandler.combineMessage('월간 적립식 상품의 투자이력을 불러오지 못했습니다.', error)
            ));
        }
    }

    // https://stackoverflow.com/a/43367234
    // Vue의 모델과 연결된 값을 선택해주면 됩니다.
    selectRecent() {
        let currentProduct: Product | null | undefined = this.investedProducts.find(
            (product: Product) =>
                product.index === this.investedProducts[this.investedProducts.length - 1].index );

        if (currentProduct) {
            this.currentProduct = currentProduct;
        }
    }

    // MonthlyReserveTxnCalc 배열에서 reduce 나 반복문을 쓰는 computed로 하려 했으나
    // 실행 타이밍의 문제인지 계산이 이루어지지 않고 0으로 출력돼서 변수에다 넣는 방법을 사용.
    handleTxnCalculateFinished(index: number, result: MonthlyReserveTxnCalc) {
        if ( ! result) {
            // 받은 값이 없으면 넘어간다
            return false;
        }

        this.calcResults[index] = result;

        if (this.calcResults.length === this.transactionHistorySortedByParentIdx.length) {
            // 합산 준비
            // let calcResults = [...this.calcResults]; // 타이밍 문제가 발생할 수 있어 spread 사용
            let depositAmountSumUntilNow = 0;
            let afterTaxInterestSum: number = 0;
            let taxForInterestSum: number = 0;
            let dueAmountThisMonthSum: number = 0;
            let depositAmountThisMonthSum: number = 0;


            for (const resultFromResults of this.calcResults) {
                if (!resultFromResults) {
                    continue;
                }
                // 합산 반복문
                depositAmountSumUntilNow = depositAmountSumUntilNow + resultFromResults.depositAmount;
                afterTaxInterestSum = afterTaxInterestSum + resultFromResults.afterTaxInterest;
                taxForInterestSum = taxForInterestSum + resultFromResults.taxForInterest;
                dueAmountThisMonthSum = dueAmountThisMonthSum + resultFromResults.dueAmountThisMonth;
                depositAmountThisMonthSum = depositAmountThisMonthSum + resultFromResults.depositAmountThisMonth;
            }

            // 합산 결과 반영
            this.depositAmountSumUntilNow = depositAmountSumUntilNow;
            this.afterTaxInterestSum = afterTaxInterestSum;
            this.taxForInterestSum = taxForInterestSum;
            this.dueAmountThisMonthSum = dueAmountThisMonthSum;
            this.depositAmountThisMonthSum = depositAmountThisMonthSum;
        }
    }
}
