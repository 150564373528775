import { AxiosError } from "axios";

/** 서버에서 return하는 raw error */
export interface IDefaultError {
    code: string;
    message: string;
    details?: object;
}

export abstract class BaseError extends Error {
    abstract get message(): string;
    abstract get errorType(): string;

    constructor() {
        super();
        Object.setPrototypeOf(this, BaseError.prototype);
    }

    toString(): string {
        return `[${this.errorType}] ${this.message}`;
    }
}

export class APIError extends BaseError {

    static buildFromAxiosError(error: AxiosError): APIError {
        const { response } = error;
        let statusCode = 0;
        if (response) {
            statusCode = response.status;
            if ( response.headers['content-type'].includes('application/json') ) {
                return new APIError(response.data, statusCode);
            }
        }

        // TODO: HTML이나 기타 데이터가 왔다!
        return new APIError({
            code: error.code || 'fatal_server_error',
            message: error.message || '', // FIXME
        }, statusCode);
    }

    constructor(public response: IDefaultError, public statusCode?: number) {
        super();
        Object.setPrototypeOf(this, APIError.prototype);
    }

    get errorType(): string {
        return this.response.code;
    }

    get message(): string {
        return `${this.response.message}`;
    }
}

export class NotImplementedError extends BaseError {

    constructor(public method: string, public endpoint: string) {
        super();
        Object.setPrototypeOf(this, NotImplementedError.prototype);
    }

    get errorType(): string {
        return 'NotImplementedError';
    }

    get message(): string {
        return `${this.method} ${this.endpoint}는 아직 구현되지 않았습니다.`;
    }
}

export class MessageHandler {

    static combineMessage(defaultMsg: string, response: any): string {
        let message = defaultMsg;
        if (response && response.message) {
            // \n 대신 <br /> 태그를 넣어야 줄바꿈이 됩니다.
            message = message + "<br />" + response.message;
        }
        return message;
    }
}
