






































































































































































































































































































































































































































































































import {Component, Mixins, Vue, Watch} from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import moment from 'moment';
import {LoginForm, NiceDiPrepareVal, RegisterForm, RegisterUserGender} from "@/api/in/login";
import DigitsOnly from '../mixins/DigitsOnly';


import CenterWrapper from '@/components/CenterWrapper.vue';
import DaumPostcodeWrapper from '@/components/DaumPostcodeWrapper.vue';

import {APIClient} from "@/api/client/client";

import { EventBus } from '@/lib/event-bus';

import {
    CustomException,
    SuccessNotification,
    InformationNotification, FatalException
} from '@/api/client/notification';
import {MessageHandler} from "@/api/client/error";
import {Metadata} from "@/metadata";
import {BankEntry} from "@/api/mypage";
import {isCRNlegit, isSSNlegit} from "@/lib/juminNoUtils";
import {UserGender, UserType} from "@/api/member";

interface Validatable {
    name?: string;
    errorMessage?: string;
    var: string;
    element: string;
    validateFn?: () => boolean;
}

@Component({
    components: {
        CenterWrapper,
        DaumPostcodeWrapper,
    },
})
export default class Register extends Mixins<DigitsOnly>(DigitsOnly) {

    /** 국내 회원 (회원 형식) */
    public readonly MEMBER_TYPE_DOMESTIC: UserType = "individual";

    /** 법인 회원 (회원 형식) */
    public readonly MEMBER_TYPE_COMPANY: UserType = "company";

    /** 해외 회원 (회원 형식) */
    public readonly MEMBER_TYPE_OVERSEAS: UserType = "overseas";

    /** 남 (성별) */
    public readonly MEMBER_SEX_MALE: UserGender = "M";

    /** 여 (성별) */
    public readonly MEMBER_SEX_FEMALE: UserGender = "F";

    // /** ReCAPTCHA 사이트 토큰 */
    // public readonly recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_KEY!;

    /** 회원 가입 형식 */
    // private memberType: string = this.MEMBER_TYPE_DOMESTIC;

    /************************* 일반 가입 필드 *************************/
    // private personalEmailId: string = '';       // 이메일 아이디
    // private personalEmailDomain: string = '';   // 이메일 도메인
    // private personalEmailChkdDup: string = '';  // 중복확인된 이메일 주소
    // private personalPwd: string = '';           // 비밀번호
    // private personalPwdRe: string = '';         // 비밀번호 재확인
    private personalSsn1: string = '';          // 주민등록번호 앞자리
    private personalSsn2: string = '';          // 주민등록번호 뒷자리

    private personalAddressFinder: boolean = false;  // 주소찾기 창 표시 여부

    private personalIdCheckNiceEncData: string = '';        // 본인인증 준비값
    private personalIdCheckNameVar: string = '';            // 본인인증 이름
    private personalIdCheckBirthVar: string = '';           // 본인인증 생년월일
    private personalIdCheckSexVar: RegisterUserGender = ''; // 본인인증 성별
    private personalIdCheckPhoneVar: string = '';           // 본인인증 전화번호
    private personalIdCheckDi: string = '';                 // 본인인증 확인 값

    private personalIdInputName: string = '';               // 직접입력 이름
    private personalIdInputBirth: string = '';              // 직접입력 생년월일
    private personalIdInputSex: RegisterUserGender = '';    // 직접입력 성별
    private personalIdInputPhone: string = '';              // 직접입력 전화번호

    // private personalPolicyTermAgree: boolean = false;       // 약관동의 서비스이용약관 및 개인정보취급방침
    // private personalPolicyInvestNotiAgree: boolean = false; // 약관동의 투자정보 수신
    // private personalPolicyMarketingAgree: boolean = false;  // 약관동의 마케팅 동의

    /************************* 법인 가입 필드 *************************/
    private companyIdCheckNameVar: string = '';     // 본인인증 법인명
    private companyIdCheckBrandVar: string = '';    // 본인인증 상호명
    private companyIdCheckNumVar: string = '';      // 본인인증 법인등록번호
    private companyIdCheckRegVar: string = '';      // 본인인증 사업자등록번호
    private companyIdCheckRepresVar: string = '';   // 본인인증 대표자 성명
    private companyIdCheckDi: string = '';          // 본인인증 확인 값

    private companyIdInputName: string = '';     // 직접입력 법인명
    private companyIdInputBrand: string = '';    // 직접입력 상호명
    private companyIdInputNum: string = '';      // 직접입력 법인등록번호
    private companyIdInputReg: string = '';      // 직접입력 사업자등록번호
    private companyIdInputRepres: string = '';   // 직접입력 대표자 성명

    private companyBusinessType: string = '';    // 직접입력 기업 형태
    // private companyRegLicenseFilename: string = '';      // 사업자등록증 파일 이름

    private companyAddressFinder: boolean = false;  // 주소찾기 창 표시 여부

    // private companyEmailId: string = '';       // 이메일 아이디
    // private companyEmailDomain: string = '';   // 이메일 도메인
    // private companyEmailChkdDup: string = '';  // 중복확인된 이메일 주소
    // private companyPwd: string = '';           // 비밀번호
    // private companyPwdRe: string = '';         // 비밀번호 재확인

    private companyMobile: string = '';         // 담당자 전화번호

    // private companyPolicyTermAgree: boolean = false;       // 약관동의 서비스이용약관 및 개인정보취급방침
    // private companyPolicyInvestNotiAgree: boolean = false; // 약관동의 투자정보 수신
    // private companyPolicyMarketingAgree: boolean = false;  // 약관동의 마케팅 동의

    /*************************** 공동 필드 **************************/

    private commonEmailId: string = '';       // 이메일 아이디
    private commonEmailDomain: string = '';   // 이메일 도메인
    private commonEmailChkdDup: string = '';  // 중복확인된 이메일 주소
    private commonPwd: string = '';           // 비밀번호
    private commonPwdRe: string = '';         // 비밀번호 재확인
    // private emailCheckedDuplicate: string = ''; // 중복확인된 이메일 주소

    private commonZipCode: string = '';            // 우편번호
    private commonAddress: string = '';            // 찾은 주소
    private commonAddressExtra: string = '';       // 추가 주소

    private commonDepositAccountBankCode: string | null = null; // 계좌 은행 코드
    private commonDepositAccountNumber: string = '';  // 계좌 번호

    private commonPolicyTermAgree: boolean = false;       // 약관동의 서비스이용약관 및 개인정보취급방침
    private commonPolicyInvestNotiAgree: boolean = false; // 약관동의 투자정보 수신
    // private commonPolicyMarketingAgree: boolean = false;  // 약관동의 마케팅 동의

    private commonEmailIsDup: boolean = false;
    private signupInProcess: boolean = false;

    private niceEncData: string | null = null;

    // 은행코드 목록
    private bankCodes: BankEntry[] = [];

    /** 회원등록용 객체 */
    private registerForm: RegisterForm = {
        responseToken: null,
        type: this.MEMBER_TYPE_DOMESTIC,
        email: '',
        password: '',
        name: '',
        jumin1: '',
        jumin2: '',
        birthday: null,
        gender: '',
        telephone: '',
        allowMarketing: false,
        niceDI: null,

        companyRegId:  null,
        companyRepres: null,
        companyType: null,

        zipCode:  null,
        address1: null,
        address2: null,

        depositAccountBankCode: null,
        depositAccountNumber: ''
    };

    /************************* 가입 필드 끝 *************************/


    onWindowMessage(event: MessageEvent) {
        this.onWindowMessageIE(event.data.toString());
    }

    onWindowMessageIE(message: string) {
        const [type, data] = message.split(":");
        if (type === "NICE") {
            this.niceEncData = data;
        }
    }


    private get personalIdCheckName(): string {
        if (this.personalIdCheckNameVar) {
            return this.personalIdCheckNameVar;
        } else {
            return ''; // '(인증 후 이름이 뜹니다)';
        }
    }

    private get personalIdCheckBirth(): string {
        if (this.personalIdCheckBirthVar) {
            return this.personalIdCheckBirthVar;
        } else {
            return ''; // '(인증 후 생년월일이 뜹니다)';
        }
    }

    private get personalIdCheckSex(): string {
        if (this.personalIdCheckSexVar) {
            return (this.personalIdCheckSexVar === "M") ? "남" : "여";
        } else {
            return ''; // '(인증 후 성별이 뜹니다)';
        }
    }

    private get personalIdCheckPhone(): string {
        if (this.personalIdCheckPhoneVar) {
            return this.personalIdCheckPhoneVar;
        } else {
            return ''; // '(인증 후 전화번호가 뜹니다)';
        }
    }

    private get companyIdCheckName(): string {
        if (this.companyIdCheckNameVar) {
            return this.companyIdCheckNameVar;
        } else {
            return ''; // '(인증 후 법인명이 뜹니다)';
        }
    }

    private get companyIdCheckBrand(): string {
        if (this.companyIdCheckBrandVar) {
            return this.companyIdCheckBrandVar;
        } else {
            return ''; // '(인증 후 상호명이 뜹니다)';
        }
    }

    private get companyIdCheckNum(): string {
        if (this.companyIdCheckNumVar) {
            return this.companyIdCheckNumVar;
        } else {
            return ''; // '(인증 후 법인등록번호가 뜹니다)';
        }
    }

    private get companyIdCheckReg(): string {
        if (this.companyIdCheckRegVar) {
            return this.companyIdCheckRegVar;
        } else {
            return ''; // '(인증 후 사업자등록번호가 뜹니다)';
        }
    }

    private get companyIdCheckRepres(): string {
        if (this.companyIdCheckRepresVar) {
            return this.companyIdCheckRepresVar;
        } else {
            return ''; // '(인증 후 대표자 성명이 뜹니다)';
        }
    }

    /** 개인 회원 가입중인가 */
    private get isRegisteringPersonal(): boolean {
        return this.registerForm.type === this.MEMBER_TYPE_DOMESTIC
            || this.registerForm.type === this.MEMBER_TYPE_OVERSEAS;
    }

    /** 법인 회원 가입중인가 */
    private get isRegisteringCompany(): boolean {
        return this.registerForm.type === this.MEMBER_TYPE_COMPANY;
    }

    /** 해외 회원 가입중인가 */
    private get isRegisteringOverseas(): boolean {
        return this.registerForm.type === this.MEMBER_TYPE_OVERSEAS;
    }

    /** 법인 회원가입으로 전환 */
    private setRegisterPersonal() {
        this.registerForm.type = this.MEMBER_TYPE_DOMESTIC;
    }

    /** 법인 회원가입으로 전환 */
    private setRegisterCompany() {
        this.registerForm.type = this.MEMBER_TYPE_COMPANY;
    }

    /** 주소찾기 시작 버튼 (토글) */
    private showPersonalPostcode() {
        this.personalAddressFinder = !this.personalAddressFinder;
    }
    /** 주소찾기 시작 버튼 (토글) */
    private showCompanyPostcode() {
        this.companyAddressFinder = !this.companyAddressFinder;
    }

    private async loadBankCodes() {
        try {
            this.bankCodes = await APIClient.instance.loan.getBankCodeList();
        } catch (error) {
            EventBus.$emit('toast-notification', new FatalException(
                MessageHandler.combineMessage('은행 목록 불러오기에 실패하였습니다. 다시 시도해주세요.', error)
            ));
        }
    }

    /** 이메일 중복 확인 */
    private async checkForDuplicate() {
        this.commonEmailId = this.commonEmailId.trim();
        this.commonEmailDomain = this.commonEmailDomain.trim();

        let emailChkForDup: string =  this.commonEmailId + '@' + this.commonEmailDomain;
        try {
            let isDuplicate: boolean = await APIClient.instance.login.has(emailChkForDup);
            if ( ! isDuplicate) {
                // 사용할 수 있는 이메일 주소인것 같아요.
                this.commonEmailChkdDup = emailChkForDup;
                this.commonEmailIsDup = false;
            } else {
                // 이미 누군가 사용중인 이메일 주소.
                this.commonEmailChkdDup = '';
                this.commonEmailIsDup = true;
            }
        } catch (error) {
            // 아이디 중복확인을 하지 못한 경우.
            EventBus.$emit('toast-notification', new CustomException(
                MessageHandler.combineMessage('아이디 중복확인을 하지 못했습니다. 다시 시도해주세요.', error)
            ));
            this.commonEmailChkdDup = '';
            this.commonEmailIsDup = false;
        }

    }

    /**  이메일 중복확인 된 주소를 해제. 이메일주소 입력 이벤트로 써주세요.  */
    private resetCheckedDuplicate() {
        // this.personalEmailChkdDup = '';
        // this.companyEmailChkdDup = '';
        this.commonEmailChkdDup = '';
        this.commonEmailIsDup = false;
    }

    /** 이메일 중복체크되었는지 체크하는 함수 */
    private get emailDuplicateChecked(): boolean {
        return ( ! this.commonEmailIsDup) && (this.commonEmailChkdDup !== '');
    }

    private get emailDuplicatedStatus(): string {
        if (this.emailDuplicateChecked) {
            return '이 이메일 주소는 사용할 수 있습니다.';
        } else if (this.commonEmailIsDup) {
            return '이미 누군가가 사용하고 있습니다.';
        } else {
            return '왼쪽 중복확인 버튼을 눌러주세요.';
        }
    }

    /** 주소 찾기 */
    private handleAddress(data: any) {
        let fullAddress = data.jibunAddress;
        let extraAddress = '';
        if (data.addressType === 'R') {
            // 도로명 주소인경우
            fullAddress = data.roadAddress;

            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }

        this.commonZipCode = data.zonecode;
        this.commonAddress = fullAddress;
        this.commonAddressExtra = '';
        this.companyAddressFinder = false;
        this.personalAddressFinder = false;
    }

    /**
     * 회원 가입 전 form-validation을 행합니다
     */
    private validate(targetData: Validatable[]): boolean {
        for (const v of targetData) {
            const isValid = v.validateFn ? v.validateFn() : !!((this as any)[v.var]);
            if ( ! isValid) {
                if (v.name) {
                    EventBus.$emit('toast-notification', new CustomException(`${v.name}를 입력해주세요.`));
                }
                if (v.errorMessage) {
                    EventBus.$emit('toast-notification', new CustomException(v.errorMessage));
                }
                (this.$refs[v.element] as HTMLElement).focus();

                // 여러개가 실행되지 않게 합니다.
                return false;
            }
        }

        return true;
    }

    /** 입력 문자열과 숫자만 나오게 거른 문자열의 길이가 다르면 숫자가 아닌게 있는겁니다 */
    private hasOnlyNumber(input: string): boolean {
        let regEx = /[^0-9]/g;  // /[^0-9.]/g;
        let inputFiltered = input.replace(regEx, '');

        return input.length === inputFiltered.length;
    }

    /** 회원가입 동작 */
    private registerMember() {

        let self = this;

        if (this.isRegisteringPersonal) {
            // 개인 회원 가입

            const personalValidationData: Validatable[] = [

                { name: '이메일 아이디', var: 'commonEmailId', element: 'personalEmailId' },
                { name: '이메일 도메인', var: 'commonEmailDomain', element: 'personalEmailDomain' },
                { errorMessage: '이메일 주소 중복확인을 하지 않으셨습니다.',
                    var: 'commonEmailChkdDup', element: 'btnEmailCheckForDupPersonal',
                    validateFn: () => this.emailDuplicateChecked },
                { name: '비밀번호', var: 'commonPwd', element: 'personalPwd' },
                { name: '비밀번호 재확인', var: 'commonPwdRe', element: 'personalPwdRe' },
                { errorMessage: '입력하신 비밀번호가 같지 않습니다',
                    var: 'commonPwdRe', element: 'personalPwdRe',
                    validateFn: () => this.commonPwd === this.commonPwdRe },

                { name: '주민등록번호 앞자리', var: 'personalSsn1', element: 'personalSsn1'},
                { errorMessage: '주민등록번호 앞자리를 숫자로 6자리 입력해주세요.',
                    var: 'personalSsn1', element: 'personalSsn1',
                    validateFn: () => (this.personalSsn1.length === 6) && this.hasOnlyNumber(this.personalSsn1) },
                { name: '주민등록번호 뒷자리', var: 'personalSsn2', element: 'personalSsn2'},
                { errorMessage: '주민등록번호 뒷자리를 숫자로 7자리 입력해주세요.',
                    var: 'personalSsn2', element: 'personalSsn2',
                    validateFn: () => (this.personalSsn2.length === 7) && this.hasOnlyNumber(this.personalSsn2) },
                { errorMessage: '주민등록번호를 올바르게 입력해주세요.',
                    var: 'personalSsn1', element: 'personalSsn1',
                    validateFn: () => isSSNlegit(`${this.personalSsn1}${this.personalSsn2}`)},

                { errorMessage: '주소를 입력하지 않으셨습니다.',
                    var: 'commonZipCode', element: 'btnFindZipCodePersonal',
                    validateFn: () => {
                        if (self.commonZipCode && self.commonAddress) {
                            // if로 거르지 않고 return 으로 바로 사용하는 경우
                            // string 형으로 되고 컴파일 오류가 표시된다.
                            return true;
                        } else {
                            return false;
                        }
                    }
                },

                { name: '계좌 은행 선택', var: 'commonDepositAccountBankCode', element: 'personalBankCode'},
                { name: '계좌번호', var: 'commonDepositAccountNumber', element: 'personalBankAccountNumber'},
                { errorMessage: '계좌번호를 숫자로 입력해주세요.',
                    var: 'commonDepositAccountNumber', element: 'personalBankAccountNumber',
                    validateFn: () => this.hasOnlyNumber(this.commonDepositAccountNumber) },

            ];

            if ( ! this.validate(personalValidationData) ) {
                // 개인 고객용 정보 입력 유효성 불만족
                return false;
            }


            // 2022-11-30 임시로 NICE ID 사용하지 않도록 변경
            // Issue #29 (https://github.com/SCV-Soft/buildon-partners-frontend/issues/29)
            if ( false ) {
            // if ( ! this.isRegisteringOverseas) {
                
                // 국내 회원

                const personalIdentityCheckValidationData: Validatable[] = [
                    { errorMessage: '본인인증을 진행해주세요.', var: 'personalIdCheckNameVar', element: 'personalIdentityCheck' },
                    { errorMessage: '본인인증을 진행해주세요.', var: 'personalIdCheckBirthVar', element: 'personalIdentityCheck' },
                    { errorMessage: '본인인증을 진행해주세요.', var: 'personalIdCheckSexVar', element: 'personalIdentityCheck' },
                    { errorMessage: '본인인증을 진행해주세요.', var: 'personalIdCheckPhoneVar', element: 'personalIdentityCheck' },
                    { errorMessage: '본인인증을 진행해주세요.', var: 'personalIdCheckDi', element: 'personalIdentityCheck' },
                ];

                if ( ! this.validate(personalIdentityCheckValidationData)) {
                    // 해외 고객용 정보 입력 유효성 불만족
                    return false;
                }
            } else {
                // 해외 회원
                const overseasValidationData: Validatable[] = [

                    { name: '이름', var: 'personalIdInputName', element: 'personalIdInputName' },
                    { name: '생년월일', var: 'personalIdInputBirth', element: 'personalIdInputBirth' },
                    { errorMessage: '생년월일을 숫자로 8자리 입력해주세요.',
                        var: 'personalIdInputBirth', element: 'personalIdInputBirth',
                        validateFn: () => (this.personalIdInputBirth.length === 8)
                            && this.hasOnlyNumber(this.personalIdInputBirth) },
                    { name: '성별', var: 'personalIdInputSex', element: 'personalIdInputSex' },
                    { name: '전화번호', var: 'personalIdInputPhone', element: 'personalIdInputPhone'},
                    { errorMessage: '전화번호를 숫자로 입력해주세요.',
                        var: 'personalIdInputPhone', element: 'personalIdInputPhone',
                        validateFn: () => this.hasOnlyNumber(this.personalIdInputPhone) },
                ];

                if ( ! this.validate(overseasValidationData)) {
                    // 해외 고객용 정보 입력 유효성 불만족
                    return false;
                }
            }

            const policyPersonalValidationData: Validatable[] = [
                // 약관 동의
                { errorMessage: "서비스 이용약관 및 개인정보취급방침에 동의하셔야 가입이 가능합니다.",
                    var: 'commonPolicyTermAgree', element: 'personalPolicyTermAgree',
                    validateFn: () => this.commonPolicyTermAgree },
                { errorMessage: "투자 정보 수신에 동의하셔야 가입이 가능합니다.",
                    var: 'commonPolicyInvestNotiAgree', element: 'personalPolicyInvestNotiAgree',
                    validateFn: () => this.commonPolicyInvestNotiAgree },

            ];

            if ( ! this.validate(policyPersonalValidationData)) {
                // 개인 고객용 정책 동의 유효성 불만족
                return false;
            }

            this.registerForm.email = this.commonEmailChkdDup;
            this.registerForm.password = this.commonPwd;
            // 2022-11-30 임시로 NICE ID 사용하지 않도록 변경
            // Issue #29 (https://github.com/SCV-Soft/buildon-partners-frontend/issues/29)

            // this.registerForm.name = (this.personalIdCheckNameVar)
            //     ? this.personalIdCheckNameVar : this.personalIdInputName;
            this.registerForm.name = this.personalIdInputName;

            this.registerForm.jumin1 = this.personalSsn1;
            this.registerForm.jumin2 = this.personalSsn2;

            this.registerForm.zipCode = this.commonZipCode;
            this.registerForm.address1 = this.commonAddress;
            this.registerForm.address2 = this.commonAddressExtra;

            this.registerForm.depositAccountBankCode = this.commonDepositAccountBankCode;
            this.registerForm.depositAccountNumber = this.commonDepositAccountNumber;

            // moment.tz.add('Asia/Seoul'); 한 뒤
            // moment(string, format).tz('Asia/Seoul') ~ 사용시
            // undefined 에 split을 시도했다는 오류 발생
            // this.registerForm.birthday = (this.personalIdCheckBirthVar)
            //     ? moment(this.personalIdCheckBirthVar, 'YYYY. MM. DD.').toDate()
            //     : moment(this.personalIdInputBirth, 'YYYYMMDD').toDate();
            // 2019-06-18 Date로 주니 오류가 난다 string으로 줘보자
            
            // 2022-11-30 임시로 NICE ID 사용하지 않도록 변경
            // Issue #29 (https://github.com/SCV-Soft/buildon-partners-frontend/issues/29)

            // this.registerForm.birthday = (this.personalIdCheckBirthVar)
            //     ? this.personalIdCheckBirthVar : this.personalIdInputBirth;
            // this.registerForm.gender = (this.personalIdCheckSexVar)
            //     ? this.personalIdCheckSexVar : this.personalIdInputSex;
            // this.registerForm.telephone = (this.personalIdCheckPhoneVar)
            //     ? this.personalIdCheckPhoneVar : this.personalIdInputPhone;
            // this.registerForm.niceDI = (this.personalIdCheckDi)
            //     ? this.personalIdCheckDi : null;
            this.registerForm.birthday = this.personalIdInputBirth;
            this.registerForm.gender = this.personalIdInputSex;
            this.registerForm.telephone = this.personalIdInputPhone;
            this.registerForm.niceDI = null;

        } else if (this.isRegisteringCompany) {
            // 법인 회원 가입

            const companyValidationData: Validatable[] = [
                // 회사 정보 입력
                { name: '법인명', var: 'companyIdInputName', element: 'companyIdInputName' },
                { name: '법인등록번호', var: 'companyIdInputNum', element: 'companyIdInputNum' },
                { errorMessage: '법인등록번호를 숫자로 13자리 입력해주세요.',
                    var: 'companyIdInputNum', element: 'companyIdInputNum',
                    validateFn: () => (this.companyIdInputNum.length === 13)
                        && this.hasOnlyNumber(this.companyIdInputNum) },
                { errorMessage: '법인등록번호를 올바르게 입력해주세요.',
                    var: 'companyIdInputNum', element: 'companyIdInputNum',
                    validateFn: () => isCRNlegit(`${this.companyIdInputNum}`)},
                { name: '사업자등록번호', var: 'companyIdInputReg', element: 'companyIdInputReg' },
                { errorMessage: '사업자등록번호를 숫자로 입력해주세요.',
                    var: 'companyIdInputReg', element: 'companyIdInputReg',
                    validateFn: () => this.hasOnlyNumber(this.companyIdInputReg) },
                { name: '대표자 성명', var: 'companyIdInputRepres', element: 'companyIdInputRepres' },
                { name: '기업형태', var: 'companyBusinessType', element: 'companyBusinessType' },
                { errorMessage: '회사 주소를 입력하지 않으셨습니다.',
                    var: 'commonZipCode', element: 'btnFindZipCodeCompany',
                    validateFn: () => {
                        if (self.commonZipCode && self.commonAddress) {
                            // if로 거르지 않고 return 으로 바로 사용하는 경우
                            // string 형으로 되고 컴파일 오류가 표시된다.
                            return true;
                        } else {
                            return false;
                        }
                    }
                },

                { name: '계좌 은행 선택', var: 'commonDepositAccountBankCode', element: 'companyBankCode'},
                { name: '계좌번호', var: 'commonDepositAccountNumber', element: 'companyBankAccountNumber'},
                { errorMessage: '계좌번호를 숫자로 입력해주세요.',
                    var: 'commonDepositAccountNumber', element: 'companyBankAccountNumber',
                    validateFn: () => this.hasOnlyNumber(this.commonDepositAccountNumber) },

                // 사내 담당자 정보
                { name: '이메일 아이디', var: 'commonEmailId', element: 'companyEmailId' },
                { name: '이메일 도메인', var: 'commonEmailDomain', element: 'companyEmailDomain' },
                { errorMessage: '이메일 주소 중복확인을 하지 않으셨습니다.',
                    var: 'commonEmailChkdDup', element: 'btnEmailCheckForDupCompany',
                    validateFn: () => this.emailDuplicateChecked },
                { name: '비밀번호', var: 'commonPwd', element: 'companyPwd' },
                { name: '비밀번호 재확인', var: 'commonPwdRe', element: 'companyPwdRe' },
                { errorMessage: '입력하신 비밀번호가 같지 않습니다',
                    var: 'commonPwdRe', element: 'companyPwdRe',
                    validateFn: () => this.commonPwd === this.commonPwdRe },
                { name: '전화번호', var: 'companyMobile', element: 'companyMobile' },
                { errorMessage: '전화번호를 숫자로 입력해주세요.',
                    var: 'companyMobile', element: 'companyMobile',
                    validateFn: () => this.hasOnlyNumber(this.companyMobile) },
            ];

            if ( ! this.validate(companyValidationData) ) {
                // validate 실패
                return false;
            }

            const policyCompanyValidationData: Validatable[] = [
                // 정책 동의
                { errorMessage: "서비스 이용약관 및 개인정보취급방침에 동의하셔야 가입이 가능합니다.",
                    var: 'commonPolicyTermAgree', element: 'companyPolicyInvestNotiAgree',
                    validateFn: () => this.commonPolicyTermAgree },
                { errorMessage: "투자 정보 수신에 동의하셔야 가입이 가능합니다.",
                    var: 'commonPolicyInvestNotiAgree', element: 'companyPolicyInvestNotiAgree',
                    validateFn: () => this.commonPolicyInvestNotiAgree },

            ];

            if ( ! this.validate(policyCompanyValidationData)) {
                // 법인 고객용 정책 동의 유효성 불만족
                return false;
            }


            this.registerForm.email = this.commonEmailChkdDup;
            this.registerForm.password = this.commonPwd;
            this.registerForm.name = (this.companyIdCheckNameVar)
                ? this.companyIdCheckNameVar : this.companyIdInputName;

            let companyNum = (this.companyIdCheckNumVar)
                ? this.companyIdCheckNumVar : this.companyIdInputNum;
            this.registerForm.jumin1 = companyNum.substring(0, 0 + 6);
            this.registerForm.jumin2 = companyNum.substring(6, 6 + 7);

            this.registerForm.depositAccountBankCode = this.commonDepositAccountBankCode;
            this.registerForm.depositAccountNumber = this.commonDepositAccountNumber;

            this.registerForm.telephone = this.companyMobile;

            this.registerForm.companyRegId = (this.companyIdCheckRegVar)
                ? this.companyIdCheckRegVar : this.companyIdInputReg;
            this.registerForm.companyRepres = (this.companyIdCheckRepresVar)
                ? this.companyIdCheckRepresVar : this.companyIdInputRepres;

            this.registerForm.companyType = this.companyBusinessType;

            this.registerForm.zipCode = this.commonZipCode;
            this.registerForm.address1 = this.commonAddress;
            this.registerForm.address2 = this.commonAddressExtra;

        }

        // type 은 v-model로 연결되어있다.
        // allowMarketing 은 v-model로 연결되어있다.

        // ReCAPTCHA
        // if ( ! this.registerForm.responseToken) {
        //     EventBus.$emit('toast-notification', new CustomException("자동가입을 막기 위해 '로봇이 아닙니다' 체크박스를 클릭해주세요."));
        //     return false;
        // }


        // this.registerForm 을 제출하는 API call을 여기에
        this.performSignup();
    }

    private async performSignup() {
        if (this.signupInProcess) {
            EventBus.$emit('toast-notification', new InformationNotification("회원가입 과정을 처리하고 있습니다. 잠시만 기다려주세요!"));
        } else {
            // 회원가입이 진행중임을 선언
            this.signupInProcess = true;

            try {
                let registerResult = await APIClient.instance.signup.signup(this.registerForm);


                // EventBus.$emit('toast-notification', new SuccessNotification("회원가입이 정상적으로 이루어졌습니다."
                // + "이제 빌드온파트너스대부에서 투자 상품을 누려보세요."));
                if (!registerResult.success) {
                    EventBus.$emit('toast-notification', new CustomException(
                        MessageHandler.combineMessage("회원가입에 실패하였습니다. 다시 시도해주세요.", registerResult)
                    ));

                    return false;
                }

                // 가입 대기중인 회원은 로그인을 할 수 없습니다.
                // 승인 이후 빌드온파트너스대부의 투자상품을 누릴 수 있다는 메시지를 송출하고 종료

                let companyOrOverseas: string = "";
                if (this.isRegisteringPersonal) {
                    companyOrOverseas = companyOrOverseas + "국내";
                }
                if (this.isRegisteringOverseas) {
                    companyOrOverseas = companyOrOverseas + "해외";
                }
                if (this.isRegisteringCompany) {
                    companyOrOverseas = companyOrOverseas + "법인";
                }

                EventBus.$emit('toast-notification', new SuccessNotification(
                    "빌드온파트너스대부 "
                    + companyOrOverseas
                    + " 회원가입 신청이 정상적으로 완료되었습니다.\n"
                    + "빌드온파트너스대부는 심사 후 승인제로 운영되며, 가급적 빠른 시일 내에 가입 확인을 진행하겠습니다."
                ));

                this.$router.push({ path: '/' });

                return false;

            } catch (error) {
                EventBus.$emit('toast-notification', new CustomException(
                MessageHandler.combineMessage('회원가입이 정상적으로 이루어지지 않았습니다. 다시 시도해주세요.', error)
                ));
            } finally {
                // 작업이 완료되거나 예외가 발생한 경우 등 회원가입 진행중 표식을 해제
                this.signupInProcess = false;
            }
        }
    }

    // private onRecaptchaVerified(response: string) {
    //     this.registerForm.responseToken = response;
    // }
    //
    // private onRecaptchaExpired() {
    //     this.registerForm.responseToken = null;
    // }

    // 마운트 되었을 때 호출
    private mounted() {
        this.setMetadata();

        this.loadBankCodes();
        this.prepareNiceEncData();
        window.addEventListener('message', this.onWindowMessage);
        (window as any).__onWindowMessageIE = this.onWindowMessageIE; // IE 대응
    }

    private destroyed() {
        window.removeEventListener('message', this.onWindowMessage);
        delete (window as any).__onWindowMessageIE; // 객체 삭제는 delete 로
    }

    /**
     * 서버로부터 NiceEncData 가져오기
     */
    private async prepareNiceEncData() {
        let niceDiPrepareVal: NiceDiPrepareVal = await APIClient.instance.signup.prepareNiceDi();

        this.personalIdCheckNiceEncData = niceDiPrepareVal.sencData;
    }

    private openNicePopup() {
        window.open(
            '',
            'popupChk',
            ('width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, ' +
             'status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no')
        );
        // HTMLFormElement로 nicePopupPersonalForm을 불러들여 popupChk 창으로 제출, 본인인증창을 띄웁니다.
        let nicePopup: HTMLFormElement = this.$refs.nicePopupPersonalForm as HTMLFormElement;
        nicePopup.setAttribute("target", "popupChk");
        nicePopup.setAttribute("action", "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb");
        nicePopup.submit();
    }

    @Watch('niceEncData')
    private async onNiceEncDataChanged() {
        const response = await APIClient.instance.signup.decodeNiceEncodedData(this.niceEncData!);

        this.personalIdCheckNameVar = response.sname;
        this.personalIdCheckBirthVar = response.sbirthDate;
        this.personalIdCheckSexVar = (parseInt(response.sgender, 10) % 2 === 0) ? "F" : "M"; // 0 여 1 남
        this.personalIdCheckPhoneVar = response.smobileNo;
        this.personalIdCheckDi = response.sdupInfo;
    }

    /**
     * 약관 창을 엽니다.
     * @param whatToOpen 열어야할 약관 파일 이름
     */
    private openTerms(whatToOpen: string) {
        try {
            window.self.postMessage(`termsWinOpen:${whatToOpen}`, location.origin);
        } catch (error) {
            ((window.self) as any).__openTermsWindow(whatToOpen);
        }
    }

    /**
     * 모든 필수 및 선택 약관에 동의하게 합니다.
     */
    private policyAllAgree() {
        this.commonPolicyInvestNotiAgree = true;
        this.commonPolicyTermAgree = true;
        this.registerForm.allowMarketing = true;
    }

    private setMetadata() {
        let meta: Metadata = {
            title: '회원가입',
            // description: '회원가입 및 가상계좌 발급을 통해 빌드온파트너스대부에서 투자 상품을 누려보세요.'
        };
        EventBus.$emit("meta-title", meta);
    }
}
