




















































































































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import CenterWrapper from '@/components/CenterWrapper.vue';
import { Product } from '../api/product';
import APIClient from '../api/client';
import StoreHelper from "@/mixins/StoreHelper";
import {mixins} from "vue-class-component";
import DescPreview from "@/components/DescPreview.vue";

import { EventBus } from '@/lib/event-bus';

import {CustomException, FatalException} from '@/api/client/notification';
import {MessageHandler} from "@/api/client/error";
import CommaInput from "@/components/CommaInput.vue";
import {Metadata} from "@/metadata";
import PortfolioTile from "@/components/PortfolioTile.vue";
import {Member} from "@/api/member";
import PortfolioMerchandiseTile from "@/components/PortfolioMerchandiseTile.vue";
import {PortfolioMerchandise} from "@/api/portfolioMerchandise";


@Component({
    components: {
        PortfolioMerchandiseTile,
        CenterWrapper,
        DescPreview,
        CommaInput,
        PortfolioTile,
    }
})
export default class PortfolioDetail extends Mixins<StoreHelper>(StoreHelper) {
    /** 기본 이미지로 우회합니다. */
    public readonly URL_IMG_FALLBACK: string = "../assets/product-list/no-image.png";

    @Prop({ required: true })
    public productId!: string;
    public product: Product | null = null;

    public groupIdPrev: number = -1;
    public groupId: number = -1;
    public productInSamePortfolioGroup: Product[] = [];

    @Watch('productId', { immediate: true })
    private async onProductIdChanged() {
        try {
            this.product = await (APIClient.instance).product.get(this.productId);

            if ( ! this.product.isMonthlyProduct) {
                // 월간 상품이 아니면 일반 상품 페이지로 이동합니다.
                this.$router.replace(`/products/${this.product.index}`);

                return false;
            }
            this.setMetadata();


            this.productInSamePortfolioGroup.length = 0;
            this.productInSamePortfolioGroup.push(this.product);
            // 2020-02-18 한 상품에 하나만 보이게 하기
            // 상품 포트폴리오 호출을 위해 그룹 고유번호 조회
            // if (this.product.group && this.product.group.hasOwnProperty('index')) {
            //     this.groupId = (this.product.group as any).index;
            // }
        } catch (e) {
            // TODO: 없는 상품이면은.. 홈으로 이동시키기?
            this.$router.push('/');
            EventBus.$emit('toast-notification', new FatalException(
                MessageHandler.combineMessage("상품 정보를 가져오지 못했습니다.", e)
            ));
        }
        try {
            this.loadLastTransactionForThis();
        } catch (e) {
            // 투자내역을 가져오지 못해도 기존처럼 투자할 수 있으므로 아무것도 할 필요 없음.
        }
    }

    // 2020-02-18 한 상품에 하나만 보이게 하기
    // @Watch('groupId', { immediate: true }) // groupId 변화가 감지되면 실행
    // private async handleGroupIdChanges() {
    //     // 새로고침 필요 여부
    //     let needToRefresh: boolean = true;
    //
    //     if (this.groupIdPrev === this.groupId) {
    //         // 상품 그룹 Id가 같습니다. 새로고침 불필요.
    //         needToRefresh = false;
    //     }
    //
    //     if (needToRefresh) {
    //         try {
    //             this.productInSamePortfolioGroup =
    //                 await (APIClient.instance).product.getPortfoliosByGroup(this.groupId);
    //
    //             // 성공했으니 그룹 ID 남기기
    //             this.groupIdPrev = this.groupId;
    //         } catch (e) {
    //             // 포트폴리오 그룹 상품 불러오는데 오류가 발생했습니다.
    //             EventBus.$emit('toast-notification', new FatalException(
    //                 MessageHandler.combineMessage("포트폴리오 그룹 상품 정보를 가져오지 못했습니다.", e)
    //             ));
    //         }
    //     }
    // }

    /** 상품 이미지 URL */
    private get attachedImageURLs(): string[] {
        if (this.product!.productImages.length > 0) {
            return this.product!.productImages.map(a => a.url);
        }
        return [];
    }

    private get associatedPortfolios(): PortfolioMerchandise[] {
        if (this.product && this.product.associatedPortfolios) {
            return this.product.associatedPortfolios;
        }
        return [];
    }

    private mounted() {
        this.setMetadata();
    }


    private async loadLastTransactionForThis() {
        // 2020-01-14 투자금액 변경하기 버튼 무력화
        return ;
        //
        // if (this.product) {
        //     try {
        //         // 상품정보를 받았으니 해당 상품에 대한 transaction들이 있는지 조회.
        //         let investTransactions =
        //             await APIClient.instance.mypage.getMyTransactionsOnProduct(this.product!.index);
        //
        //         if (investTransactions) {
        //             // 그중에 맨 마지막 유효 상품을 골라냅니다.
        //             investTransactions.forEach(
        //                 (txn: InvestmentTransaction, index: number, txnList: InvestmentTransaction[]) => {
        //                     // if (!txn.confirmedAt && !txn.canceled) {
        //                     if (!txn.canceled) {
        //                         // 취소 안된거라면
        //                         // 최근 txn으로 처리합니다.
        //                         // 2019-12-12 컨펌 받은거라면 증액만 가능.
        //                         //            감액은 취소후 재투자 필요.
        //
        //                         if (!!txn.confirmedAt) {
        //                             this.lastConfirmedTxn = txn;
        //                         } else {
        //                             this.lastNotConfirmedTxn = txn;
        //                         }
        //                     }
        //                 });
        //         }
        //     } catch (error) {
        //         // 에러 메시지 처리를 할 필요가 있을까요?
        //     }
        // }
    }

    // 현재 투자 가능한 상품인지 보여줍니다.
    private checkInvestible(product: Product): boolean {
        if (product) {
            return product.state === "funding";
        }
        return false;
    }

    private get profile(): Member | null {
        return this.$state.profile;
    }

    /** 투자하기 화면으로 이동 */
    private investThis(product: Product) {

        if ( ! this.checkInvestible(product)) {
            EventBus.$emit('toast-notification', new CustomException('이 상품은 투자할 수 없습니다.'));
            return false;
        }

        if ( ! this.profile ) {
            // 로그인 되어있지 않습니다.
            this.showLoginPopup();

            return false;
        }

        if ( product ) {
            this.$router.push(`/portfolios/${product.index}/invest`);
            return false;
        }
    }

    private onClickMerchandiseTile(merchandise: PortfolioMerchandise) {
        console.log(merchandise);
        console.log(`merchandise tile clicked`);
    }

    private setMetadata() {

        let meta: Metadata;

        let pageTitle: string = '상품보기';
        // let desc: string = '빌드온파트너스대부 투자상품보기 페이지입니다.';

        if (this.product) {
            // 상품이 로드되었을 때는 상품 정보를 보이게 합니다.
            pageTitle = `상품보기: ${this.product.title}`;
            // desc = `빌드온파트너스대부 투자상품 ${this.product.title} 입니다.`;
            //
            // if (this.product.intro) {
            //     desc = MetadataUtils.processContentBelow(this.product.intro);
            // }
        }
        meta = {
            title: pageTitle,
            // description: desc
        };
        EventBus.$emit("meta-title", meta);
    }

}
