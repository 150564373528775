













































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CenterWrapper from '@/components/CenterWrapper.vue';
import {FindPasswordConfirmForm} from "@/api/in/login";
import {APIClient} from "@/api/client/client";

import { EventBus } from '@/lib/event-bus';

import {
    CustomException,
    SuccessNotification,
    InformationNotification,
    FatalException
} from '@/api/client/notification';
import {MessageHandler} from "@/api/client/error";
import {Metadata} from "@/metadata";

@Component({
    components: {
        CenterWrapper,
    }
})
export default class FindPasswordByToken extends Vue {

    // public readonly recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_KEY!;

    private pwChangeForm: FindPasswordConfirmForm = {
        responseToken: null,
        token: '',
        password: '',
    };

    private pwRetry: string = '';

    // 비밀번호 변경이 성공했는지 플래그
    private pwChangeSubmitted: boolean = false;

    private pwChangeNotPossible: boolean = false;

    //
    // onRecaptchaVerified(response: string) {
    //     this.pwChangeForm.responseToken = response;
    // }
    //
    // onRecaptchaExpired() {
    //     this.pwChangeForm.responseToken = null;
    // }


    mounted() {
        this.setMetadata();

        // URL GET 매개변수들 중 필요한 것을 즉석 추출
        let urlParams = new URLSearchParams(window.location.search);

        let token = urlParams.get('token');
        if (token) {
            this.pwChangeForm.token = token;

            this.checkTokenIsAvailable(token);
        } else {
            // 잘못된 접근입니다. 돌아감
            EventBus.$emit('toast-notification', new FatalException("잘못된 접근입니다.\n메인페이지로 돌아갑니다."));
            this.$router.push('/');
        }

        // let id = urlParams.get('id');
        // if (id) {
        //     this.token = token;
        // }
    }

    async handlePwChangeRequest() {
        if ( ! this.pwChangeForm.password ) {
            EventBus.$emit('toast-notification', new CustomException("비밀번호를 입력해주세요."));
            (this.$refs.pwdInputOnce as HTMLInputElement).focus();
            return false;
        }

        if ( ! this.pwRetry ) {
            EventBus.$emit('toast-notification', new CustomException("비밀번호 재확인란에 입력해주세요."));
            (this.$refs.pwdInputRetry as HTMLInputElement).focus();
            return false;
        }

        if ( this.pwChangeForm.password !== this.pwRetry ) {
            EventBus.$emit('toast-notification', new CustomException("비밀번호와 재확인란이 일치하지 않습니다."));

            return false;
        }

        // 비밀번호 입력 상태에 대한 체크 완료. 리캡챠 체크

        // if ( ! this.pwChangeForm.responseToken) {
        //     EventBus.$emit('toast-notification', new CustomException("보안을 위해 로봇 방지 체크를 진행해 주십시오."));
        //
        //     return false;
        // }

        // 리캡챠 체크 완료

        // TODO: 제출!
        try {
            let response = await APIClient.instance.login.confirmPasswordReset(this.pwChangeForm);
            if (response.success) {
                this.pwChangeSubmitted = true;
            } else {
                EventBus.$emit('toast-notification', new FatalException(
                    MessageHandler.combineMessage("비밀번호 재설정에 실패했습니다.", response)
                ));
            }
        } catch (error) {
            EventBus.$emit('toast-notification', new FatalException(
                MessageHandler.combineMessage("비밀번호 변경에 실패하였습니다.", error)
            ));
        }

    }


    async checkTokenIsAvailable(token: string) {
        try {
            let response = await APIClient.instance.login.checkResetTokenValid(token);

            if (response.success != null) {
                if (response.success) {
                    // 이 토큰은 사용가능
                    // do nothing
                } else {
                    // 토큰 사용 불가
                    this.pwChangeNotPossible = true;
                }
            }
        } catch (error) {
            this.pwChangeNotPossible = true;
        }
    }

    private setMetadata() {
        let meta: Metadata = {
            title: '비밀번호 재설정',
            // description: '회원님의 비밀번호를 재설정할 수 있습니다.'
        };
        EventBus.$emit("meta-title", meta);
    }

}
