
































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CenterWrapper from '@/components/CenterWrapper.vue';
import NoticeTile from '@/components/NoticeTile.vue';

import { EventBus } from '@/lib/event-bus';

import Notice from '@/api/notice';
import {APIClient} from "@/api/client/client";
import { CustomException } from '@/api/client/notification';
import {MessageHandler} from "@/api/client/error";
import {Metadata, MetadataUtils} from "@/metadata";

@Component({
    components: {
        CenterWrapper,
        NoticeTile,
    }
})
export default class NoticeDetail extends Vue {


    @Prop({ required: true })
    public noticeId!: string;

    private noticeEntry: Notice | null = null;

    // 공지사항 다음글
    private noticeNext: Notice | null = null;

    // 공지사항 이전글
    private noticePrev: Notice | null = null;

    /**
     * 마운트 되었을 때 호출됩니다.
     */
    private mounted() {
        this.loadNotice();
    }

    // noticeId 변경시 호출
    @Watch('noticeId')
    private onNoticeIdChanged() {
        this.loadNotice();
    }

    private async loadNotice() {
        await this.loadNoticeViaApi();

        this.setMetadata();

        // 2019-07-03 제플린 시안에 이전글 다음글 표시 기능 없음
        // await this.loadNextViaApi();
        // await this.loadPrevViaApi();
    }

    // 본 공지사항
    private async loadNoticeViaApi() {
        try {
            this.noticeEntry = await APIClient.instance.notice.get(this.noticeId);
        } catch (error) {
            EventBus.$emit('toast-notification', new CustomException(
                MessageHandler.combineMessage(this.noticeId + "번 공지사항을 가져올 수 없습니다.", error)
            ));
            history.back();
        }
    }

    // 공지사항 다음글
    private async loadNextViaApi() {
        try {
            this.noticeNext = await APIClient.instance.notice.next(this.noticeId);
        } catch (error) {
            EventBus.$emit('toast-notification', new CustomException(
                MessageHandler.combineMessage(this.noticeId + "번 공지사항의 다음글을 가져올 수 없습니다.", error)
            ));
        }
    }

    // 공지사항 이전글
    private async loadPrevViaApi() {
        try {
            this.noticePrev = await APIClient.instance.notice.prev(this.noticeId);
        } catch (error) {
            EventBus.$emit('toast-notification', new CustomException(
                MessageHandler.combineMessage(this.noticeId + "번 공지사항의 이전글을 가져올 수 없습니다.", error)
            ));
        }
    }

    private goToList() {
        this.$router.push('/board/notice');
    }

    private setMetadata() {

        let meta: Metadata;

        let pageTitle: string = '공지사항';
        // let desc: string = '빌드온파트너스대부 공지사항 보기 페이지입니다.';

        if (this.noticeEntry) {
            // 상품이 로드되었을 때는 상품 정보를 보이게 합니다.
            pageTitle = `공지사항: ${this.noticeEntry.title}`;
            // desc = `빌드온파트너스대부 공지사항 ${this.noticeEntry.title} 입니다.`;
            //
            // if (this.noticeEntry.content) {
            //     desc = MetadataUtils.processContentBelow(this.noticeEntry.content);
            // }
        }
        meta = {
            title: pageTitle,
            // description: desc
        };
        EventBus.$emit("meta-title", meta);
    }
}
