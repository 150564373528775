





























































































































































































































































































import {Component, Vue, Prop, Watch, Mixins} from 'vue-property-decorator';
import CenterWrapper from '@/components/CenterWrapper.vue';
import { Product } from '../api/product';
import APIClient from '../api/client';

import { EventBus } from '@/lib/event-bus';

import {
    CustomException,
    FatalException,
    InformationNotification
} from '@/api/client/notification';
import {MessageHandler} from "@/api/client/error";
import CommaInput from "@/components/CommaInput.vue";
import {Metadata} from "@/metadata";
import PdfViewer from "@/components/PdfViewer.vue";
import {InvestmentTransaction} from "@/api/transaction";
import StoreHelper from "@/mixins/StoreHelper";
import {mixins} from "vue-class-component";
import moment from "moment";

@Component({
    components: {
        PdfViewer,
        CenterWrapper,
        CommaInput,
    }
})
export default class ProductInvest extends Mixins<StoreHelper>(StoreHelper) {
    @Prop({ required: true })
    public productId!: number;

    @Prop({ required: false })
    public transactionId!: number;

    // 상품과 회원정보가 로드되 투자가 가능한 경우
    private isReady: boolean = false;
    private failMessage: string | null = null;

    // 상품과 회원정보
    private product: Product | null = null;

    // 투자 트랜잭션
    private investTransactions: InvestmentTransaction[] = [];
    private transaction: InvestmentTransaction | null = null;

    // 입력란
    private investAmountInput: number = 0;
    private agreeInput: string = '';
    private depositDateOfMonth: number = 5;

    // 약관 동의
    private investPolicyPrivacy: boolean = false;
    private investPolicyTerms: boolean = false;

    // 수수료 면제 상품인 경우
    private feeExcluded: boolean = true;

    private agreementPdfSrc: string = '/pdfvw/dummy.pdf';

    private readonly STATE_INVEST_FIRST: number = 1;
    private readonly STATE_INVEST_AGREEMENT: number = 2;
    private readonly STATE_INVEST_COMPLETED: number = 3;


    // 투자가 완료된 경우
    private investState: number = this.STATE_INVEST_FIRST;
    private investProceeding: boolean = false;
    private investOverbooked: boolean = false;


    // 현재 날짜
    private readonly today: Date = new Date();

    private get member() {
        return this.$state.profile;
    }


    private stepWarned() {
        EventBus.$emit('toast-notification', new InformationNotification(
            `입력란에 ${this.trimAmountIntoKoreanUnits(this.investAmountStep)}원 단위로만 값을 넣을 수 있습니다.`));
    }

    // 동의합니다 입력 여부
    private get isConsent() {
        return this.agreeInput.includes('동의합니다') && this.member!.contractible;
        // && this.investPolicyPrivacy && this.investPolicyTerms;
    }

    // 현재 날짜로부터 복제한 date를 가져옴
    private get nowDateFixed(): Date {
        return this.today;
    }

    // 상환 기간 수로
    private get repaymentMethodMonthInNumber(): number {
        if (this.product) {
            let repaymentMethodMonth: number = parseInt(this.product.repaymentMethodMonth!, 10);

            if (repaymentMethodMonth) {
                if ( isNaN(repaymentMethodMonth) ) {
                    repaymentMethodMonth = 1;
                }
            }

            return repaymentMethodMonth;
        }
        return 0;
    }

    // 0.0~1.0 사이의 수익률
    private get productInterestRateInNumber(): number {
        let interestRate = 0 / 100;
        if (this.product) {
            interestRate = this.product.interestRate / 100;
        }
        return interestRate;
    }

    // 0.0~1.0 사이의 이자소득세율
    private get productInterestTaxRateInNumber(): number {
        let interestTaxRate = 15.4 / 100;
        if (this.product) {
            interestTaxRate = this.product.interestTaxRate / 100;
        }
        return interestTaxRate;
    }

    // 현재 월에 대한 이자 계산
    private estimateInterest(investAmount: number, currentMonth: number): number {
        return investAmount * this.productInterestRateInNumber
            * ((this.repaymentMethodMonthInNumber - (currentMonth - 1)) / this.repaymentMethodMonthInNumber);
    }

    // 현재 월에 대한 세후이자 계산
    private estimateAfterTaxInterest(investAmount: number, currentMonth: number) {
        return this.estimateInterest(investAmount, currentMonth) * (1.00 - this.productInterestTaxRateInNumber);
    }

    // 현재 월에 대한 이자소득세 계산
    private estimateTaxForInterest(investAmount: number, currentMonth: number) {
        return this.estimateInterest(investAmount, currentMonth) * (this.productInterestTaxRateInNumber);
    }


    // 납입기간 전체에 대한 이자 계산
    private estimateInterestAll(interestRate: number, fullRepaymentMonth: number): number {
        let interestAll = 0;
        for (let cur = 0; cur < fullRepaymentMonth; cur++) {
            interestAll = interestAll
                + this.estimateInterest(this.investAmountInput, cur + 1);
        }
        return interestAll;
    }

    // 납입기간 전체에 대한 세후이자 계산
    private estimateAfterTaxInterestAll(fullRepaymentMonth: number): number {
        return this.estimateInterestAll(this.productInterestRateInNumber, fullRepaymentMonth)
            * (1.00 - this.productInterestTaxRateInNumber);
    }

    // 납입기간 전체에 대한 이자소득세 계산
    private estimateTaxForInterestAll(fullRepaymentMonth: number): number {
        return this.estimateInterestAll(this.productInterestRateInNumber, fullRepaymentMonth)
            * (this.productInterestTaxRateInNumber);
    }


    // 예상 총 수익
    private get sumProfit() {
        if (this.product) {
            let repaymentMethodMonth: number = parseInt(this.product.repaymentMethodMonth!, 10);

            if (repaymentMethodMonth) {
                if ( isNaN(repaymentMethodMonth) ) {
                    repaymentMethodMonth = 1;
                }
            }

            if (this.product.isMonthlyReserve) {
                return this.estimateInterestAll(this.productInterestRateInNumber, repaymentMethodMonth);
            }
            return this.investAmount * (this.productInterestRateInNumber) * (repaymentMethodMonth / 12);
        }
        return 0;
    }

    // 예상 총 수익
    private get sumProfitTax() {
        if (this.product) {
            return this.sumProfit * (this.productInterestTaxRateInNumber);
        }
        return 0;
    }

    private get monthlyProfit() {
        if (this.product) {
            let repaymentMethodMonth: number = parseInt(this.product.repaymentMethodMonth!, 10);

            if (repaymentMethodMonth) {
                if ( isNaN(repaymentMethodMonth) ) {
                    repaymentMethodMonth = 1;
                }
            }

            return this.sumProfit / repaymentMethodMonth;
        }
        return 0;
    }

    private get monthlyProfitTax() {
        if (this.product && this.monthlyProfit >= 0) {
            return this.monthlyProfit * (this.productInterestTaxRateInNumber);
        }
        return 0;
    }

    private get isMonthlyReserveProduct(): boolean {
        let isMonthlyReserve = false;
        if (this.product) {
            isMonthlyReserve = !!this.product.isMonthlyReserve;
        }
        return isMonthlyReserve;
    }

    private get depositDateOfMonthForSubmit(): number {
        let depositDateOfMonth = -1;
        if (this.isMonthlyReserveProduct) {
            depositDateOfMonth = this.depositDateOfMonth;
        }
        return depositDateOfMonth;
    }

    private get expiresAtStringWithBraces(): string {
        let result: string = '';
        if (this.product) {
            if (this.product.expiresAt) {
                result = `만기예정일(${moment(this.product.expiresAt).format("YYYY-MM-DD")})`;
            } else if (this.product.repaymentMethodMonth) {
                result = `${this.product.repaymentMethodMonth}개월 후`;
                // let repaymentMethodMonth: number = parseInt(this.product.repaymentMethodMonth!, 10);
                //
                // if (repaymentMethodMonth) {
                //     if ( !isNaN(repaymentMethodMonth) ) {
                //         result = `${repaymentMethodMonth}개월 후`;
                //     }
                // }
            }
        }

        return result;
    }


    private get investAmount(): number {
        if (this.product && this.isMonthlyReserveProduct) {
            return this.investAmountInput * parseInt(this.product.repaymentMethodMonth!, 10);
        }
        return this.investAmountInput;
    }

    private set investAmount(newVal: number) {
        this.investAmountInput = newVal;
    }

    private get investAmountStep(): number {
        // if (this.product) {
        //     if (this.product.minPrice) {
        //         // 상품이 최소 가격을 설정했다면 이걸 사용.
        //         if (this.isMonthlyReserveProduct) {
        //             return Math.round(this.product.minPrice / this.repaymentMethodMonthInNumber); // 적립식 상품: 최소금액 / 개월수
        //         }
        //         return this.product.minPrice;
        //     }
        // }

        if (this.isMonthlyReserveProduct) {
            return 100000; // 적립식 상품: 월 10만원씩
        } else {
            return 1000000; // 거치식 상품: 100만원씩
        }
    }

    private get investAmountMinimum(): number {
        if (this.product) {
            if (this.product.minPrice) {
                // 상품이 최소 가격을 설정했다면 이걸 사용.
                if (this.isMonthlyReserveProduct) {
                    return Math.round(this.product.minPrice / this.repaymentMethodMonthInNumber); // 적립식 상품: 최소금액 / 개월수
                }
                return this.product.minPrice;
            }

            if (this.isMonthlyReserveProduct) {
                // 적립식 상품: 월 10만원부터 = 12개월이면 120만원부터
                return this.investAmountStep * parseInt(this.product.repaymentMethodMonth!, 10);
            }
        }

        return this.investAmountStep; // 일반(거치식) 상품: 100만원부터
    }

    private trimAmountIntoKoreanUnits(amount: number): string {
        let result = '';

        let units = [
            {
                inNumber: 100000000,
                inKorean: '억',
            },
            {
                inNumber: 10000,
                inKorean: '만',
            },
        ]; // 억, 만. 이 둘만 하면 되겠지?

        let remainingAmount = amount;
        for (const unit of units) {
            let numAboveUnit = 0; // 단위 이상
            let numBelowUnit = 0; // 단위 미만
            if (remainingAmount >= unit.inNumber) {
                // 단위 미만 : ex> 억 미만
                numBelowUnit = remainingAmount % unit.inNumber;

                // 단위 이상: ex> 억 단위
                numAboveUnit = remainingAmount - numBelowUnit;
                numAboveUnit = numAboveUnit / unit.inNumber;

                result = `${result} ${numAboveUnit.toLocaleString('ko-kr')}${unit.inKorean}`;

                // 다음 단계로 넘어감.
                remainingAmount = numBelowUnit;
            }
        }

        return result;
    }

    // 동의합니다 표식을 날립니다. 주로 키 입력으로 투자 금액이 변동됐을 때 사용
    private invalidateConsent() {
        this.agreeInput = '';
    }

    // 투자 수행하기
    private async doInvest() {
        if ( ! this.member!.contractible ) {
            EventBus.$emit('toast-notification', new CustomException('계약서 작성에 필요한 정보가 누락되었습니다. 마이페이지 정보수정에서 입력해주세요.'));
            return false;
        }

        if ( ! this.isConsent) {
            EventBus.$emit('toast-notification', new CustomException('투자 조건 및 약관에 동의하지 않으셨습니다.'));
            return false;
        }

        if (this.investProceeding) {
            // 이미 투자 요청이 보내졌습니다. 잠시 기다려주세요
            EventBus.$emit('toast-notification', new InformationNotification('투자 신청이 진행중입니다. 잠시만 기다려주세요.'));
            return false;
        }
        this.investProceeding = true;


        try {
            // 투자 요청 실행!
            let response = await APIClient.instance.loan.investToProduct(
                this.productId, this.investAmount, this.depositDateOfMonthForSubmit);

            if (response.success) {

                this.investState = this.STATE_INVEST_COMPLETED;

                if (response.message === "OK") {
                    // 전액 투자가 된 경우
                } else {
                    // 일부 투자가 된 경우
                    let adjustedAmount: number = parseInt(response.message!, 10);
                    if (adjustedAmount > 0) {
                        this.investAmountInput = adjustedAmount;
                        this.investOverbooked = true;
                    }
                }

            } else {
                EventBus.$emit('toast-notification', new FatalException(
                    MessageHandler.combineMessage("투자를 처리하지 못했습니다.", response)
                ));
            }

        } catch (error) {
            EventBus.$emit('toast-notification', new CustomException(
                MessageHandler.combineMessage('투자를 처리하지 못했습니다.', error)
            ));
        } finally {
            this.investProceeding = false;
        }
    }


    // 금액 변경 수행하기
    private async doModify() {
        if ( ! this.member!.contractible ) {
            EventBus.$emit('toast-notification', new CustomException('계약서 작성에 필요한 정보가 누락되었습니다. 마이페이지 정보수정에서 입력해주세요.'));
            return false;
        }

        if ( ! this.isConsent) {
            EventBus.$emit('toast-notification', new CustomException('투자 조건 및 약관에 동의하지 않으셨습니다.'));
            return false;
        }

        if (this.transaction && this.transaction.amount && this.transaction.amount === this.investAmount) {
            EventBus.$emit('toast-notification', new CustomException('동일 금액으로는 변경하실 수 없습니다.'));
            return false;
        }

        if (this.investProceeding) {
            // 이미 변경 요청이 보내졌습니다. 잠시 기다려주세요
            EventBus.$emit('toast-notification', new InformationNotification('투자금액 변경 신청이 진행중입니다. 잠시만 기다려주세요.'));
            return false;
        }
        this.investProceeding = true;


        try {
            // 변경 요청 실행!
            let response = await APIClient.instance.loan.changeInvest(
                this.transactionId, this.investAmount, this.depositDateOfMonthForSubmit);

            if (response.success) {

                this.investState = this.STATE_INVEST_COMPLETED;

                if (response.message === "OK") {
                    // 전액 투자가 된 경우
                } else {
                    // 일부 투자가 된 경우
                    let adjustedAmount: number = parseInt(response.message!, 10);
                    if (adjustedAmount > 0) {
                        this.investAmountInput = adjustedAmount;
                        this.investOverbooked = true;
                    }
                }

            } else {
                EventBus.$emit('toast-notification', new FatalException(
                    MessageHandler.combineMessage("투자금액 변경을 처리하지 못했습니다.", response)
                ));
            }

        } catch (error) {
            EventBus.$emit('toast-notification', new CustomException(
                MessageHandler.combineMessage('투자금액 변경을 처리하지 못했습니다.', error)
            ));
        } finally {
            this.investProceeding = false;
        }
    }

    private mounted() {
        this.setMetadata();
        this.loadMemberSelf();
        this.loadResources();
    }

    private async loadResources() {
        try {
            await this.loadProduct();

            let lastTxn: InvestmentTransaction | null = null;
            // if (this.product) {
            //     // 2019-12-13 상품 정보 페이지에서 이 동작을 하므로 투자하기 페이지에는 필요 없음.
            //     // 상품정보를 받았으니 해당 상품에 대한 transaction들이 있는지 조회.
            //     this.investTransactions =
            //         await APIClient.instance.mypage.getTransactionsOnProduct(this.product!.index);
            //
            //     if (this.investTransactions) {
            //         // 그중에 맨 마지막 유효 상품을 골라냅니다.
            //         this.investTransactions.forEach(
            //             (txn: InvestmentTransaction, index: number, txnList: InvestmentTransaction[]) => {
            //             if (!txn.confirmedAt && !txn.canceled) {
            //                 // 컨펌받았고, 취소 안된거라면
            //                 // 최근 txn으로 처리합니다.
            //                 lastTxn = txn;
            //             }
            //         });
            //     }
            // }

            if (this.transactionId || lastTxn) {
                await this.loadTransaction(lastTxn);
            }
            this.isReady = true;
        } catch (e) {
            this.failMessage = e;
        }
    }

    private loadMemberSelf() {
        this.$store.dispatch("fetchProfile");
    }

    @Watch('isLoggedIn', {immediate: true})
    private async checkLoggedIn() {
        if ( ! this.isLoggedIn ) {
            // 로그인 창을 띄우도록 합니다.
            this.showLoginPopup();
        }
    }

    private async loadProduct() {
        try {
            this.product = await APIClient.instance.product.get(this.productId);

            this.setMetadata();
        } catch (e) {
            EventBus.$emit('toast-notification', new CustomException(
                MessageHandler.combineMessage(this.productId + "번 상품을 가져올 수 없습니다", e)
            ));

            // 호출 메소드로 전달
            throw e;
        }
    }

    private async loadTransaction(lastTxn: InvestmentTransaction | null) {
        try {
            if (lastTxn) {
                // 제공받은 transaction이 있습니다. 이를 사용합니다.
                this.transaction = lastTxn;
                this.transactionId = lastTxn.index;
            } else {
                // 없습니다 조회해 옵니다.
                this.transaction = await APIClient.instance.mypage.getMyTransaction(this.transactionId);
            }

            this.product = this.transaction.product!;

            if (this.isMonthlyReserveProduct) {
                // 적립식 상품 변경 금지해야함!!!
                EventBus.$emit('toast-notification',
                    new CustomException("적립식 상품의 금액 변경은 허용되지 않습니다."));

                this.$router.replace(`/products/${this.product.index}`);
            }

            // 투자 내용 변경하기에서는 미리 투자 금액을 채워줍니다.
            this.investAmountInput = this.transaction.amount!;

            this.setMetadata();
        } catch (e) {
            EventBus.$emit('toast-notification', new CustomException(
                MessageHandler.combineMessage("요청한 투자내역을 가져올 수 없습니다", e)
            ));

            // 호출 메소드로 전달
            throw e;
        }
    }

    // 계약서 확인하기 버튼을 눌렀을 때
    private checkAgreement() {
        if ( ! this.member!.contractible ) {
            EventBus.$emit('toast-notification', new CustomException('계약서 작성에 필요한 정보가 누락되었습니다. 마이페이지 정보수정에서 입력해주세요.'));

            // 마이페이지로 이동
            this.$router.push("/mypage/personal");

            return false;
        }

        if (this.transaction && this.transaction.amount && this.transaction.amount === this.investAmount) {
            EventBus.$emit('toast-notification', new CustomException('동일 금액으로는 변경하실 수 없습니다.'));
            return false;
        }

        if ( this.investAmount < this.investAmountMinimum) {

            let message = `최저 투자금액은 ${this.trimAmountIntoKoreanUnits(this.investAmountMinimum)}원입니다. 투자금액을 다시 확인해주세요.`;

            if (this.isMonthlyReserveProduct) {
                // 월간 적립식 상품
                message = `만기까지 총 투자금액이 ${this.trimAmountIntoKoreanUnits(this.investAmountMinimum)}원 미만입니다. 만기까지 총 투자금액을 다시 확인해주세요.`;
            }

            EventBus.$emit('toast-notification', new CustomException(message));

            return false;
        }

        this.agreementPdfSrc = `/_api/product/${this.product!.index}/contract-preview?amount=${this.investAmount}&depositDateOfMonth=${this.depositDateOfMonthForSubmit}`;
        this.investState = this.STATE_INVEST_AGREEMENT;
    }

    /**
     * 약관 창을 엽니다.
     * @param whatToOpen 열어야할 약관 파일 이름
     */
    private openTerms(whatToOpen: string) {
        try {
            window.self.postMessage(`termsWinOpen:${whatToOpen}`, location.origin);
        } catch (error) {
            ((window.self) as any).__openTermsWindow(whatToOpen);
        }
    }

    private setMetadata() {

        let meta: Metadata;

        let pageTitle: string = '상품보기';
        // let desc: string = '빌드온파트너스대부 상품에 투자합니다.';

        if (this.product) {
            // 상품이 로드되었을 때는 상품 정보를 보이게 합니다.
            pageTitle = `상품투자: ${this.product.title}`;
            // desc = `빌드온파트너스대부 투자상품 ${this.product.title} 에 투자합니다.`;

            // 투자 상품 페이지에는 개요를 넣지 않습니다.

            if (this.transaction) {
                // mounted에서 await으로 대기하고 순서대로 불러오므로 괜찮습니다.
                pageTitle = '투자금액 변경하기';

                if ( this.transaction.product ) {
                    pageTitle = pageTitle + `: ${this.transaction.product.title}`;
                }
            }
        }

        meta = {
            title: pageTitle,
            // description: desc
        };
        EventBus.$emit("meta-title", meta);
    }

    @Watch('investState')
    private handleInvestStateChange() {
        // investState 변경시 scrollTop 0 으로 설정 = 맨 위로
        document.querySelector("html")!.scrollTop = 0;
    }
}
