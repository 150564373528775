
























































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import CenterWrapper from '@/components/CenterWrapper.vue';
import {APIClient} from "@/api/client/client";

import { EventBus } from '@/lib/event-bus';
import { FatalException } from '@/api/client/notification';
import {MessageHandler} from "@/api/client/error";
import {Product} from "@/api/product";
import {InvestmentTransaction, LoanInterestRecord, LoanRecord} from "@/api/transaction";

@Component({
    components: {
        CenterWrapper,
    },
})
export default class InterestsByMonthBox extends Vue {

    private isResourceReady: boolean = false;

    private totalInterestRangeMonth: Date = new Date();

    private totalTotalInterestTxnList: Array<(LoanRecord | LoanInterestRecord)> = [];

    private readonly MILLISECONDS_ONE_DAY = 24 * 60 * 60 * 1000;

    /** 월별 총 지급액 (원금) */
    get totalMonthlyAmount() {
        return this.totalLoanRecordsListPaid.reduce((sum: number, txn: any) => {
            if (txn.type === 'loan_record') {
                return sum + txn.amount;
            }
            return sum;
        }, 0);
    }

    /** 월별 총 지급액 (이자) */
    get totalMonthlyInterestAmount() {
        return this.totalInterestRecordsListPaid.reduce((sum: number, txn: any) => {
            if (txn.type === 'interest_record') {
                return sum + txn.amount;
            }
            return sum;
        }, 0);
    }

    /** 월별 총 지급액 (세금) */
    get totalMonthlyTaxAmount() {
        return this.totalInterestRecordsListPaid.reduce((sum: number, txn: any) => {
            if (txn.type === 'interest_record') {
                return sum + txn.taxAmount;
            }
            return sum;
        }, 0);
    }

    get totalLoanRecordsList(): LoanRecord[] {
        return this.totalRecordsListAll.filter(
            (record) => {
                return record.type === "loan_record";
            }
        ).map(record => record as LoanRecord);
    }

    get totalLoanRecordsListPaid() {
        return this.totalLoanRecordsList.filter(
            (record) => {
                return record.isPaid;
            }
        );
    }

    get totalInterestRecordsList(): LoanInterestRecord[] {
        return this.totalRecordsListAll.filter(
            (record) => {
                return record.type === "interest_record";
            }
        ).map(record => record as LoanInterestRecord);
    }

    get totalInterestRecordsListPaid() {
        return this.totalInterestRecordsList.filter(
            (record) => {
                return record.isPaid;
            }
        );
    }

    get totalRecordsListAll() {
        return this.totalTotalInterestTxnList;
    }

    async fetchTotalInterestTxnList() {
        const rangeMonth = this.totalInterestRangeMonth;
        this.isResourceReady = false;

        try {
            this.totalTotalInterestTxnList
                = await APIClient.instance.mypage.getRecordsOnProduct(
                    "-1",
                    rangeMonth.getFullYear(), rangeMonth.getMonth() + 1, false);

            this.isResourceReady = true;
        } catch (e) {
            // 오류인 경우
            EventBus.$emit('toast-notification', new FatalException(
                MessageHandler.combineMessage('정보를 불러오는데 실패했습니다.', e)
            ));
            this.isResourceReady = true;
            throw e;
        }
    }

    /**
     * 주어진 달의 첫 날과 마지막 날을 구합니다.
     */
    getMonthFirstLastDateAsPair(date: Date) {
        let [firstDate, lastDate] = [new Date(date), new Date(date)];
        firstDate.setDate(1);
        lastDate.setMonth(lastDate.getMonth() + 1);
        lastDate.setDate(0);

        return [firstDate, lastDate];
    }
    prevMonth() {
        this.totalInterestRangeMonth = new Date(this.totalInterestRangeMonth.setMonth(
            this.totalInterestRangeMonth.getMonth() - 1
        ));
    }
    nextMonth() {
        // 월간 이자 보기 미래로도 당길 수 있도록 함.
        // let now = new Date();
        // let thisMonth: number = now.getFullYear() * 100 + now.getMonth();
        // if (thisMonth <=
        //     (this.totalInterestRangeMonth.getFullYear() * 100 + this.totalInterestRangeMonth.getMonth()) ) {
        //     // 오늘의 년월 보다 현재 선택된 년월이 같거나 크면 다음 달로 넘어갈 수 없습니다.
        //     return false;
        // }

        this.totalInterestRangeMonth = new Date(this.totalInterestRangeMonth.setMonth(
            this.totalInterestRangeMonth.getMonth() + 1
        ));
    }

    @Watch('totalInterestRangeMonth', { immediate: true })
    onTotalInterestRangeMonthChanged() {
        this.fetchTotalInterestTxnList();
    }

    calcInvestedDays(product: Product): number {
        if (!!product && product.executionDate) {
            // 현재 선택된 월을 추출
            let dateThatDay = new Date(this.totalInterestRangeMonth);

            let currentDate = new Date();
            if (dateThatDay < currentDate) {
                // TODO: need to something but don't remember ;;
            }

            if (!!product.expiresAt) {

                if (product.state === "completed") {
                    // 상환 완료
                    // 만기일이 되어있다면 대출 실행일과의 차이를 구함
                    return product.expiresAt - product.executionDate;
                }

                if (product.state === "repaying") {
                    // 상환중
                    let currentDateMillis = currentDate.getTime();
                    // 오늘 날짜로부터 대출 실행일과의 차이를 구함
                    return currentDateMillis - product.executionDate;
                }

            }
        }

        return -1;
    }

    calcInvestedDaysForTxnExecSeparately(txnExecSeparately: InvestmentTransaction): number {
        if (!!txnExecSeparately && txnExecSeparately.executionDate) {
            // 현재 선택된 월을 추출
            let dateThatDay = new Date(this.totalInterestRangeMonth);

            let currentDate = new Date();
            if (dateThatDay < currentDate) {
                // TODO: need to something but don't remember ;;
            }

            if (!!txnExecSeparately.product && txnExecSeparately.product.expiresAt) {

                if (txnExecSeparately.product.state === "completed") {
                    // 상환 완료
                    // 만기일이 되어있다면 대출 실행일과의 차이를 구함
                    return txnExecSeparately.product.expiresAt - txnExecSeparately.executionDate;
                }

                if (
                    txnExecSeparately.product.state === "funding"
                    || txnExecSeparately.product.state === "funded"
                    || txnExecSeparately.product.state === "repaying"
                ) {
                    // 모집중/모집완료/상환중 모두 검사해야함 왜냐면 이 때도 단건대출은 가능하니까.
                    let currentDateMillis = currentDate.getTime();
                    // 오늘 날짜로부터 대출 실행일과의 차이를 구함
                    return currentDateMillis - txnExecSeparately.executionDate;
                }
            }
        }

        return -1;
    }

    // 총 투자일수를 계산해 가져오는 메소드. 단 '일' 단위를 붙임
    getInvestedDaysFromLoanRecord(loanRecord: any): string {
        let result = -1;
        if (loanRecord.transactionExecSeparately) {
            result = this.calcInvestedDaysForTxnExecSeparately(loanRecord.transactionExecSeparately);
        } else {
            result = this.calcInvestedDays(loanRecord.product);
        }

        // 계산불가
        if (result < 0) {
            return '-';
        } else {
            return `${(result / this.MILLISECONDS_ONE_DAY).toFixed(0)}일`;
        }
    }

    accumulatePayoutInterestforDisplay(maxIndex: number): number {
        let sum: number = 0;

        for (let cur: number = 0; cur <= maxIndex; cur++) {
            if (
                this.totalRecordsListAll[cur].type === "interest_record"
                && this.totalRecordsListAll[cur].isPaid
            ) {
                sum = sum + ( this.totalRecordsListAll[cur].amount
                    - (this.totalRecordsListAll[cur] as LoanInterestRecord).taxAmount );
            }
        }
        return sum;
    }
}
