




















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

import CenterWrapper from '@/components/CenterWrapper.vue';
import Axios from "axios";
import {FatalException} from "@/api/client/notification";
import {MessageHandler} from "@/api/client/error";
import {EventBus} from "@/lib/event-bus";

@Component({
    components: {
        CenterWrapper,
    },
})
export default class Terms extends Vue {

    @Prop({ required: true })
    private policy!: string;

    private filteredPolicy: string = '';

    private staticHtml: string = '';

    mounted() {
        this.checkValidScope();

        this.loadHtml();
    }


    /**
     *  약관 문서가 있는 것만 로드하도록 허용하고 나머지는
     *  서비스 이용약관으로 리디렉션 됩니다.
     */
    @Watch('policy')
    checkValidScope() {
        switch (this.policy) {
            case 'service':
            case 'loan':
            case 'invest':
            case 'privacy':
                this.filteredPolicy = this.policy;
                break;
            default:
                this.filteredPolicy = 'service';
                break;
        }
    }

    // 파일을 읽어와 staticHtml 객체에 넣습니다.
    @Watch('filteredPolicy')
    async loadHtml() {
        try {
            const response = await Axios.get(`/assets/terms/${this.filteredPolicy}.html`);
            this.staticHtml = response.data;
        } catch (error) {
            EventBus.$emit('toast-notification', new FatalException(
                MessageHandler.combineMessage('약관을 불러오지 못했습니다.', error)
            ));
        }
    }

    get canGoBack() {
        // 이번 페이지 말고 다른페이지가 더 있으면 못가는걸로 생각해보자.
        return history.length >= 2;
    }

    goBack() {
        this.$router.back();
    }

    closeWindow() {
        try {
            window.opener.postMessage('termsWinClose:default');
        } catch (error) {
            window.opener.__closeTermsWindow();
        }
    }
}
