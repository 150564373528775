
































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CenterWrapper from '@/components/CenterWrapper.vue';
import moment from 'moment';
import {APIClient} from "@/api/client/client";
import {Product} from "@/api/product";
import {Member} from "@/api/member";

import { EventBus } from "@/lib/event-bus";

import { CustomException } from "@/api/client/notification";
import {MessageHandler} from "@/api/client/error";
import {Transaction} from "@sentry/integrations";
import {InvestmentTransaction, LoanRecord} from "@/api/transaction";
import PdfViewer from "@/components/PdfViewer.vue";
import InvestmentList from "@/components/mypage/InvestmentList.vue";

@Component({
    components: {
        InvestmentList,
        CenterWrapper,
        PdfViewer
    },
})
export default class InvestBox extends Vue {

    private isResourceReady: boolean = false;
    private investTransactions: InvestmentTransaction[] = [];
    private loanRecords: LoanRecord[] = [];

    mounted() {
        this.__initialize();
    }

    async __initialize() {
        this.isResourceReady = false;
        await this.fetchFundingInvestmentList();
        this.isResourceReady = true;
    }

    async fetchFundingInvestmentList() {
        this.investTransactions = [];
        try {
            this.investTransactions =
                await APIClient.instance.mypage.getTransactionsThatAreFundingConsolidate();
            this.loanRecords =
                await APIClient.instance.mypage.getLoanRecordsThatAreFunding();
        } catch (error) {
            EventBus.$emit('toast-notification', new CustomException(
                MessageHandler.combineMessage('회원의 펀딩중 투자내역을 불러오지 못했습니다.', error)
            ));
        }

    }

}
