



















































import { Component, Prop, Vue } from 'vue-property-decorator';

import { Product } from '@/api/product';
import {APIClient} from "@/api/client/client";


@Component
export default class ProductTile extends Vue {
    @Prop({ default: false })
    private isFinalized!: boolean;
    @Prop({ required: true })
    private product!: Product;

    /** 파일 존재여부 체크를 해야하면 false, 존재여부가 부존재라면 true */
    private requiresFallback: boolean = false;
    /** 기본 이미지로 우회합니다. */
    private readonly URL_IMG_FALLBACK: string = "../assets/product-list/no-image.png";

    // 12345.678.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') 는 12,345.67 숫자 형식 고정이 된다.
    // https://stackoverflow.com/a/14428340

    private get isProductActive() {
        switch (this.product.state) {
            case 'in-review':
            case 'preparing':
                return false;
            case 'funding':
                return true;
            case 'funded':
            case 'repaying':
            case 'completed':
            case 'canceled':
            case 'failed':
                return false;
            default:
                return false;
        }
    }

    private get isProductPrepared() {
        switch (this.product.state) {
            case 'in-review':
            case 'preparing':
                return true;
            case 'funding':
                return false;
            case 'funded':
            case 'repaying':
            case 'completed':
            case 'canceled':
            case 'failed':
                return false;
            default:
                return false;
        }
    }

    private get isProductInactive() {
        switch (this.product.state) {
            case 'in-review':
            case 'preparing':
                return false;
            case 'funding':
                return false;
            case 'funded':
            case 'repaying':
            case 'completed':
            case 'canceled':
            case 'failed':
                return true;
            default:
                return false;
        }
    }

    // 이미지 주소를 1개 이상 얻을 수 있을때 맨 첫 이미지 한 장만 가져옵니다.
    private get firstImage(): string {
        if (this.product) {
            if ( this.product.productImages && this.product.productImages.length > 0 ) {

                // 그와 동시에 주소 반환
                if ( ! this.requiresFallback) {
                    // 파일 존재 여부를 게으르게 확인하도록 함
                    this.lazyCheckImgExists(this.product.productImages[0].rawFilename);

                    return this.product.productImages[0].url;
                }
            }
        }

        return this.URL_IMG_FALLBACK;
    }

    private async lazyCheckImgExists(rawFilename: string) {
        try {
            let status = await APIClient.instance.attachment.check(rawFilename);

            if ( status.toString() === 'presents') {
                // 사진을 읽을 수 있습니다.
                this.requiresFallback = false;
            } else {
                // 사진을 읽을 수 없습니다.
                this.requiresFallback = true;
            }
        } catch (e) {
            // 사진의 존재 여부를 확인하는 도중 오류가 발생했습니다.
            this.requiresFallback = true;
        }

    }


    /**
     * 남은 금액을 0 이상으로만 보이게 조치된 "목표금액 - 투자금액"
     */
    private get remainingInvestAmount(): number {
        if (this.product) {
            let target: number = 0;

            if (this.product.totalInvestment) {
                // 목표 투자액
                target = this.product.totalInvestment;
            }

            // 현재 투자액
            let current: number = this.product.currentInvestmentAmount;

            // 남은 금액
            let remaining = target - current;

            if (remaining > 0) {
                // 남은 금액이 0보다 클 때만 남은 금액 반환
                return remaining;
            }
        }
        return 0;
    }
}
