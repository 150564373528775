







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CenterWrapper extends Vue {
    @Prop({ default: '1000px' })
    private width!: string;
}
