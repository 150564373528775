





import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AmountInput extends Vue {
    @Prop({ required: true })
    private value!: number;

    get readableString(): string {
        return this.toReadableString(Math.abs(this.value));
    }

    toReadableString(value: number, isSubUnit: boolean = false): string {
        const numbers = ['영', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
        const unitMap = new Map<number, string>([
            [1_000_000_000_000, '조 '],
            [100_000_000, '억 '],
            [10_000, '만 '],
            [1_000, '천 '],
            [100, '백 '],
            [10, '십 '],
        ]);

        if (value < 10) {
            return numbers[value];
        }

        let str: string = '';

        for (const [unit, name] of unitMap) {
            if (value >= unit) {
                str += this.toReadableString(Math.floor(value / unit), true);
                str += name;
                if (value % unit > 0) {
                    str += this.toReadableString(value % unit);
                }
                break;
            }
        }
        return str;
    }
}
