













import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {Attachment} from "@/api/attachment";
import {APIClient} from "@/api/client/client";

@Component
export default class DescPreview extends Vue {

    /** ************************** Props ************************** */

    /** 첨부파일 하나, 필수 */
    @Prop({ required: true })
    public file!: Attachment;

    private imgLoaded: boolean = false;

    private fileRetrievable: boolean = true;


    private mounted() {
        this.checkFileIsRetrieval();
    }

    /**
     * 이 파일이 서버에서 읽을 수 있는지를 반환합니다.
     */
    @Watch('file')
    private async checkFileIsRetrieval() {
        try {
            let status = await APIClient.instance.attachment.check(this.file.rawFilename);
            if ( status.toString() === 'presents') {
                this.fileRetrievable = true;
            } else {
                this.fileRetrievable = false;
            }
        } catch (e) {
            this.fileRetrievable = false;
        }
    }

    /**
     * 구글 문서 뷰어에 제출할 주소를 가져옵니다.
     */
    private get urlEncoded(): string {
        if (this.attachmentEndPoint) {
            let protocol = window.location.protocol;
            let slashes = "//";
            let host = window.location.hostname;

            return encodeURI(protocol + slashes + host + this.attachmentEndPoint);
        }
        return "";
    }

    /**
     * 첨부파일주소를 가져옵니다.
     */
    private get attachmentEndPoint(): string {
        if (this.file) {
            return "/_api/attachment/" + this.file.rawFilename;
        }
        return "";
    }

    /**
     * 구글 문서도구를 사용할지 결정합니다.
     */
    private get canGoogleHandleThis(): boolean {
        let isSupported: boolean = false;

        if (this.file) {
            switch (this.file.ext) {
                case "pdf":
                case "doc":
                case "xls":
                case "xlsx":
                case "ppt":
                case "pptx":
                    isSupported = true;
            }
        }

        return isSupported;
    }

    private get isImage(): boolean {
        let isImage: boolean = false;

        if (this.file) {
            switch (this.file.ext) {
                case "gif":
                case "jpg":
                case "jpeg":
                case "png":
                    isImage = true;
            }
        }

        return isImage;
    }

    private onContentLoaded() {
        this.imgLoaded = true;
    }
}
