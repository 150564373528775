
















































































































































import {Component, Prop, Vue, Watch, Mixins} from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import { EventBus } from '@/lib/event-bus';
import DigitsOnly from '@/mixins/DigitsOnly';

import Fullscreen from '@/components/Fullscreen.vue';
import APIClient from '@/api/client';
import {
    CustomException,
    SuccessNotification,
    FatalException, InformationNotification
} from '@/api/client/notification';
import {FindPasswordForm, FindUsernameCodeForm, FindUsernameForm, LoginForm, NiceDiPrepareVal} from '@/api/in/login';
import {MessageHandler} from "@/api/client/error";
import StoreHelper from '@/mixins/StoreHelper';

@Component({
    components: {
        Fullscreen,
    },
})
export default class LoginPopup extends Mixins<StoreHelper, DigitsOnly>(StoreHelper, DigitsOnly) {
    // public readonly recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_KEY!;

    public readonly VIEW_LOGIN = 'loginMode';
    public readonly VIEW_ID_SEARCH = 'idSearch';
    public readonly VIEW_ID_SEARCH_STEP_02 = 'idSearch_step2';
    public readonly VIEW_ID_SEARCH_STEP_03 = 'idSearch_step3';
    public readonly VIEW_PW_SEARCH = 'pwSearch';

    public currentView: string = this.VIEW_LOGIN;

    public loginForm: LoginForm = {
        username: '',
        password: '',
        responseToken: null,
    };

    // 아이디 검색시 필요한 정보 입력 폼
    public searchIdForm: FindUsernameForm = {
        responseToken: null,
        tel: '',
        niceDI: null,
    };

    // 전화번호 인증시 인증 코드 폼
    public searchIdCodeForm: FindUsernameCodeForm = {
        responseToken: null,
        memberIdx: -1,
        code: '',
    };

    // NICE 팝업 실행시 필요
    public niceEncodeData: string = '';
    public niceEncData: string | null = null;

    // 아이디 찾기 결과
    public searchIdResult: string | null = null;

    // 비밀번호 찾기에 필요한 이메일 입력 폼
    public searchPwForm: FindPasswordForm = {
        responseToken: null,
        email: '',
    };

    private keepLoginOn: boolean = false;

    /* 전송 중일 때는 버튼이 눌리지 않게 합니다. */
    private transferInProgress: boolean = false;

    get idResetRequestable() {
        return this.searchIdForm.tel && ! this.transferInProgress;
    }

    get codeInputable() {
        return this.searchIdCodeForm.code && ! this.transferInProgress;
    }

    get emailResetRequestable() {
        return this.searchPwForm.email && ! this.transferInProgress;
    }



    /**
     * 팝업 닫습니다
     */
    closePopup() {
        this.$store.commit('setLoginPopupVisible', false);
    }
    //
    // onRecaptchaVerified(response: string) {
    //     this.loginForm.responseToken = response;
    // }
    //
    // onRecaptchaExpired() {
    //     this.loginForm.responseToken = null;
    // }
    //
    // onIdSearchRecaptchaVerified(response: string) {
    //     this.searchIdForm.responseToken = response;
    // }
    //
    // onIdSearchRecaptchaExpired() {
    //     this.searchIdForm.responseToken = null;
    // }
    //
    // onIdSearchCodeRecaptchaVerified(response: string) {
    //     this.searchIdCodeForm.responseToken = response;
    // }
    //
    // onIdSearchCodeRecaptchaExpired() {
    //     this.searchIdCodeForm.responseToken = null;
    // }
    //
    // onPwSearchRecaptchaVerified(response: string) {
    //     this.searchPwForm.responseToken = response;
    // }
    //
    // onPwSearchRecaptchaExpired() {
    //     this.searchPwForm.responseToken = null;
    // }

    doNothing() {
        // 아무것도 하지 않습니다.
        return ;
    }

    @Watch('currentView')
    onCurrentViewChanged() {
        // 아이디 찾기 본인인증을 진입했을 때 NICE값을 준비합니다.
        if (this.currentView === this.VIEW_ID_SEARCH) {
            this.prepareNiceEncData();
        }
    }

    /**
     * 서버로부터 NiceEncData 가져오기
     */
    async prepareNiceEncData() {
        let niceDiPrepareVal: NiceDiPrepareVal = await APIClient.instance.signup.prepareNiceDi();

        this.niceEncodeData = niceDiPrepareVal.sencData;
    }

    openNicePopup() {
        // if ( ! this.searchIdForm.responseToken) {
        //     EventBus.$emit('toast-notification',
        //         new CustomException("보안을 위해 로봇 방지 체크를 진행해 주십시오."));
        //
        //     return false;
        // }


        window.open(
            '',
            'popupChk',
            ('width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, ' +
                'status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no')
        );
        // HTMLFormElement로 nicePopupPersonalForm을 불러들여 popupChk 창으로 제출, 본인인증창을 띄웁니다.
        let nicePopup: HTMLFormElement = this.$refs.nicePopupIdSearchForm as HTMLFormElement;
        nicePopup.setAttribute("target", "popupChk");
        nicePopup.setAttribute("action", "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb");
        nicePopup.submit();
    }

    @Watch('niceEncData')
    async onNiceEncDataChanged() {
        const response = await APIClient.instance.signup.decodeNiceEncodedData(this.niceEncData!);

        this.handleIdSearchIdentityVerify(response.sdupInfo);
    }

    /** 닫기/이전 버튼 처리 */
    handleBackBtn() {
        if (this.currentView !== this.VIEW_LOGIN) {
            this.currentView = this.VIEW_LOGIN;
        } else {
            this.closePopup();
        }
    }

    /**
     * 로그인 핸들합니다
     */
    async handleLogin() {
        // if (!this.loginForm.responseToken) {
        //     EventBus.$emit('toast-notification',
        //         new CustomException("보안을 위해 로봇 방지 체크를 진행해 주십시오."));
        //     return;
        // }

        EventBus.$emit('toast-notification', new InformationNotification('잠시만 기다려주세요...'));

        const client = APIClient.instance;
        try {
            await client.login.login(this.loginForm);
            this.$store.dispatch("fetchProfile");
        } catch (e) {
            EventBus.$emit('toast-notification', new CustomException(
                MessageHandler.combineMessage("로그인에 실패하였습니다.", e)
            ));
            // (this.$refs.recaptcha! as VueRecaptcha).reset();
            return;
        }
    }

    @Watch('isLoggedIn')
    checkLoggedInStatus() {
        // 로그인 여부만 확인하고 창 닫기
        if (this.isLoggedIn) {
            EventBus.$emit('toast-notification', new SuccessNotification('로그인에 성공하였습니다.'));
            this.closePopup();
        }
    }

    /** 휴대전화로 인증번호 문자 발송을 요청 */
    async handleIdSearchPhoneVerify() {
        // if (!this.searchIdForm.responseToken) {
        //     EventBus.$emit('toast-notification',
        //         new CustomException("보안을 위해 로봇 방지 체크를 진행해 주십시오."));
        //     return;
        // }

        this.transferInProgress = true;

        try {
            let response = await APIClient.instance.login.findUsernameViaPhone(this.searchIdForm);

            if (response != null && response.success != null) {
                if (response.success) {
                    // 본인인증에 성공했고 idx 추출에 성공했습니다.
                    this.searchIdCodeForm.memberIdx = Number(response.message);

                    this.currentView = this.VIEW_ID_SEARCH_STEP_02;
                } else {
                    EventBus.$emit('toast-notification', new CustomException(
                        MessageHandler.combineMessage("인증번호 전송에 실패하였습니다. 다시 시도해주세요.", response)
                    ));
                    // (this.$refs.recaptchaIdSearch! as VueRecaptcha).reset();
                }
            } else {
                EventBus.$emit('toast-notification', new CustomException(
                    MessageHandler.combineMessage("인증번호 전송에 실패하였습니다. 다시 시도해주세요.", response)
                ));
                // (this.$refs.recaptchaIdSearch! as VueRecaptcha).reset();
            }

        } catch (error) {
            // 다른 예외가 발생한 경우
            EventBus.$emit('toast-notification', new CustomException(
                MessageHandler.combineMessage("인증번호 전송에 실패하였습니다. 다시 시도해주세요.", error)
            ));
            // (this.$refs.recaptchaIdSearch! as VueRecaptcha).reset();
        }

        this.transferInProgress = false;
    }

    /** NICE 본인인증 창의 결과를 수신합니다. */
    async handleIdSearchIdentityVerify(diVal: string) {
        this.searchIdForm.niceDI = diVal;
        try {
            let response = await APIClient.instance.login.findUsername(this.searchIdForm);

            if (response != null && response.success != null) {
                if (response.success) {
                    // 본인인증에 성공했고 id 추출에 성공했습니다.
                    this.onSearchIdResult(response.message);
                } else {
                    // 아이디가 존재하지 않습니다. 실패 화면으로 넘깁니다.
                    this.onSearchIdResult('');
                }
            } else {
                EventBus.$emit('toast-notification', new FatalException(
                    MessageHandler.combineMessage("아이디 찾기에서 본인인증에 실패하였습니다.", response)
                ));
                // (this.$refs.recaptchaIdSearch! as VueRecaptcha).reset();
            }

        } catch (error) {
            // 다른 예외가 발생한 경우
            EventBus.$emit('toast-notification', new FatalException(
                MessageHandler.combineMessage("아이디 찾기에서 본인인증에 실패하였습니다.", error)
            ));
            // (this.$refs.recaptchaIdSearch! as VueRecaptcha).reset();
        }
    }

    /** 휴대전화 인증번호 입력을 처리해야 합니다. */
    async handleIdSearchIdentityCode() {
        // if (!this.searchIdCodeForm.responseToken) {
        //     EventBus.$emit('toast-notification',
        //         new CustomException("보안을 위해 로봇 방지 체크를 진행해 주십시오."));
        //     return;
        // }

        this.transferInProgress = true;

        try {
            let response = await APIClient.instance.login.findUsernameViaPhoneCode(this.searchIdCodeForm);

            if (response != null && response.success != null) {
                if (response.success) {
                    // 본인인증에 성공했고 id 추출에 성공했습니다.
                    this.onSearchIdResult(response.message);
                } else {
                    // 인증코드가 일치하지 않습니다.
                    EventBus.$emit('toast-notification', new FatalException(
                        MessageHandler.combineMessage("아이디 찾기에서 인증코드 처리에 실패하였습니다.", response)
                    ));
                    // (this.$refs.recaptchaIdSearchCode! as VueRecaptcha).reset();
                }
            } else {
                EventBus.$emit('toast-notification', new FatalException(
                    MessageHandler.combineMessage("아이디 찾기에서 인증코드 처리에 실패하였습니다.", response)
                ));
                // (this.$refs.recaptchaIdSearchCode! as VueRecaptcha).reset();
            }

        } catch (error) {
            // 다른 예외가 발생한 경우
            EventBus.$emit('toast-notification', new FatalException(
                MessageHandler.combineMessage("아이디 찾기에서 인증코드 처리에 실패하였습니다.", error)
            ));
            // (this.$refs.recaptchaIdSearchCode! as VueRecaptcha).reset();
        }


        this.transferInProgress = false;
    }

    /** 이메일 요청 */
    async handlePwSearchEmailSubmit() {
        // if (!this.searchPwForm.responseToken) {
        //     EventBus.$emit('toast-notification',
        //         new CustomException("보안을 위해 로봇 방지 체크를 진행해 주십시오."));
        //     return;
        // }

        this.transferInProgress = true;

        try {
            let response = await APIClient.instance.login.requestPasswordReset(this.searchPwForm);

            if (response != null && response.success != null ) {
                if (response.success) {
                    EventBus.$emit('toast-notification', new SuccessNotification(
                        "비밀번호 재설정 요청에 성공하였습니다. 이메일함을 확인해주세요."));
                } else {
                    EventBus.$emit('toast-notification', new FatalException(
                        MessageHandler.combineMessage("비밀번호 재설정 요청에 실패하였습니다.", response)
                    ));
                    // (this.$refs.recaptchaPwSearch! as VueRecaptcha).reset();
                }
            } else {
                EventBus.$emit('toast-notification', new FatalException(
                    MessageHandler.combineMessage("비밀번호 재설정 요청에 실패하였습니다.", response)
                ));
                // (this.$refs.recaptchaPwSearch! as VueRecaptcha).reset();
            }
        } catch (error) {
            EventBus.$emit('toast-notification', new FatalException(
                MessageHandler.combineMessage("비밀번호 재설정 요청에 실패하였습니다.", error)
            ));
            // (this.$refs.recaptchaPwSearch! as VueRecaptcha).reset();
        }

        this.transferInProgress = false;
    }

    /**
     * 아이디 찾기가 완료되면 찾은 ID를 결과 창은 물론 로그인 창에도 넣어줍니다.
     */
    onSearchIdResult(foundId: string) {
        this.searchIdResult = foundId;
        this.loginForm.username = foundId;
        this.currentView = this.VIEW_ID_SEARCH_STEP_03;
    }


    /**
     * 가상계좌 계좌번호를 클립보드로 복사합니다.
     */
    copyIdSearchResultToClipboard() {
        let idSearchResult = this.$refs['copy-clipboard-idsearch-result'] as HTMLInputElement;
        idSearchResult.setAttribute('type', 'text');    // hidden은 선택 불가?
        idSearchResult.select();

        try {
            let successful = document.execCommand('copy');
            if (successful) {
                EventBus.$emit('toast-notification',
                    new SuccessNotification("아이디가 클립번호에 복사되었습니다."));
            } else {
                EventBus.$emit('toast-notification',
                    new FatalException("아이디를 복사하지 못했습니다."));
            }
        } catch (err) {
            EventBus.$emit('toast-notification', new FatalException(
                MessageHandler.combineMessage("아이디를 복사하지 못했습니다.", err)
            ));
        }

        /* 선택 취소 */
        idSearchResult.setAttribute('type', 'hidden');

        let selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
    }



    onWindowMessage(event: MessageEvent) {
        this.onWindowMessageIE(event.data.toString());
    }

    onWindowMessageIE(message: string) {
        const [type, data] = message.split(":");
        if (type === "NICE") {
            this.niceEncData = data;
        }
    }

    // 마운트 되었을 때 호출
    mounted() {
        window.addEventListener('message', this.onWindowMessage);
        (window as any).__onWindowMessageIE = this.onWindowMessageIE; // IE 대응
    }

    destroyed() {
        window.removeEventListener('message', this.onWindowMessage);
        delete (window as any).__onWindowMessageIE; // 객체 삭제는 delete 로
    }
}

