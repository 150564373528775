




































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import CenterWrapper from '@/components/CenterWrapper.vue';
import moment from 'moment';
import {APIClient} from "@/api/client/client";
import Chart from "chart.js";

import { EventBus } from '@/lib/event-bus';
import { FatalException } from '@/api/client/notification';
import {MessageHandler} from "@/api/client/error";
import {LoanInterestRecord, LoanRecord} from "@/api/transaction";

@Component({
    components: {
        CenterWrapper,
    },
})
export default class GraphBox extends Vue {

    /** 그래프 객체 */
    private yearTrendChart: Chart | null = null;

    /** 그래프 표시 실패했을 때 사용 */
    private isGraphFailure: boolean = false;
    private graphFailureReason: string = '';

    /** 그래프 숨김 상태 */
    private isGraphHidden: boolean = true;
    private isResourceReady: boolean = false;

    private totalInterestRangeMonth: Date = new Date();

    private totalInterestTxnList: Array<(LoanRecord | LoanInterestRecord)> = [];

    /** 월별 총 지급액 (원금) */
    get totalMonthlyAmount() {
        return this.totalInterestTxnList.reduce((sum: number, txn: any) => {
                if (txn.type === 'loan_record') {
                    return sum + txn.amount;
                }
                return sum;
            }, 0);
    }

    /** 월별 총 지급액 (이자) */
    get totalMonthlyInterestAmount() {
        return this.totalInterestTxnList.reduce((sum: number, txn: any) => {
            if (txn.type === 'interest_record') {
                return sum + txn.amount;
            }
            return sum;
        }, 0);
    }

    /** 월별 총 지급액 (세금) */
    get totalMonthlyTaxAmount() {
        return this.totalInterestTxnList.reduce((sum: number, txn: any) => {
            if (txn.type === 'interest_record') {
                return sum + txn.taxAmount;
            }
            return sum;
        }, 0);
    }

    mounted() {
        // this.initYearTrendChart();
        // this.updateYearTrendChart();
    }


    /** 차트를 초기화합니다 */
    initYearTrendChart() {
        const chartNode: HTMLCanvasElement = this.$refs.yearTrendChart as HTMLCanvasElement;
        this.yearTrendChart = new Chart(chartNode, {
            type: 'bar',
            data: {
                datasets: [
                    {
                        label: '그래프 로딩중',
                        data: [0],
                    },
                ],
                labels: ['로딩중'],
            },
            options: {
                scales: {
                    yAxes: [{
                        id: 'repay',
                        type: 'linear',
                        position: 'left',
                        ticks: {
                            beginAtZero: true,
                        },
                    }, {
                        id: 'invest',
                        type: 'linear',
                        position: 'right',
                        ticks: {
                            beginAtZero: true,
                        },
                    }],
                },
                maintainAspectRatio: false,
            },
        });
    }

    /**  */
    async updateYearTrendChart() {
        this.isGraphFailure = false;
        this.isGraphHidden = true;

        try {
            let result = await APIClient.instance.mypage.getGraphData();

            if (this.yearTrendChart) {
                this.yearTrendChart.config.data = result;
                this.yearTrendChart.update();

                this.isGraphHidden = false;
                this.isGraphFailure = false;
            }

        } catch (e) {
            EventBus.$emit('toast-notification', new FatalException(
                MessageHandler.combineMessage('그래프를 불러오지 못했습니다.', e)
            ));
            this.isGraphFailure = true;
            this.graphFailureReason = '그래프를 불러오지 못했습니다.';
        }
    }

    async fetchTotalInterestTxnList() {
        const rangeMonth = this.totalInterestRangeMonth;
        this.isResourceReady = false;

        try {
            this.totalInterestTxnList
                = await APIClient.instance.mypage.getTotalTableData(
                    rangeMonth.getFullYear(), rangeMonth.getMonth() + 1);

            this.isResourceReady = true;
        } catch (e) {
            // 오류인 경우
            EventBus.$emit('toast-notification', new FatalException(
                MessageHandler.combineMessage('정보를 불러오는데 실패했습니다.', e)
            ));
            this.isResourceReady = true;
            throw e;
        }
    }

    /**
     * 주어진 달의 첫 날과 마지막 날을 구합니다.
     */
    getMonthFirstLastDateAsPair(date: Date) {
        let [firstDate, lastDate] = [new Date(date), new Date(date)];
        firstDate.setDate(1);
        lastDate.setMonth(lastDate.getMonth() + 1);
        lastDate.setDate(0);

        return [firstDate, lastDate];
    }
    prevMonth() {
        this.totalInterestRangeMonth = new Date(this.totalInterestRangeMonth.setMonth(
            this.totalInterestRangeMonth.getMonth() - 1
        ));
    }
    nextMonth() {
        let now = new Date();
        let thisMonth: number = now.getFullYear() * 100 + now.getMonth();
        if (thisMonth <=
            (this.totalInterestRangeMonth.getFullYear() * 100 + this.totalInterestRangeMonth.getMonth()) ) {
            // 오늘의 년월 보다 현재 선택된 년월이 같거나 크면 다음 달로 넘어갈 수 없습니다.
            return false;
        }

        this.totalInterestRangeMonth = new Date(this.totalInterestRangeMonth.setMonth(
            this.totalInterestRangeMonth.getMonth() + 1
        ));
    }

    @Watch('totalInterestRangeMonth', { immediate: true })
    onTotalInterestRangeMonthChanged() {
        this.fetchTotalInterestTxnList();
    }

}
