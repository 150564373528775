
































import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator';
import Fullscreen from '@/components/Fullscreen.vue';
import CenterWrapper from "@/components/CenterWrapper.vue";
import {LeaveViaPhoneCodeForm} from "@/api/in/login";
import {CustomException, FatalException} from "@/api/client/notification";
import {EventBus} from "@/lib/event-bus";
import {APIClient} from "@/api/client/client";
import {MessageHandler} from "@/api/client/error";
import DigitsOnly from "@/mixins/DigitsOnly";
import {mixins} from "vue-class-component";
import {Member} from "@/api/member";
import StoreHelper from "@/mixins/StoreHelper";

@Component({
    components: {
        CenterWrapper,
        Fullscreen,
    }
})
export default class DeactivatePopup extends Mixins<DigitsOnly>(DigitsOnly) {

    @Prop({required: true})
    public memberIndex!: number;

    // public readonly recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_KEY!;

    private deactivationSuccessful: boolean = false;

    private leaveViaPhoneCodeForm: LeaveViaPhoneCodeForm = {
        responseToken: null,
        memberIdx: -1,
        code: '',
    };

    /* 전송 중일 때는 버튼이 눌리지 않게 합니다. */
    private transferInProgress: boolean = false;

    private get codeInputable() {
        return this.leaveViaPhoneCodeForm.code && ! this.transferInProgress;
    }


    /** 휴대전화 인증번호 입력을 처리해야 합니다. */
    private async handleDeactivationCode() {
        // if (!this.leaveViaPhoneCodeForm.responseToken) {
        //     EventBus.$emit('toast-notification',
        //         new CustomException("보안을 위해 로봇 방지 체크를 진행해 주십시오."));
        //     return;
        // }

        this.leaveViaPhoneCodeForm.memberIdx = this.memberIndex;

        this.transferInProgress = true;

        try {
            let response = await APIClient.instance.mypage.deleteVerify(this.leaveViaPhoneCodeForm);

            if (response.success != null) {
                if (response.success) {
                    // 탈퇴 신청 성공

                    // 인증코드를 입력할 차례입니다.
                    this.deactivationSuccessful = true;
                } else {
                    // 탈퇴 신청 실패
                    EventBus.$emit('toast-notification', new CustomException(
                        MessageHandler.combineMessage("회원 탈퇴에 실패했습니다.", response)
                    ));
                    // (this.$refs.recaptcha! as VueRecaptcha).reset();
                }
            } else {
                EventBus.$emit('toast-notification', new CustomException(
                    MessageHandler.combineMessage("회원 탈퇴에 실패했습니다.", response)
                ));
                // (this.$refs.recaptcha! as VueRecaptcha).reset();
            }
        } catch (error) {
            // 회원탈퇴 신청도중 오류 발생
            EventBus.$emit('toast-notification', new FatalException(
                MessageHandler.combineMessage("회원 탈퇴에 실패했습니다.", error)
            ));
            // (this.$refs.recaptcha! as VueRecaptcha).reset();
        }


        this.transferInProgress = false;
    }

    /**
     * 회원 탈퇴 이후 메인으로 나가며 새로고침을 수행합니다.
     */
    private afterDeactivation() {
        this.$router.push('/');
        location.reload();
    }
    //
    // private onRecaptchaVerified(response: string) {
    //     this.leaveViaPhoneCodeForm.responseToken = response;
    // }
    //
    // private onRecaptchaExpired() {
    //     this.leaveViaPhoneCodeForm.responseToken = null;
    // }

}
