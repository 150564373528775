import {Member} from "@/api/member";

export const isSSNlegit = function(juminConcat: string): boolean {
    if (juminConcat.length === 13) {
        // 13자리일때만
        // https://ko.wikipedia.org/wiki/%EC%A3%BC%EB%AF%BC%EB%93%B1%EB%A1%9D%EB%B2%88%ED%98%B8#%EA%B5%AC%EC%84%B1
        let parity = parseInt(juminConcat.charAt(12), 10);

        const multipliers = [2, 3, 4, 5, 6, 7, 8, 9];
        let multipliedVal = 0;

        for (let cur = 0; cur < 12; cur++) {
            multipliedVal = multipliedVal - - (multipliers[cur % multipliers.length] * parseInt(juminConcat.charAt(cur), 10));
        }

        let parityCalculated = ( 11 - (multipliedVal % 11) ) % 10;

        return parityCalculated == parity;
    }
    return false;
};


export const isCRNlegit = function(crnConcat: string): boolean {
    if (crnConcat.length === 13) {
        // 법인등록번호 13자리일때만
        // https://www.dukgun.com/2018/11/resident-registration-number-system-corporate.html
        let parity = parseInt(crnConcat.charAt(12), 10);

        const multipliers = [1, 2];
        let multipliedVal = 0;

        for (let cur = 0; cur < 12; cur++) {
            multipliedVal = multipliedVal - - (multipliers[cur % multipliers.length] * parseInt(crnConcat.charAt(cur), 10));
        }

        let parityCalculated = 10 - (multipliedVal % 10);

        return parityCalculated == parity;
    }
    return false;
};


export const isUserSSNlegit = function(user: Member): boolean {
    if (!user) {
        // user 로딩이 안됨 . false 반환
        return false;
    }
    if (user.userType === 'company') {
        // 법인등록번호 규칙은 차후 추가
        if (user.jumin1 && user.jumin2) {
            let crnConcat = `${user.jumin1}${user.jumin2}`;

            return isCRNlegit(crnConcat);
        }
    } else {
        if (user.jumin1 && user.jumin2) {
            let juminConcat = `${user.jumin1}${user.jumin2}`;

            return isSSNlegit(juminConcat);
        }
    }
    return false;
};
