

















































import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import StoreHelper from '@/mixins/StoreHelper';

import Fullscreen from '@/components/Fullscreen.vue';
import { Member } from '../api/member';

@Component({
    components: {
        Fullscreen
    }
})
export default class Drawer extends Mixins<StoreHelper>(StoreHelper) {
    get profile(): Member | null {
        return this.$state.profile;
    }

    public readonly MENU_ENTRIES: MenuEntry[] = [
        { label: '메인', link: '/' },
        { label: '투자하기', link: '/' },
        // { label: '대출 신청하기', link: '/loan/application' },
        { label: '공지사항', link: '/board/notice' },
        // { label: '투자 FAQ', link: '/board/faq' }
    ];
}
