

























































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import CenterWrapper from '@/components/CenterWrapper.vue';
import ReadableAmount from '@/components/ReadableAmount.vue';
import ProductTile from "@/components/ProductTile.vue";
import {Product, ProductState} from "@/api/product";
import { Attachment } from '../api/attachment';
import {
    CustomException,
    InformationNotification,
    JustNotification,
    SuccessNotification
} from "@/api/client/notification";
import {EventBus} from "@/lib/event-bus";
import CommaInput from "@/components/CommaInput.vue";
import {Metadata} from "@/metadata";

@Component({
    components: {
        CommaInput,
        CenterWrapper,
        ProductTile,
        ReadableAmount
    },
})
export default class UIShowcase extends Vue {
    private inputNumber: number = 0;

    private commaInputVal: number = 1000;
    private commaInputStep: number = 500;

    private testProduct: Product = new TestProduct ();

    // 토스트 관련
    private numOfToasts: number = 1;
    private toastMessage: string = '토스트 시험가동 메시지 입니다.';

    private mounted() {
        this.setMetadata();
    }


    @Watch('numOfToasts')
    private onNumOfToastChanged() {
        // input 입력값 범위를 통제하기 위해서 Watch를 걸었습니다.
        let inputNumOfToasts = this.$refs.inputNumOfToasts as HTMLInputElement;

        let maximum = parseInt(inputNumOfToasts.max, 10);
        let minimum = parseInt(inputNumOfToasts.min, 10);

        // 최대값보다 더 클 때 최대값으로 덮어쓰기;
        if (this.numOfToasts > maximum) {
            this.numOfToasts = maximum;
        }

        // 최소값보다 더 작을 때 최소값으로 덮어쓰기;
        if (this.numOfToasts < minimum) {
            this.numOfToasts = minimum;
        }

    }

    private popupToast(type: string) {

        let noti: Notification | null = null;

        switch (type) {
            case 'warning':
                noti = new CustomException(this.toastMessage) as unknown as Notification;
                break;
            case 'success':
                noti = new SuccessNotification(this.toastMessage) as unknown as Notification;
                break;
            default:
            case 'show':
                noti = new JustNotification(this.toastMessage) as unknown as Notification;
                break;
            case 'info':
                noti = new InformationNotification(this.toastMessage) as unknown as Notification;
                break;
        }

        if (this.numOfToasts > 1) {
            // 한 번에 많은 양의 토스트를 띄워야하므로 기존 토스트를 먼저 직접 비워줘야 합니다.
            // 그렇지 않으면 마지막에 뜨는 토스트 때문에 기껏 띄운 토스트가 사라집니다.
            EventBus.$emit("toast-flush");

            // 토스트 토출 갯수를 필요한 만큼 늘릴 수 있습니다.
            EventBus.$emit("toast-set-limit", this.numOfToasts);
        }

        for (let cur = 1; cur <= this.numOfToasts; cur++) {
            EventBus.$emit('toast-notification', noti);
        }

        // 사용후 다시 1로 돌려주세요.
        EventBus.$emit("toast-set-limit", 1);

    }

    private stepWarned() {
        EventBus.$emit('toast-notification', new InformationNotification(
            `입력란에 ${this.commaInputStep.toLocaleString('ko-kr')} 단위로만 값을 넣을 수 있습니다.`));
    }

    private setMetadata() {
        let meta: Metadata = {
            title: 'UI Showcase',
            // description: 'shows pre-defined UI components of BuildonFunding'
        };
        EventBus.$emit("meta-title", meta);
    }
}

class TestProduct implements Product {
    /** 인덱스 (상품 번호) */
    index: number = 1024;
    /** 상품명 */
    title?: string = '제 nn호 삐약삐약 호텔 건설자금 (4차)';

    /** 목표 투자액 */
    totalInvestment?: number = 10245760000;
    /** 현재 모집된 금액 */
    currentInvestmentAmount: number = 2560000;
    /** 상환 누적 금액 */
    totalRepaymentAmount: number = -1;
    /** 상환 건수 */
    totalRepaymentCount: number = -1;

    /** 투자금액 모집률 */
    currentInvestmentPercentage: number = -1;

    /** 투자모집기간 시작일 */
    displayStart?: number = 1559702060004;

    /** 투자모집기간 종료일 */
    displayEnd?: number = 1569702060004;

    /** 이자율 (연간) */
    interestRate: number = 15.67;
    /** 이자율 (일일) */
    interestTaxDailyRate: number = -1;
    /** 이자소득세율 */
    interestTaxRate: number = -1;
    /** 이자소득세율 (일일) */
    interestDailyRate: number = -1;
    /** 연체이자율 */
    lateInterestRate: number = -1;

    /** 투자 집행 여부 */
    executed: boolean = false;

    /** 상품 상태 */
    state: ProductState = 'funding';

    /** 상품 사용 여부 */
    enabled: boolean = true;
    /** 상품 사용 여부 */
    isEnabled: boolean = true;
    /** 상품 삭제 여부 */
    deleted: boolean = false;
    /** 상품 삭제 여부 */
    isDeleted: boolean = false;

    /** 호수 번호 */
    absoluteIndex: number = 256;
    /** 차수 번호 */
    revision: number = 10;

    /** 출금 제한 금액 */
    reservedAmount: number = -1;

    /** 첨부 사진 */
    productImages: Attachment[] = [];
    /** 첨부 보고서 */
    productDescs: Attachment[] = [];
}
