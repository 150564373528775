import { Component, Vue,  } from 'vue-property-decorator';

function onInput(event: Event) {
    // Regex only numbers and dots https://stackoverflow.com/a/4572205
    const element = event.target as HTMLInputElement;
    const regEx = /[^0-9]/g;  // /[^0-9.]/g;
    element.value = element.value.replace(regEx, '');
}

@Component({
    directives: {
        digitsOnly: {
            bind(el: HTMLElement) {
                el.addEventListener('input', onInput);
            },
            unbind(el: HTMLElement) {
                el.removeEventListener('input', onInput);
            }
        }
    }
})
export default class DigitsOnly extends Vue {
    // null
}
