import ProductAPI from './product';
import MypageAPI from './mypage';
import LoginAPI from './login';
import FaqAPI from './faq';
import LoanAPI from './loan';
import AttachmentAPI from './attachment';
import RegisterAPI from "./register";
import NoticeAPI from "./notice";
import StudyAPI from "./study";
import StatsAPI from "@/api/client/stats";
import StaticFilesAPI from "@/api/client/static_files";

export class APIClient {
    private static readonly $instance: APIClient = new APIClient();

    public readonly product    = new ProductAPI();
    public readonly mypage     = new MypageAPI();
    public readonly login      = new LoginAPI();
    public readonly signup     = new RegisterAPI();
    public readonly faq        = new FaqAPI();
    public readonly loan       = new LoanAPI();
    public readonly notice     = new NoticeAPI();
    public readonly study      = new StudyAPI();
    public readonly attachment = new AttachmentAPI();
    public readonly stats      = new StatsAPI();
    public readonly staticfile = new StaticFilesAPI();

    private constructor() {

    }

    /**
     * 싱글턴 인스턴스를 반환합니다
     */
    public static get instance(): APIClient {
        return APIClient.$instance;
    }
}
