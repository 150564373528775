import {APIBase, DELETE, GET, POST} from "./apibase";
import { Product } from '../product';
import { Member, ResolveResult } from '../member';
import {DeactivationForm, LeaveViaPhoneCodeForm, RegisterForm} from "@/api/in/login";
import {
    DepositAvailability,
    DepositBankAccountInfo,
    DepositVerifyResponse,
    DepositVerifyStatus,
    DashboardValues, MonthlyReserveTxnCalc
} from '../mypage';
import {SimpleResponse} from "@/api/resourceList";
import {SeyfertTransaction} from "@/api/seyfert";
import {InvestmentTransaction, LoanInterestRecord, LoanRecord} from "@/api/transaction";


export default class MypageAPI extends APIBase {
    /** 자기 자신의 정보를 취득합니다. */
    public getSelf() {
        const endpoint = '/_api/self';
        return this.request(GET, endpoint) as Promise<Member>;
    }

    /** 자기 자신을 탈퇴하고자 휴대전화 인증을 요청합니다 */
    public deleteSelf(form: DeactivationForm) {
        const endpoint = '/_api/self/deleteSelf';
        return this.request(POST, endpoint, form) as Promise<SimpleResponse>;
    }

    /** 자기 자신을 탈퇴하고자 휴대전화 인증을 요청합니다 */
    public deleteVerify(form: LeaveViaPhoneCodeForm) {
        const endpoint = '/_api/leave/verify';
        return this.request(POST, endpoint, form) as Promise<SimpleResponse>;
    }

    /** 접속자의 IP 주소를 취득합니다. */
    public resolveSelf() {
        const endpoint = '/_api/self/resolve';
        return this.request(GET, endpoint) as Promise<ResolveResult>;
    }

    /**
     * 로그인 된 사용자가 지금까지 투자한 상품 목록을 취득합니다.
     * @note 이 API는 ResourceList<> 대신 raw array를 반환합니다
     */
    public getInvestedProducts() {
        const endpoint = '/_api/self/invested_products';
        return this.request(GET, endpoint) as Promise<Product[]>;
    }


    /**
     * 로그인 된 사용자가 지금까지 투자한 월간 적립식 상품 목록을 취득합니다.
     * @note 이 API는 ResourceList<> 대신 raw array를 반환합니다
     */
    public getInvestedMonthlyReserveProducts() {
        const endpoint = '/_api/self/invested_monthly_reserve_products';
        return this.request(GET, endpoint) as Promise<Product[]>;
    }

    /**
     * 자신이 투자한 내역을 읽어올 수 있습니다.
     */
    public getMyTransaction(transactionId: number | string) {
        const endpoint = `/_api/self/invest_txn/get/${transactionId}`;
        return this.request(GET, endpoint) as Promise<InvestmentTransaction>;
    }

    /**
     * 로그인 된 사용자의 대시보드 정보를 반환합니다.
     */
    public getDashboard() {
        const endpoint = '/_api/self/dashboard';
        return this.request(GET, endpoint) as Promise<DashboardValues>;
    }

    /** 수익권증서를 다운로드 합니다. */
    public downloadCertificate(loanRecordId: number) {
        const endpoint = `/_api/self/certificate/${loanRecordId}`;
        window.open(endpoint, '_blank');
    }

    /** 날인 계약서를 다운로드 합니다. */
    public downloadContract(txnId: number) {
        const endpoint = `/_api/self/contract/${txnId}`;
        window.open(endpoint, '_blank');
    }

    /**
     * 마이페이지용 YYYY년 MM월 테이블 데이터를 취득합니다
     * @param year
     * @param month
     */
    public getTotalTableData(year: string | number, month: string | number) {
        const endpoint = `/_api/self/table-total/${year}/${month}`;
        return this.request(GET, endpoint) as Promise<Array<(LoanRecord | LoanInterestRecord)>>;
    }

    /**
     * 마이페이지용 그래프 데이터를 반환합니다.
     */
    public getGraphData() {
        const endpoint = `/_api/self/graph`;
        return this.request(GET, endpoint);
    }

    /**
     * 특정 상품의 이자/원금 지급 내역을 불러옵니다.
     * 단 해당 회원이 투자한 내역에 대해서만.
     * @param productIdx 상품 번호
     * @param year 년도
     * @param month 월
     * @param onlyPaid 지불 여부 (false == 모두, true == 지불된 것만)
     */
    public getRecordsOnProduct(productIdx: string | number,
                               year: string | number, month: string | number, onlyPaid: boolean) {
        const endpoint = `/_api/self/loan_and_interest_records/${productIdx}`;
        return this.request(GET, endpoint, {
            year,
            month,
            onlyPaid,
        }) as Promise<Array<(LoanRecord | LoanInterestRecord)>>;
    }

    /**
     * 특정 상품의 이자 지급 내역을 불러옵니다.
     * 단 해당 회원이 투자한 내역에 대해서만.
     * @param product_idx 투자 상품 번호
     */
    public getInterestRecordsOnProduct(productIdx: string | number) {
        const endpoint = `/_api/self/interest_records/${productIdx}`;
        return this.request(GET, endpoint) as Promise<LoanInterestRecord[]>;
    }

    /**
     * 특정 상품의 원금 내역(미지급 포함)을 불러옵니다.
     * 단 해당 회원이 투자한 내역에 대해서만.
     * @param product_idx 투자 상품 번호
     */
    public getAllLoanRecordsOnProduct(productIdx: string | number) {
        const endpoint = `/_api/self/loan_records/all/${productIdx}`;
        return this.request(GET, endpoint) as Promise<LoanRecord[]>;
    }

    /**
     * 해당 회원의 펀딩 진행중 원금 내역을 불러옵니다.
     * 단 지급 유무는 영향을 주지 않고 상품 상태만 따집니다.
     */
    public getLoanRecordsThatAreFunding() {
        const endpoint = `/_api/self/loan_record_from_funding_products`;
        return this.request(GET, endpoint) as Promise<LoanRecord[]>;
    }

    /**
     * 특정 상품의 투자 내역을 불러옵니다.
     * 단 해당 회원이 투자한 내역에 대해서만.
     * @param product_idx 투자 상품 번호
     * @deprecated
     */
    public getTransactionsOnProduct(productIdx: string | number) {
        const endpoint = `/_api/self/invest_txns_per_product/${productIdx}`;
        return this.request(GET, endpoint) as Promise<InvestmentTransaction[]>;
    }

    /**
     * 특정 상품의 투자 내역을 불러옵니다.
     * (parentTxn 존재시 하나만)
     * 단 해당 회원이 투자한 내역에 대해서만.
     * @param product_idx 투자 상품 번호
     */
    public getTransactionsOnProductConsolidate(productIdx: string | number) {
        const endpoint = `/_api/self/invest_txns_per_product_consolidate/${productIdx}`;
        return this.request(GET, endpoint) as Promise<InvestmentTransaction[]>;
    }

    /**
     * 특정 상품의 투자 내역을 불러옵니다.
     * 단 해당 회원이 투자한 내역에 대해서만.
     * @param product_idx 투자 상품 번호
     */
    public getTransactionsOnMonthlyReserveProduct(productIdx: string | number) {
        const endpoint = `/_api/self/invest_txns_per_monthly_reserve_product/${productIdx}`;
        return this.request(GET, endpoint) as Promise<InvestmentTransaction[]>;
    }

    /**
     * 특정 상품의 투자 내역을 불러옵니다.
     * 단 해당 회원이 투자한 내역에 대해서만.
     * 컨펌 안 된 것도 포함
     * @param product_idx 투자 상품 번호
     */
    public getMyTransactionsOnProduct(productIdx: string | number) {
        const endpoint = `/_api/self/my_txns_per_product/${productIdx}`;
        return this.request(GET, endpoint) as Promise<InvestmentTransaction[]>;
    }

    /**
     * 특정 상품의 투자 내역을 불러옵니다.
     * 단 해당 회원이 투자한 내역에 대해서만.
     * 컨펌 안 된 것도 포함
     * @param product_idx 투자 상품 번호
     */
    public getMyTransactionsOnMonthlyReserveProduct(productIdx: string | number) {
        const endpoint = `/_api/self/my_txns_per_monthly_reserve_product/${productIdx}`;
        return this.request(GET, endpoint) as Promise<InvestmentTransaction[]>;
    }

    /**
     * 월간 적립식 상품의 이자 내역을 불러옵니다.
     * 단 해당 회원이 투자한 내역에 대해서만.
     * 컨펌 안 된 것도 포함
     * @param product_idx 투자 상품 번호
     */
    public getMyStatsOnMonthlyReserveProduct() {
        const endpoint = `/_api/self/my_stat_of_monthly_reserve_products`;
        return this.request(GET, endpoint) as Promise<Record<number, MonthlyReserveTxnCalc>>;
    }

    /**
     * 특정 회원의 펀딩 진행중 투자 내역을 불러옵니다.
     * @deprecated
     */
    public getTransactionsThatAreFunding() {
        const endpoint = `/_api/self/invest_txns_are_funding`;
        return this.request(GET, endpoint) as Promise<InvestmentTransaction[]>;
    }

    /**
     * 특정 회원의 펀딩 진행중 투자 내역을 불러옵니다. (단 parentTxn 존재시 하나만)
     */
    public getTransactionsThatAreFundingConsolidate() {
        const endpoint = `/_api/self/invest_txns_are_funding_consolidate`;
        return this.request(GET, endpoint) as Promise<InvestmentTransaction[]>;
    }

    public modifySelf(signupForm: RegisterForm) {
        const endpoint = "/_api/self/modify";
        // new Object() 가 json으로 반환되므로 object를 써도 됩니다.
        return this.request(POST, endpoint, signupForm) as Promise<Member>;
    }

}

